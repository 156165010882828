import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { throwError } from "rxjs";

import { environment } from "../../../../environments/environment";

import { Help } from "../../models/help";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class HelpService {
  private apiUrl = environment.API_START_URL + "api/requestHelp";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public requestHelp(formData: any, accessToken, lang) {
    if (accessToken) {
      const header = new HttpHeaders({
        Authorization: "Bearer " + accessToken,
      });
      return this.http
        .post(this.apiUrl + "/" + lang, formData, { headers: header })
        .map(this.extractData)
        .catch(this.handleError);
    } else {
      return this.http
        .post(this.apiUrl + "/" + lang, formData)
        .map(this.extractData)
        .catch(this.handleError);
    }
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
