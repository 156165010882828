import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BundleListing } from '../../../../core/models/bundle';

@Component({
  selector: 'app-transfer-registered',
  templateUrl: './transfer-registered.component.html',
  styleUrls: ['./transfer-registered.component.css']
})
export class TransferRegisteredComponent {
  @Input() public diplomaTemplateType: string;
  @Input() public sameUser: boolean;
  @Input() public receiverMail: string;
  @Input() public userMail: string;

  @Output() public claim: EventEmitter<void> = new EventEmitter<void>();
  @Output() public logout: EventEmitter<void> = new EventEmitter<void>();


  public onClaim() {
    this.claim.emit();
  }

  public onLogout(event: Event) {
    this.logout.emit();
  }

}
