<!-- TODO: if bundle show bundle component -->
<section *ngIf="this.designView | async as view">
  <app-diploma-design-view
    *ngIf="view.presentation_view > 1 || view.has_bundle else normal"
    [withAccessLevel]="this.withAccessLevel"
    [withGoalSelection]="this.withGoalSelection"
    [diplomaAccessLevel]="this.diplomaAccessLevel"
    [diplomaDesign] = view
    [openModel]="this.openModel"
    [userHash]="this.userHash"
    [userDetails]="this.userDetails"
    [trackLink]="this.trackLink"
    [shareData]="this.shareData"
    [addToSocialProfile]="this.addToSocialProfile"
    [successful]="this.successful"
    [unsuccessful]="this.unsuccessful"
    [special]="this.specialShare">
  </app-diploma-design-view>
  <ng-template #normal>
    <app-diploma
      [withAccessLevel]="this.withAccessLevel"
      [withGoalSelection]="this.withGoalSelection"
      [diplomaAccessLevel]="this.diplomaAccessLevel"
      [diplomaDesign] = view
      [openModel]="this.openModel"
      [userHash]="this.userHash"
      [userDetails]="this.userDetails"
      [trackLink]="this.trackLink"
      [shareData]="this.shareData"
      [addToSocialProfile]="this.addToSocialProfile"
      [successful]="this.successful"
      [unsuccessful]="this.unsuccessful"
      [special]="this.specialShare">
    </app-diploma>
  </ng-template>
</section>
