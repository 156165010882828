import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpUtilService {
  private cancelHttpRequest$ = new Subject<void>();

  public cancelPendingRequests() {
    this.cancelHttpRequest$.next();
  }

  public getCancelRequestObservable(): Observable<void> {
    return this.cancelHttpRequest$.asObservable();
  }
}
