import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomButtonResponseDto } from '../../../../dtos/CustomButtonResponseDto';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css'],
})
export class CustomButtonComponent implements OnInit {
  @Input() public customButton: CustomButtonResponseDto;

  @Output() public actionClick = new EventEmitter<CustomButtonResponseDto>();

  public hover: Boolean = false;

  constructor() {}

  ngOnInit() {}

  public emitActionClick() {
    this.actionClick.emit(this.customButton);
  }

  public getButtonCss() {
    const { backgroundColor, textAndOutline } = this.hover
      ? this.customButton.color.focus
      : this.customButton.color.notFocus;

    return {
      'background-color': backgroundColor,
      'border-color': textAndOutline,
      'color': textAndOutline,
    };
  }
}
