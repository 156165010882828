<flash-messages></flash-messages>
<div class="container list-container" *ngIf="alternativeLogins; else loading">
  <p class="social-link-text">
    {{ "You can log in using existing accounts from Google, LinkedIn or Facebook. This way you do not need to remember the password for your Diplomasafe account." | translate }}
  </p>
  <mat-divider></mat-divider>
  <mat-list>
    <!-- Google -->
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon><span class="fa fa-google"></span></mat-icon>
      <div mat-line>{{ "Google Link" | translate }}</div>
      <ui-switch
        matTooltip="Info about the action"
        class="ui-switch"
        checkedLabel="{{ 'on' | translate }}"
        uncheckedLabel="off"
        [(ngModel)]="alternativeLogins.google"
        (change)="changeGoogleLink($event)"
      ></ui-switch>
    </mat-list-item>
    <!-- !Google -->
    <!-- Facebook -->
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon><span class="fa fa-facebook"></span></mat-icon>
      <div mat-line>{{ "Facebook Link" | translate }}</div>
      <ui-switch
        matTooltip="Info about the action"
        class="ui-switch"
        checkedLabel="{{ 'on' | translate }}"
        uncheckedLabel="off"
        [(ngModel)]="alternativeLogins.facebook"
        (change)="changeFacebookLink($event)"
      ></ui-switch>
    </mat-list-item>
    <!-- !Facebook -->
    <!-- Facebook -->
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon><span class="fa fa-linkedin"></span></mat-icon>
      <div mat-line>{{ "LinkedIn Link" | translate }}</div>
      <ui-switch
        matTooltip="Info about the action"
        class="ui-switch"
        checkedLabel="{{ 'on' | translate }}"
        uncheckedLabel="off"
        [(ngModel)]="alternativeLogins.linkedin"
        (change)="changeLinkedinLink($event)"
      ></ui-switch>
    </mat-list-item>
    <!-- !Facebook -->
  </mat-list>
</div>
<button #googleButton [style.visibility]="'hidden'"></button>
<ng-template #loading>
  <div class="text-center mt-3">
    <h1 class="margin-bottom-30 green-color">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
      <span class="sr-only">{{ "Loading" | translate }}...</span>
    </h1>
    <p>{{ "Loading" | translate }}... {{ "Please Wait" | translate }}...</p>
  </div>
</ng-template>
