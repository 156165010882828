import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
})
export class TermsComponent implements OnInit {

  constructor() {
    // blank constructor
  }

  public ngOnInit() {
    // blank onInit
  }

}
