import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable, throwError } from 'rxjs';

import { Login } from '../../models/login';

import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import LoginResponse from 'app/core/models/auth/login-response';
import { UserService } from '../user/user.service';
import getHeaders from 'app/core/helpers/http/http-headers';
import { take } from 'rxjs/operators';
import { convertQueryParamsToObject } from '../../helpers/util';

const LOGIN = () => environment.API_START_URL + 'api/login';
const LOGOUT = () => environment.API_START_URL + 'api/logout';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiCheckDiplomaUrl = environment.API_START_URL + 'api/checkDiploma';

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private userService: UserService,
    private cookieService: CookieService
  ) {}

  public makeLogin(body: any): Observable<Login[] | {}> {
    return this.http.post(LOGIN(), body, { headers: getHeaders(true) }).map(this.extractData);
  }

  public checkDiploma(lang, uh, id): Observable<Login[] | {}> {
    return this.http
      .get(this.apiCheckDiplomaUrl + '/' + lang + '/' + uh + '/' + id)
      .map(this.extractData)
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public getDiplomaData(lang, uh, id): Observable<Login[] | {}> {
    return this.http
      .get(this.apiCheckDiplomaUrl + '/' + lang + '/' + uh + '/' + id)
      .map(this.extractData)
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public login(data: LoginResponse, withRedirect: boolean = true): Observable<any> | void {
    try {
      localStorage.removeItem('currentUser');
      localStorage.setItem(
        'currentUser',
        JSON.stringify({
          accessToken: data.access_token ?? '',
          expiresIn: data.expires_in ?? '',
          refreshToken: data.refresh_token ?? '',
          tokenType: data.token_type ?? '',
        })
      );

      this.getUserDataAndRedirect(withRedirect);
    } catch (error) {
      return throwError(error);
    }
  }

  public logout(): Observable<any> {
    return this.http.get(LOGOUT(), { headers: getHeaders() });
  }

  private getUserDataAndRedirect(redirect: boolean) {
    this.userService
      .getLoggedUserData()
      .pipe(take(1))
      .subscribe(
        (uData) => {
          try {
            localStorage.removeItem('userHash');
            localStorage.setItem('userHash', uData.userHash);
            this.navigate(redirect);
          } catch (error) {
            return throwError(error);
          }
        },
        (err) => this.handleError(err)
      );
  }

  private navigate(redirect: boolean) {
    // claiming flow for linkedin login
    const linkedinClaimFlow = sessionStorage.getItem('clamingFlow');
    if (linkedinClaimFlow) {
      const queryParams = JSON.parse(sessionStorage.getItem('clamingFlowParams'));

      // clear session storage
      sessionStorage.removeItem('clamingFlow');
      sessionStorage.removeItem('clamingFlowParams');

      return this.router.navigate(
        [this.cookieService.get('langVal') + '/claim/' + linkedinClaimFlow],
        { queryParams: queryParams }
      );
    }

    // in claiming flow reload on login
    redirect
      ? this.router.navigate([this.cookieService.get('langVal') + '/dashboard'])
      : this.reload();
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.userService.clearSessionRedirectToLogin();
      }
      const body = error || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  private reload() {
    this.router.navigated = false;

    const url = this.router.url;

    const queryParams: any = convertQueryParamsToObject(url);

    this.router.navigate([this.router.url.split('?')[0]],  { queryParams });
  }

  public clearSessionRedirectToLogin(uhash: string = null, socialType: string = null) {
    localStorage.clear();
    this.ngZone.run(() => {
      this.router.navigate([this.cookieService.get('langVal') + '/login'], { queryParams: { social: uhash, social_type: socialType } });
    });
  }
}
