import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BundleListing } from 'app/core/models/bundle';

@Component({
  selector: 'app-claim-unregistered',
  templateUrl: './claim-unregistered.component.html',
  styleUrls: ['./claim-unregistered.component.css'],
})
export class ClaimUnregisteredComponent implements OnInit {
  termsAccepted: boolean;
  warningHidden: boolean;
  @Input() public diplomaType: String;
  @Input() public bundleData: BundleListing | null;
  @Input() public isBundle: boolean;
  @Input() public lang: string;
  @Input() public hasRegisteredSupportEmail: string;

  @Output() public claimRegister: EventEmitter<void> = new EventEmitter<void>();
  @Output() public claimRegisterBundle: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
    this.termsAccepted = false;
    this.warningHidden = true;
  }

  public handleAccept() {
    if (!this.termsAccepted) {
      this.warningHidden = false;
    } else if (this.isBundle) {
      this.claimRegisterBundle.emit();
    } else {
      this.claimRegister.emit();
    }
  }
}
