<app-two-factor-authentication-prompt
  *ngIf="this.has2faCode"
  [userHash]="this.userHash"
  [enabled2FA]="this.enabled2FA"
  [templateType]="this.templateType"
  [isEditProfile]="this.isEditProfile"
  [orgainzationImage]="this.orgainzationImage"
  (isValid)="emitIsValid($event)"
  #twoFactorPrompt
></app-two-factor-authentication-prompt>

<app-two-factor-authentication-setup
  *ngIf="!this.isEditProfile && !this.has2faCode"
  [userHash]="this.userHash"
  [templateType]="this.templateType"
  [orgainzationImage]="this.orgainzationImage"
  (isValid)="emitIsValid($event)"
></app-two-factor-authentication-setup>

<!-- Modal set 2fa -->
<ng-template id="accept" #setupModal let-c="close" let-d="dismiss">
  <app-two-factor-authentication-setup
    *ngIf="this.isEditProfile"
    [userHash]="this.userHash"
    [templateType]="this.templateType"
    [orgainzationImage]="this.orgainzationImage"
    (isValid)="emitIsValid($event, true)"
  ></app-two-factor-authentication-setup>
</ng-template>
<!-- !Modal set 2fa -->