<div *ngIf='!diplomaData.has_active_transfer_request;  else hasActiveRequest'>
  <button (click)='openDialog()' mat-raised-button
          class='transfer-diploma-button'
  >
    {{ 'Transfer /template_type/ to another user' | translate:{ "template_type": diplomaData.diploma_template_type } }}
  </button>
</div>

<ng-template #hasActiveRequest>

  <button (click)='cancelTransfer()' mat-raised-button
          class='transfer-diploma-button cancel-button'
  >
    {{ 'Cancel transfer' | translate }}
  </button>
  <span>
    {{ 'Transfer status:' | translate}} {{ 'In transfer' | translate}}
  </span>
</ng-template>
