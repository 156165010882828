import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-learn-more-description-modal',
  templateUrl: './learn-more-description-modal.component.html',
  styleUrls: ['./learn-more-description-modal.component.css']
})
export class LearnMoreDescriptionModalComponent implements OnInit {

  @Input() templateLink: string;
  @Input() description: any;
  @Input() diplomaName: string;
  @Input() organizationImage: string;
  @Input() dismiss: any;
  @Input() trackLink: any;

  constructor() { }

  ngOnInit() {
  }

}
