<div class="container list-container" *ngIf="shareViews | async as views; else loading">
  <table class="table" *ngIf="views.length > 0; else missing">
    <thead>
      <tr>
        <th class="align-baseline">{{'Name' | translate}}</th>
        <th class="align-baseline">{{'Course Title' | translate}}</th>
        <th class="align-baseline">{{'Expiry' | translate}}</th>
        <th class="align-baseline">{{'Actions' | translate}}</th>
        <th class="protected align-baseline"></th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let view of views">
          <td class="w-20 break-all">{{view.name}}</td>
          <td class="w-30">{{view.courseTitle}}</td>
          <td class="w-20" *ngIf="!view.is_blocked else blockedLink">{{convertToLocalDate(view.expiryDate) | date : 'dd-MM-yyyy hh:mm'}}</td>
          <ng-template #blockedLink>
            <td class="w-20">
              <span class="text-danger">{{ 'Blocked' | translate }}</span>
              <button class="btn btn-sm" (click)="unblock(view.uHash)" title="{{ 'Unblock' | translate }}"><i class="fa fa-unlock-alt"></i></button>
            </td>
          </ng-template>
          <td class="w-22">
            <button class="btn btn-sm email" (click)="showView(view.uHash)" title="{{ 'View how diploma is displayed using this link' | translate}}"><i class="fa fa-eye" ></i></button>
            <button class="btn btn-sm linkedin" (click)="this.shareViewService.copyToClipBoard(view.link)" title="{{ 'Copy this link to clipboard' | translate}}"><i class="fa fa-copy"></i></button>
            <button class="btn btn-sm pdf" (click)="this.dialog.open(deleteConfirm,{data: view.uHash, hasBackdrop: true})" title="{{ 'Delete access to diploma via this link' | translate}}"><i class="fa fa-trash"></i></button>
          </td>
          <td class="protected" *ngIf="view.protected; else unprotected"><button class="btn btn-sm pdf" title="{{ 'Protected with password' | translate}}"><i class="fa fa-lock"></i></button></td>
          <ng-template #unprotected>
            <td class="protected"><button class="btn btn-sm green" title="{{ 'Not protected with password' | translate}}"><i class="fa fa-unlock"></i></button></td>
          </ng-template>
        </tr>
    </tbody>
  </table>
</div>
<ng-template #missing>
  <div class="mt-3 text-center">
    <h3>{{ 'You do not have any active customized views' | translate }}</h3>
  </div>
</ng-template>
<ng-template #deleteConfirm let-data>
  <h1 mat-dialog-title class="green-color">
    {{ 'Confirm delete' | translate}}
  </h1>
    <mat-dialog-content>
      <div>
        {{'If you delete the view, the corresponding link does no longer give access to your diploma' | translate}}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="button-action">
        <button mat-raised-button mat-dialog-close type="button" class="cancel">{{ 'Cancel' | translate }}</button>
        <button mat-raised-button mat-dialog-close (click)="deleteView(data)" type="button" class="submit">{{ 'Delete' | translate}}</button>
      </div>
    </mat-dialog-actions>
</ng-template>
<ng-template #loading>
  <div class="text-center mt-3">
    <h1 class="margin-bottom-30 green-color">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
      <span class="sr-only">{{ 'Loading' | translate }}...</span>
    </h1>
    <p>
      {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
    </p>
  </div>
</ng-template>