<div class="side-buttons shadow">
    <div class="title">{{ bundle.title }}</div>
    <div class="subtitle">{{'Show element' | translate}}:</div>
    <div *ngFor="let diploma of bundle.diplomas.sort(this.bundleDiplomaOrder)">
        <button
            class="btn"
            [class.selected]="diploma.hash === this.activeDiplomaHash"
            [disabled]="(diploma.hash === this.activeDiplomaHash) || this.disableButtons"
            (click)="this.changeUrl(diploma.hash)">
                {{ diploma.label }}
        </button>
    </div>
</div>
