import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pathway-settings-page',
  templateUrl: './pathway-settings-page.component.html',
  styleUrls: ['./pathway-settings-page.component.css'],
})
export class PathwaySettingsPageComponent implements OnInit, OnDestroy {
  @Input() public isEdit: boolean;
  @Input() public orgainzationImage: string;
  @Input() private sameUser: boolean;

  @Output() public onSave = new EventEmitter<boolean>();

  private params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public data: Observable<Object>;
  public diplomaHash: string = this.params.uhash;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public emitOnSave(saved: boolean): void {
    this.onSave.emit(saved);
  }

  public handlePathwaySettingsUpdate($event: boolean) {}
}
