<div class="diploma-online-presentation shadow" #viewWrapper
    [ngClass]="{
        'diploma-online-presentation-background': seeMore && !largeDiplomaMain,
        'diploma-online-presentation-max-height': seeMore
    }">
    <div class="diploma-main"
        #diplomaMain
        [ngClass]="{
            'diploma-main-background': seeMore,
            'diploma-preload': seeMore === null || largeDiplomaMain === null
        }">
        <div *ngIf="data?.diploma.diploma_image" class="diploma-image">
            <img [src]="data.diploma.diploma_image" alt="" (load)="imageLoad()">
        </div>
        <div class="diploma-info">
            <p class="title break-word">
                {{ data?.diploma.template_type }}
            </p>
            <p class="course-name break-word">
                {{ data?.diploma.diploma_name}}
            </p>
            <table class="no-border table table-sm no-margin">
                <tr>
                    <td class="item-heading hyphen-auto">{{ 'Issued to' | translate }}:</td>
                    <td class="item-content hyphen-auto break-word">{{ data?.recipient.recipient_name }}</td>
                </tr>
                <tr>
                    <td class="item-heading hyphen-auto">{{ 'Issued date' | translate }}:</td>
                    <td class="item-content hyphen-auto break-word">{{ data?.diploma.issue_date | amLocale:language | amDateFormat:'LL' }}</td>
                </tr>
                <tr>
                    <td class="item-heading hyphen-auto">{{ 'Issued by' | translate }}:</td>
                    <td class="item-content hyphen-auto break-word">{{ data?.organization.name }}</td>
                </tr>
                <tr *ngIf="data?.diploma.expiry_date">
                    <td class="item-heading hyphen-auto">{{ 'Expires' | translate }}:</td>
                    <td class="item-content hyphen-auto break-word">{{ data?.diploma.expiry_date | amLocale:language | amDateFormat:'LL' }}</td>
                </tr>
                <ng-container *ngIf="data?.diploma.fieldsData">
                    <ng-container *ngFor="let result of data?.diploma.fieldsData">
                        <tr *ngIf="result.type !=='table'">
                            <td class="item-heading hyphen-auto">{{ result.name }}:</td>
                            <td class="break-word hyphen-auto item-content">
                                <ng-container *ngIf="result.type == 'link'">
                                    <a href="{{ result.value }}" target="_blank" rel="noopener noreferrer" class="link text-semibold break-word">
                                        {{ result.value }}
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="result.type == 'image'">
                                    <img src="{{ result.value }}" class="custom-field-image"/>
                                </ng-container>
                                <ng-container *ngIf="result.type == 'textarea'">
                                    <p [innerHTML]="result.value"></p>
                                </ng-container>
                                <ng-container *ngIf="result.type !== 'link' && result.type !== 'image' && result.type !== 'textarea' && result.type !== 'table'">
                                    <span >{{ result.value }}</span>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </div>
    </div>
    
    <!-- Don't show the description when only a small fraction of it would be visible -->
    <div
        class="diploma-description"
        *ngIf="data?.diploma.diploma_description && !(largeDiplomaMain === true && seeMore !== false)"
        [ngClass]="{'overflow-hidden': seeMore}"
        [innerHTML]="data?.diploma.diploma_description"
        #diplomaDescription
    ></div>

    <ng-container *ngFor="let field of data?.diploma.fieldsData">
        <table *ngIf="showTable(field) else plainText" class="table">
            <thead *ngIf="field.settings.settings.visible">
            <tr>
                <th scope="col" *ngFor="let col of field.settings.cols" [ngStyle]="{'text-align': col['alignment']}">
                    {{ col['visible'] ? col['colName'] : ''}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of field.value | keyvalue: fieldsOrder">
                <td *ngFor="let colData of row.value; let i = index;"
                    [innerHtml]="colData" [ngStyle]="{'text-align': field.settings.cols[i]['alignment']}">
                </td>
            </tr>
            </tbody>
        </table>
        <ng-template #plainText>
            <div *ngIf="showTableReplacement(field)" class="table-replacement-text">
                <span>{{field.value}}</span>
            </div>
        </ng-template>
    </ng-container>
</div>
