import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable, throwError } from "rxjs";

import { ForgotPassword } from "../../../core/models/forgot-password";

import { environment } from "../../../../environments/environment";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class ForgotPasswordService {
  private apiUrl = environment.API_START_URL + "api/forgotPassword/";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public onForgotPassword(body: any, lang): Observable<ForgotPassword[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.apiUrl + lang, body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
