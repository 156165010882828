import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-transfer-unregistered',
  templateUrl: './transfer-unregistered.component.html',
  styleUrls: ['./transfer-unregistered.component.css']
})
export class TransferUnregisteredComponent {

  @Input() public diplomaTemplateType: string;

  public appUrl = environment.APP_URL;

  public termsAccepted: boolean = false;
  public warningHidden: boolean = true;

  @Output() public claimRegister: EventEmitter<void> = new EventEmitter<void>();

  public onClaim() {
    if(!this.termsAccepted) {
      this.warningHidden = false;
    } else {
      this.claimRegister.emit();
    }
  }
}
