<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div *ngIf="!data else loadedPage">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
            {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
          </p>
        </div>
      </div>
      <ng-template #loadedPage>
        <div *ngIf="data?.error">
          <div class="white-box margin-top-inverse-85 error-page text-center">
            <h1 class="margin-bottom-30 green-color">
              <i class="fa fa-check" aria-hidden="true"></i> {{ 'Unsubscribed' | translate }}
            </h1>
            <p class="margin-bottom-30">
              {{ data?.message | translate }}
            </p>
            <a class="btn btn-success" routerLink="/">{{ 'Go Back to the Homepage' | translate }}</a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</main>
<app-footer></app-footer>
