import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { OpenIdServiceService } from '../../../../core/services/openid-connect/open-id-service.service';

@Component({
  selector: 'app-different-session-message',
  templateUrl: './different-session-message.component.html',
  styleUrls: ['./different-session-message.component.css']
})
export class DifferentSessionMessageComponent implements OnInit {

  @Input() hash : string;

  constructor(private activatedRoute: ActivatedRoute, private openIdService : OpenIdServiceService, private router: Router) { }


  ngOnInit() {
  }

  get language() {
    return this.activatedRoute.snapshot.params.lang;
  }
  public confirmLogin(){
    this.openIdService.sendConfirmLogin(this.language, this.hash);
  }

  public navigateToDashboard() {
    this.router.navigate([this.language + '/dashboard']);
  }
}
