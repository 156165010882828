<footer>
  <div class="container">
    <div class="design-footer-menu">
      <div class="privacy pull-left">
                        <span class="dropdown-toggle noselect reset" data-toggle="dropdown">
                            {{ 'Privacy & Terms' | translate }}
                          <span class="caret"></span>
                        </span>
        <ul class="dropdown-menu">
          <li>
            <a href="https://diplomasafe.com/privacy/" target="_blank"
               rel="noopener noreferrer">
              {{ 'Privacy Statement' | translate }}
            </a>
          </li>
          <li>
            <a href="https://diplomasafe.com/eula/" target="_blank" rel="noopener noreferrer">
              {{ 'Terms & Conditions' | translate }}
            </a>
          </li>
          <li>
            <a href="https://diplomasafe.com/cookies/" target="_blank"
               rel="noopener noreferrer">
              {{ 'Cookie Policy' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="design-powered-by">
      <a href="https://www.diplomasafe.com">
        <div>{{ 'Powered By' | translate }}
          <img src="assets/img/logo_with_text_small_single_line.png" alt="">
        </div>
      </a>
    </div>
  </div>
</footer>

