import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';

import { DiplomaService } from '../../core/services/diploma/diploma.service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  providers: [DiplomaService],
  styleUrls: ['./mydiplomaspdf.component.css'],
  templateUrl: './mydiplomaspdf.component.html'
})
export class MyDiplomasPDFComponent implements OnInit, OnDestroy {
  public data: any;
  public diplomaName: any;
  public fileName: any;
  private errorMessage: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private diplomaService: DiplomaService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {
    this.langVal = environment.DEFAULT_LANGUAGE;
    if (this.params.lang) {
      this.langVal = this.params.lang;
    }
  }

  public ngOnInit() {
    this.generatePDF(this.params.uh, this.params.id, this.params.pno);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private generatePDF(uhash, id, value) {
    this.diplomaService.generateDiplomaPdf(uhash, id, this.params.lang, value).subscribe(
      (data) => this.initData(data),
      (error) => (this.errorMessage = error)
    );
  }

  private initData(data) {
    if (data.error) {
      this.data = data;

      return;
    }

    const url = encodeURI(data.pdf_url);
    this.data = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.diplomaName = data.course_name;

    this.diplomaService
      .downloadPdf(this.data.changingThisBreaksApplicationSecurity)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          let [, filename] = res.headers.get('content-disposition').split('filename=');
          filename = filename ? decodeURI(filename) : 'diploma-pdf';

          const blob = new Blob([res.body], { type: 'application/pdf' });

          // get user agent string
          const ua = navigator.userAgent.toLowerCase();

          // check if user agent is safari - open pdf instead of download it
          if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {

            window.location.href = window.URL.createObjectURL(blob);

          } else {
            FileSaver.saveAs(blob, filename);

            // Timeout for firefox
            setTimeout(() => {
              window.close();
            }, 1000);
          }

        },
        (err) => {
          console.log('err', err);
        }
      );
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
