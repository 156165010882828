import { Injectable } from '@angular/core';
import { DiplomaField, DiplomaFieldType } from 'app/core/models/diploma-field';

@Injectable({
  providedIn: 'root'
})
export class DiplomaFieldService {

  constructor() { }

  public tableSpecial(field: DiplomaField) {
    if(field.type === DiplomaFieldType.table && typeof field.value === 'object') {
      for(const col of field.settings.cols ) {
        if (col.specialShare) {
          return true;
        }
      }
    }
    return false;
  }
}
