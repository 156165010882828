import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-cancel-transfer-dialog',
  templateUrl: './confirm-cancel-transfer-dialog.component.html',
  styleUrls: ['./confirm-cancel-transfer-dialog.component.css']
})
export class ConfirmCancelTransferDialogComponent{

}
