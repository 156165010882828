import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";

import { ForgotPassword } from "../../core/models/forgot-password";
import { ForgotPasswordService } from "../../core/services/password/forgot-password.service";

import { CookieService } from "ngx-cookie-service";

import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";

import { FlashMessagesService } from "angular2-flash-messages";

import { FormValidationService } from "../../core/services/common-service/fromvalidation.service";

@Component({
  providers: [ForgotPasswordService, FormValidationService],
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  public emailFormControl = this.formValidationService.emailValidation();

  public params: any = this.activatedRoute.snapshot.params;
  public forgotPassword = new ForgotPassword("");
  public data: any;
  public status: any;
  private error: any;
  private langVal: any;
  private urlEmail;

  constructor(
    private formValidationService: FormValidationService,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private flashMessagesService: FlashMessagesService,
    private meta: Meta
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    if (localStorage.getItem("currentUser")) {
      this.router.navigate([this.langVal + "/dashboard"]);
    }
  }

  public ngOnInit() {
    this.status = false;
    const getUrlEmail = "email";
    this.activatedRoute.queryParams.subscribe((params) => {
      this.urlEmail = params[getUrlEmail];
    });

    this.forgotPassword = new ForgotPassword(this.urlEmail);
  }

  public onForgotPassword() {
    this.forgotPasswordService.onForgotPassword(this.forgotPassword, this.params.lang).subscribe(
      (data) => this.initData(data),
      (error) => this.initError(error)
    );
  }

  public initData(data) {
    if (data.error) {
      if (data.message.email) {
        this.flashMessagesService.show(data.message.email, { cssClass: "alert-danger", timeout: 5000 });
      } else {
        this.flashMessagesService.show(data.message, { cssClass: "alert-danger", timeout: 5000 });
      }
      this.data = data;
      this.status = false;
    } else {
      this.status = true;
    }
  }

  public initError(error) {
    this.error = error;
  }

  get language() {
    return this.params.lang;
  }
}
