<div class="modal-header">
    <div class="organization_image">
    </div>
    <h2 class="modal-title">
        {{ 'Which picture would you like to appear in /social/' | translate:{social: this.social} }}
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="this.dismiss('Close')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body">
    <div class="image-content" *ngIf="this.diplomaShareImages | async as shareOptions">
      <div *ngFor="let shareOption of shareOptions"
           class="btn btn-light d-inline-block share-image"
           (click)="this.selectShareOption(shareOption.value)">
          <img [src]="shareOption.imageUrl" alt="">
      </div>
    </div>
</div>
