<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center" *ngIf="data?.diploma_name else loadingPart">
    <article class="col-lg-8 col-md-7">
      <div class="diploma-details white-box margin-top-inverse-85">
        <div class="row">
          <div class="col-lg-4">
            <figure class="badge">
              <img src="{{ data?.diploma_image }}" alt="{{ data?.diploma_name }}" class="img-responsive diploma-image">
            </figure>
          </div>
          <div class=col-lg-8>
            <h1 class="margin-top-50 margin-bottom-40">
              {{ data?.diploma_name }}
            </h1>
          </div>
        </div>
        <div class="form">
          <div class="success-message margin-top-20" *ngIf="!res?.error">
            <p *ngIf="res">
              <i class="fa fa-check" aria-hidden="true"></i>
              {{ res?.message | translate }}
            </p>
          </div>
          <flash-messages></flash-messages>
          <table class="table margin-top-20 no-padding">
            <tr>
              <td class="font-18 width-100" valign="top">
                {{ 'Status' | translate }}:
              </td>
              <td class="border-bottom">
                <ui-switch class="ui-switch" defaultBgColor="#be0600" [(ngModel)]="status.status"></ui-switch>
                <div class="status-msg">
                  <div *ngIf="data?.diploma_expired; else notExpiredPart">
                    <div *ngIf="status.status; else privateExpriredPart">
                      <span class="private">
                          <i class="fa fa-times" aria-hidden="true"></i>&nbsp; {{ 'Expired' | translate }}!<br>
                          <span class="dim-text">
                              {{ 'If you no longer want this diplom to be visible, you can turn it off.' | translate }}
                          </span>
                      </span>
                    </div>
                    <ng-template #privateExpriredPart>
                        <span class="private">
                            <i class="fa fa-times" aria-hidden="true"></i>&nbsp; {{ 'Expired' | translate }}!<br>
                            <span class="dim-text">
                              {{ 'If you want this diplom to be visible, you can turn it on.' | translate }}
                            </span>
                        </span>
                    </ng-template>
                  </div>
                  <ng-template #notExpiredPart>
                      <div *ngIf="status.status; else privatePart">
                          <span class="public">
                              <i class="fa fa-check" aria-hidden="true"></i>&nbsp; {{ 'Visible for all' | translate }}<br>
                              <span class="dim-text">
                                  {{ 'You can make it private by turning it off.' | translate }}
                              </span>
                          </span>
                        </div>
                        <ng-template #privatePart>
                            <span class="private">
                                <i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                                <span *ngIf="data.has_special; else privateNoSpecial">
                                    {{ 'Private. Visible to you and anyone with an active view shared by you' | translate }}<br>
                                </span>
                                <ng-template #privateNoSpecial>
                                    {{ 'Private & only visible to you' | translate }}<br>
                                </ng-template>
                                <span class="dim-text">
                                    {{ 'You can make it visible by turning it on.' | translate }}
                                </span>
                            </span>
                        </ng-template>
                  </ng-template>
                </div>
              </td>
              <td *ngIf="hasExpiryDate" class="border-bottom">
                <span class="auto-delete-expired">
                  <ui-switch class="ui-switch" defaultBgColor="#be0600" [(ngModel)]="autoDeleteExpired" (change)="autoDeleteExpiredChange($event)"></ui-switch>
                  <div class="status-msg">
                    <span [ngClass]="autoDeleteExpired ? 'public' : 'private'">
                        <i class="fa" [ngClass]="autoDeleteExpired ? 'fa-check' : 'fa-times'" aria-hidden="true"></i>&nbsp; {{ 'Keep after expiry' | translate }}<br>
                    </span>
                  </div>
                </span>
              </td>
            </tr>
          </table>
          <div
            *ngIf="data.diploma_has_goals && data.pathways_enabled"
            class='request-button border-bottom'
          >
            <button
              (click)='editPathways()'
              mat-raised-button
              class='diploma-edit-button'
            >
              {{ 'Edit active pathways' | translate }}
            </button>
          </div>

          <div class='request-button border-bottom' *ngIf="data.has_support_email && !data.is_part_of_bundle">
            <app-wrong-name-request-button [lang]="langVal" [diplomaIsClaimed]="data.diploma_is_claimed" [templateType]="data.diploma_template_type"></app-wrong-name-request-button>
          </div>
          <div class='request-button border-bottom' *ngIf='!data.has_blockcert && !data.is_part_of_bundle'>
            <app-transfer-to-another-profile-button [diplomaData]="data" [accessToken]='userDetails.accessToken'></app-transfer-to-another-profile-button>
          </div>
          <div class="button-action">
            <button mat-raised-button type="button" class="submit" (click)="updateStatus()">
              <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
              {{ 'Update' | translate }}
            </button>
            <button class="reset" type="button" (click)="reset()">
              {{ 'Cancel Changes' | translate }}
            </button>
            <div class="margin-top-20">
              <button class="delete" type="button" (click)="deleteDiploma()">
                <i class="fa fa-times" aria-hidden="true"></i>
                {{ 'Delete' | translate }}
              </button>
              <em>({{ 'Attention: The diploma can not be restored, when deleted!' | translate }})</em>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
  <ng-template #loadingPart>
    <div class="row justify-content-md-center">
      <div class="col-md-8 col-lg-6">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
            {{ 'Loading... Please Wait' | translate }}...
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</main>
<app-footer></app-footer>
