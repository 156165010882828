import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { requestOptionsProvider } from './default-request-options.service';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { MomentModule } from 'ngx-moment';

import { SocialLoginModule } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { FacebookModule } from 'ngx-facebook';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { UiSwitchModule } from 'ngx-ui-switch';

import { FlashMessagesModule } from 'angular2-flash-messages';

import { CdkTableModule } from '@angular/cdk/table';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AboutDiplomasafeComponent } from './components/about-diplomasafe/about-diplomasafe.component';
import { RavenErrorHandler } from './core/services/common-service/sentry.service';
import { EditPasswordComponent } from './components/dashboard/editpassword.component';
import { EditProfileComponent } from './components/dashboard/editprofile.component';
import { DiplomaComponent } from './components/diploma/diploma.component';
import { DiplomaErrorComponent } from './components/diploma/diplomaerror.component';
import { DiplomaShortComponent } from './components/diploma/diplomashort.component';
import { DiplomaStatusComponent } from './components/diploma/diplomastatus.component';
import { MyDiplomasComponent } from './components/diploma/mydiplomas.component';
import { MyDiplomasPDFComponent } from './components/diploma/mydiplomaspdf.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/forgot-password/reset-password.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { NoStorageComponent } from './components/no-storage/no-storage.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TermsComponent } from './components/terms/terms.component';
import { UserComponent } from './components/user/user.component';

import { HelpComponent } from './components/help/help.component';
import { MissingEmailComponent } from './components/missing-email/missing-email.component';
import { PruningTranslationLoader } from './core/services/common-service/pruning-loader';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { UnsubscribeconfirmComponent } from './components/unsubscribe/unsubscribeconfirm.component';
import { DiplomaDesignViewComponent } from './components/diploma/diploma-design-view/diploma-design-view.component';
import {
  DesignViewHeaderComponent
} from './components/diploma/diploma-design-view/design-view-header/design-view-header.component';
import {
  DesignViewFooterComponent
} from './components/diploma/diploma-design-view/design-view-footer/design-view-footer.component';
import { VerifiedModalComponent } from './components/diploma/verified-modal/verified-modal.component';
import {
  DiplomaExpireDeleteWarningComponent
} from './components/diploma/diploma-expire-delete-warning-modal/diploma-expire-delete-warning-modal.component';
import { DiplomaLandingComponent } from './components/diploma/diploma-landing/diploma-landing.component';
import {
  ShareViewPasswordPromptComponent
} from './components/share-view/share-view-password-prompt/share-view-password-prompt.component';
import { ShareViewListComponent } from './components/share-view/share-view-list/share-view-list.component';
import { DiplomaService } from './core/services/diploma/diploma.service';
import {
  TwoFactorAuthenticationServise
} from './core/services/two-factor-authentication/two-factor-authentication.service';
import { ShareViewService } from './core/services/share-view/share-view.service';
import {
  DiplomaAddToProfileComponent
} from './components/diploma/diploma-add-to-profile/diploma-add-to-profile.component';
import { DiplomaValidationComponent } from './components/diploma/diploma-validation/diploma-validation.component';
import {
  DiplomaDesignViewImageComponent
} from './components/diploma/diploma-design-view/diploma-design-view-image/diploma-design-view-image.component';
import { OnHoverBackgroundDirective } from './on-hover-background.directive';
import { TemplateInformationComponent } from './components/diploma/template-information/template-information.component';
import {
  LearnMoreDescriptionModalComponent
} from './components/diploma/template-information/learn-more-description-modal/learn-more-description-modal.component';
import {
  DiplomaOnlineViewComponent
} from './components/diploma/diploma-design-view/diploma-online-view/diploma-online-view.component';
import { OpenIdComponentComponent } from './components/open-id-connect/open-id-component.component';
import {
  DifferentSessionMessageComponent
} from './components/open-id-connect/errors/different-session-message/different-session-message.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { UserDontExistComponent } from './components/open-id-connect/errors/user-dont-exist/user-dont-exist.component';
import {
  DesignViewHeaderBackgroundComponent
} from './components/diploma/diploma-design-view/design-view-header-background/design-view-header-background.component';
import { DiplomaShareDialogComponent } from './components/diploma/diploma-share-dialog/diploma-share-dialog.component';
import {
  DiplomaOtherFormatsComponent
} from './components/diploma/diploma-other-formats/diploma-other-formats.component';
import { BlockcertsService } from './core/services/blockcerts/blockcerts.service';
import { ClaimBaseComponent } from './components/claim/claim-base/claim-base.component';
import { AppRoutingModule } from './app-routing.module';
import { OrganizationService } from './core/services/organization/organization.service';
import { ClaimUnregisteredComponent } from './components/claim/claim-unregistered/claim-unregistered.component';
import {
  ClaimUnregisteredEmailPromptComponent
} from './components/claim/claim-unregistered-email-prompt/claim-unregistered-email-prompt.component';
import { ClaimRegisteredComponent } from './components/claim/claim-registered/claim-registered.component';
import { ClaimLoginComponent } from './components/claim/claim-login/claim-login.component';
import { ShareViewComponent } from './components/diploma/share-view/share-view.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ShareLinkDialogComponent } from './components/share-view/share-link-dialog/share-link-dialog.component';
import { BundleSidebarComponent } from './components/bundles/bundle-sidebar/bundle-sidebar.component';
import { DesignViewLoadingComponent } from './components/shared/design-view-loading/design-view-loading.component';
import { FrequentQuestionsComponent } from './components/help/frequent-questions/frequent-questions.component';
import { PathwayWidgetBaseComponent } from './components/pathways/widget/pathway-widget-base.component';
import { PathwaySettingsBaseComponent } from './components/pathways/settings/pathway-settings-base.component';
import { PathwaySettingsPageComponent } from './components/pathways/settings/page/pathway-settings-page.component';
import {
  TwoFactorAuthenticationBaseComponent
} from './components/access-level/two-factor-authentication/two-factor-authentication-base/two-factor-authentication-base.component';
import {
  TwoFactorAuthenticationSetupComponent
} from './components/access-level/two-factor-authentication/two-factor-authentication-setup/two-factor-authentication-setup.component';
import {
  TwoFactorAuthenticationPromptComponent
} from './components/access-level/two-factor-authentication/two-factor-authentication-prompt/two-factor-authentication-prompt.component';
import {
  WrongNameRequestComponent
} from './components/diploma/request-changes/wrong-name-request/wrong-name-request.component';
import { DiplomaRequestChangeService } from './core/services/diploma-request-change/diploma-request-change.service';
import {
  RequestSuccessPageComponent
} from './components/diploma/request-changes/wrong-name-request/request-success-page/request-success-page.component';
import { API_URL } from './constants';
import { environment } from 'environments/environment';
import {
  WrongNameRequestButtonComponent
} from './components/shared/wrong-name-request-button/wrong-name-request-button.component';
import {
  ConfirmClaimedDialogComponent
} from './components/shared/wrong-name-request-button/confirm-claimed-dialog/confirm-claimed-dialog.component';
import {
  ConfirmClaimDiplomaDialogComponent
} from './components/shared/wrong-name-request-button/confirm-claim-diploma-dialog/confirm-claim-diploma-dialog.component';
import {
  AlternativeLoginsListComponent
} from './components/auth/alternative-logins-list/alternative-logins-list.component';
import { SocialLoginsComponent } from './components/auth/social-logins/social-logins.component';
import {
  LinkedinLoginResponceComponent
} from './components/auth/handle-redirect/linkedin/linkedin-login-responce/linkedin-login-responce.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import {
  TransferToAnotherProfileButtonComponent
} from './components/diploma/request-changes/transfer-to-another-profile-button/transfer-to-another-profile-button.component';
import {
  ConfirmTransferDialogComponent
} from './components/diploma/request-changes/transfer-to-another-profile-button/confirm-transfer-dialog/confirm-transfer-dialog.component';
import {
  InitDiplomaTransferComponent
} from './components/diploma/request-changes/init-diploma-transfer/init-diploma-transfer.component';
import {
  ConfirmCancelTransferDialogComponent
} from './components/diploma/request-changes/transfer-to-another-profile-button/confirm-cancel-transfer-dialog/confirm-cancel-transfer-dialog.component';
import { TransferComponent } from './components/diploma/transfer/transfer.component';
import { TransferLoginComponent } from './components/diploma/transfer/transfer-login/transfer-login.component';
import {
  TransferUnregisteredComponent
} from './components/diploma/transfer/transfer-unregistered/transfer-unregistered.component';
import {
  TransferRegisteredComponent
} from './components/diploma/transfer/transfer-registered/transfer-registered.component';
import { DiplomaTransferService } from './core/services/diploma/diploma-transfer.service';
import { SwiperModule } from 'swiper/angular';
import {
  DiplomaShareButtonGroupComponent
} from './components/diploma/diploma-share/diploma-share-button-group/diploma-share-button-group.component';
import {
  DiplomaShareButtonComponent
} from './components/diploma/diploma-share/diploma-share-button/diploma-share-button.component';
import { DefaultMetaDataResolver } from './resolvers/metaData/default-meta-data.resolver';
import { MetaDataService } from './core/services/seo/meta-data.service';
import { DiplomaMetaDataResolver } from './resolvers/metaData/diploma-meta-data.resolver';
import { PathwayWidgetContentComponent } from './components/pathways/widget/pathway-widget-content/pathway-widget-content.component';
import { DiplomaModule } from './modules/diploma/diploma.module';

export function provideConfig() {
  return [
    // {
    //   id: GoogleLoginProvider.PROVIDER_ID,
    //   provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID),
    // },
    // {
    //   id: FacebookLoginProvider.PROVIDER_ID,
    //   provider: new FacebookLoginProvider(environment.FB_APP_ID),
    // },
  ];
}

// services list
const services: any[] = [
  CookieService,
  DiplomaService,
  ShareViewService,
  BlockcertsService,
  OrganizationService,
  TwoFactorAuthenticationServise,
  DiplomaRequestChangeService,
  DiplomaTransferService,
  MetaDataService
];
// resolvers list
const resolvers: any[] = [
  DefaultMetaDataResolver,
  DiplomaMetaDataResolver
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DiplomaComponent,
    DiplomaErrorComponent,
    DiplomaShortComponent,
    DiplomaStatusComponent,
    EditPasswordComponent,
    EditProfileComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    MyDiplomasComponent,
    MyDiplomasPDFComponent,
    NoStorageComponent,
    NotFoundComponent,
    UserComponent,
    TermsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AboutDiplomasafeComponent,
    HelpComponent,
    MissingEmailComponent,
    UnsubscribeComponent,
    UnsubscribeconfirmComponent,
    ShareViewListComponent,
    ShareViewPasswordPromptComponent,
    PathwayWidgetBaseComponent,
    PathwaySettingsBaseComponent,
    PathwaySettingsPageComponent,
    TwoFactorAuthenticationBaseComponent,
    TwoFactorAuthenticationSetupComponent,
    TwoFactorAuthenticationPromptComponent,
    DiplomaDesignViewComponent,
    DesignViewHeaderComponent,
    DesignViewFooterComponent,
    VerifiedModalComponent,
    DiplomaExpireDeleteWarningComponent,
    DiplomaLandingComponent,
    DiplomaAddToProfileComponent,
    DiplomaValidationComponent,
    DiplomaDesignViewImageComponent,
    OnHoverBackgroundDirective,
    TemplateInformationComponent,
    LearnMoreDescriptionModalComponent,
    DiplomaOnlineViewComponent,
    OpenIdComponentComponent,
    DifferentSessionMessageComponent,
    UserDontExistComponent,
    DesignViewHeaderBackgroundComponent,
    DiplomaShareDialogComponent,
    DiplomaOtherFormatsComponent,
    ClaimBaseComponent,
    ClaimUnregisteredComponent,
    ClaimUnregisteredEmailPromptComponent,
    ClaimRegisteredComponent,
    ClaimLoginComponent,
    ShareViewComponent,
    LoadingComponent,
    ShareLinkDialogComponent,
    BundleSidebarComponent,
    DesignViewLoadingComponent,
    FrequentQuestionsComponent,
    WrongNameRequestComponent,
    WrongNameRequestButtonComponent,
    RequestSuccessPageComponent,
    ConfirmClaimedDialogComponent,
    ConfirmClaimDiplomaDialogComponent,
    AlternativeLoginsListComponent,
    SocialLoginsComponent,
    LinkedinLoginResponceComponent,
    MaintenancePageComponent,
    TransferToAnotherProfileButtonComponent,
    ConfirmTransferDialogComponent,
    InitDiplomaTransferComponent,
    ConfirmCancelTransferDialogComponent,
    TransferComponent,
    TransferLoginComponent,
    TransferUnregisteredComponent,
    TransferRegisteredComponent,
    DiplomaShareButtonGroupComponent,
    DiplomaShareButtonComponent,
    PathwayWidgetContentComponent
  ],
  entryComponents: [
    HeaderComponent,
    FooterComponent,
    ShareLinkDialogComponent,
    ShareViewPasswordPromptComponent,
    DiplomaExpireDeleteWarningComponent,
    PathwayWidgetBaseComponent,
    PathwaySettingsBaseComponent,
    PathwaySettingsPageComponent,
    TwoFactorAuthenticationBaseComponent,
    TwoFactorAuthenticationSetupComponent,
    TwoFactorAuthenticationPromptComponent
  ],
  exports: [FooterComponent, HeaderComponent, DesignViewHeaderComponent, OnHoverBackgroundDirective, TranslateModule],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    FacebookModule.forRoot(),
    NgbModule,
    UiSwitchModule,
    FlashMessagesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory
      }
    }),
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MomentModule,
    LoadingBarHttpClientModule,
    SocialLoginModule,
    TranslateModule,
    SwiperModule,
    DiplomaModule,
  ],
  providers: [
    Meta,
    Title,
    requestOptionsProvider,
    TranslateService,
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: provideConfig()
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: API_URL,
      useValue: environment.API_START_URL
    },
    ...services,
    ...resolvers
  ]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
  // return new TranslateHttpLoader(http);
}
