import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { DiplomaService } from '../../core/services/diploma/diploma.service';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';

import { TrackLinks } from '../../core/services/common-service/tracklinks';
import { TrackLinksService } from '../../core/services/common-service/tracklinks.service';

@Component({
  providers: [DiplomaService, TrackLinksService],
  selector: 'app-mydiplomas',
  styleUrls: ['./mydiplomas.component.css'],
  templateUrl: './mydiplomas.component.html'
})
export class MyDiplomasComponent implements OnInit {
  public data: any;
  public result: any;
  public res: any;
  public datas: any;
  public resultLoading: boolean = true;
  private errorMessage: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;
  private trackLinks = new TrackLinks('', '', '', '');

  constructor(
    private router: Router,
    private diplomaService: DiplomaService,
    private trackLinksService: TrackLinksService,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate([this.langVal + '/login']);
    }
  }

  public ngOnInit() {
    const accessToken = this.userDetails.accessToken;
    this.getUserData(accessToken);
    this.getUserDiplomas(accessToken);
  }

  public getUserData(id) {
    this.diplomaService.getUserData(id).subscribe(
      (data) => this.data = data,
      (error) => (this.errorMessage = error)
    );
  }


  public getUserDiplomas(accessToken) {
    this.diplomaService.getUserDiplomas(accessToken, this.params.lang).subscribe(
      (result) => this.initDiplomaData(result),
      (error) => {
        this.resultLoading = false;
        this.errorMessage = error;
      }
    );
  }

  public initDiplomaData(result) {
    this.resultLoading = false;
    this.result = result;
  }

  public trackLink(link) {
    this.trackLinks = new TrackLinks(
      environment.APP_URL + this.router.url,
      link,
      localStorage.getItem('browserSession'),
      ''
    );
    this.trackLinksService.trackOutgoingLinks(this.trackLinks).subscribe(
      (data) => (this.datas = data),
      (error) => (this.errorMessage = error)
    );
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get language() {
    return this.params.lang;
  }

  get loginUrl() {
    return environment.APP_URL + '/' + this.language + '/login';
  }
}
