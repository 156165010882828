import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-claimed-dialog',
  templateUrl: './confirm-claimed-dialog.component.html',
  styleUrls: ['./confirm-claimed-dialog.component.css'],
})
export class ConfirmClaimedDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { templateType: string }
) {
}
}
