import { Component, Input } from '@angular/core';
import {DiplomaDesign} from "../../../../core/models/diploma";

@Component({
  selector: 'app-design-view-header-background',
  templateUrl: './design-view-header-background.component.html',
  styleUrls: ['./design-view-header-background.component.css']
})
export class DesignViewHeaderBackgroundComponent {
  @Input() public diplomaDesign: DiplomaDesign;

  constructor() { }

}
