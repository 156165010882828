/* tslint:disable:variable-name */
export class TrackLinks {
  constructor(
    public fromUrl: any,
    public toUrl: any,
    public session: any,
    public templateId: any,
  ) {}
}
/* tslint:enable:variable-name */
