<div class="modal-header">
  <h2 class="modal-title" *ngIf="identifierData">
    {{ 'Validate Personal Identifier' | translate }}
  </h2>
  <div *ngIf="value?.matched" class="text-center">
    <i class="fa fa-check" aria-hidden="true"></i>
    <h2 class="modal-title">
      {{ value?.matched }}
    </h2>
  </div>
  <div *ngIf="value?.mis_matched" class="text-center">
    <i class="fa fa-times" aria-hidden="true"></i>
    <h2 class="modal-title">
      {{ value?.mis_matched }}
    </h2>
  </div>
  <div *ngIf="value?.not_allowed" class="text-center">
    <i class="fa fa-exclamation" aria-hidden="true"></i>
    <h2 class="modal-title">
      {{ value?.not_allowed }}
    </h2>
  </div>
</div>
<div class="modal-body">
  <div class="container">
    <div *ngIf="identifierData">
      <mat-form-field>
        <input matInput type="text" placeholder="{{  data.pno_help_text }}" [(ngModel)]="identifierValue">
      </mat-form-field>
      <div>
        <button type="submit" class="btn btn-success" (click)="validateIdentifier(data?.diploma_hash, data?.diploma_nice_name, identifierValue)">
          {{ 'Next' | translate }}
        </button>
        <button class="reset" type="button" class="btn btn-default"  (click)="dismiss('Cross click')">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>
    <button class="reset" type="button" class="btn btn-default"  (click)="dismiss('Cross click'); value = null;identifierData=true;" *ngIf="value?.matched || value?.mis_matched || value?.not_allowed">
      {{ 'OK' | translate }}
    </button>
  </div>
</div>