import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTransferDialogComponent } from './confirm-transfer-dialog/confirm-transfer-dialog.component';
import {
  ConfirmCancelTransferDialogComponent
} from './confirm-cancel-transfer-dialog/confirm-cancel-transfer-dialog.component';
import {
  DiplomaRequestChangeService
} from '../../../../core/services/diploma-request-change/diploma-request-change.service';
import { takeUntil } from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-transfer-to-another-profile-button',
  templateUrl: './transfer-to-another-profile-button.component.html',
  styleUrls: ['./transfer-to-another-profile-button.component.css']
})
export class TransferToAnotherProfileButtonComponent implements OnDestroy {

  @Input() public diplomaData: any;
  @Input() public accessToken: string;
  public params: Params = this.activatedRoute.snapshot.params;

  private ngUnsubscribe$: Subject<void> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    public dialog: MatDialog,
    private diplomaRequestChangeService: DiplomaRequestChangeService,
    private flashMessagesService: FlashMessagesService
  ) {
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmTransferDialogComponent, {
      hasBackdrop: true,
      width: '500px',
      data: {
        templateType: this.diplomaData.diploma_template_type
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate([`/${this.params.lang}/initDiplomaTransfer/${this.params.uh}`]);
      }
    });
  }

  public cancelTransfer() {
    const dialogRef = this.dialog.open(ConfirmCancelTransferDialogComponent, {
      hasBackdrop: true,
      width: '500px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.diplomaRequestChangeService
          .cancelDiplomaTransferRequest(
            this.diplomaData.active_transfer_requests[0].uhash,
            this.params.lang, this.accessToken)
          // .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(
            (res) => {
              this.diplomaData.has_active_transfer_request = false;
              this.flashMessagesService.show(res.message, {
                cssClass: 'alert-success',
                timeout: 5000
              });
            },
            (err) => {

              this.flashMessagesService.show(err.error.message, {
                cssClass: 'alert-danger',
                timeout: 5000
              });
            }
          );
      }
    });
  }

}
