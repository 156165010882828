<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center" *ngIf="data else loadingPage">
    <article class="col-lg-9 col-md-8">
      <div class="diploma-details white-box margin-top-inverse-85">
        <div class="row">
          <div class="col-lg-4">
            <figure class="badge">
              <img src="{{ data?.diploma_image }}" alt="{{ data?.diploma_name }}" class="img-responsive diploma-image">
            </figure>
          </div>
          <div class="col-lg-8">
            <h1 class="margin-top-50 margin-bottom-40">
              {{ data?.diploma_name }}
            </h1>
            <table class="no-border table table-sm no-margin">
              <tr>
                <td>{{ 'Issued date' | translate }}:</td>
                <td>{{ data?.issue_date }}</td>
              </tr>
              <tr>
                <td>{{ 'Issued by'  | translate }}:</td>
                <td>{{ data?.organization_name }}</td>
              </tr>
              <tr *ngIf="data?.expiry_date">
                <td>{{ 'Expires' | translate }}:</td>
                <td><span>{{ data?.expiry_date }}</span></td>
              </tr>
            </table>
            <hr class="margin-top-40 margin-bottom-40">
            <div class="unsubscribe">
              <p class="margin-top-30">
                <a href="javascript:void(0)" class="button" (click)="unsubscribeDiploma()">
                  <i class="fa fa-check" aria-hidden="true"></i> &nbsp;
                  {{ 'Unsubscribe' | translate }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
  <ng-template #loadingPage>
    <div class="row justify-content-md-center">
      <div class="col-md-8 col-lg-6">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
            {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</main>
<app-footer></app-footer>
