import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'app/constants/api-routes';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class OpenBadgeService {

  constructor(private http: HttpClient) { }

  downloadBadge(diplomaHash) {
    return this.http.post(ApiRoutes.apiDownloadOpenBadge, {
      'diploma_hash': diplomaHash
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.userDetails.accessToken,
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }).subscribe((data: any) => {
        let fileName = diplomaHash + '.png';
        const blob = new Blob([data], {type: 'image/png'});
        FileSaver.saveAs(blob, fileName);

        return data;
      },
      err => {
        console.log(err);
      });
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
