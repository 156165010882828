<button
  class="diploma-custom-button"
  (click)="emitActionClick()"
  (mouseenter)='hover=true'
  (mouseleave)="hover=false"
  [ngStyle]="getButtonCss()"
>
  <span class="icon"><fa-icon [icon]="customButton?.icon"></fa-icon></span>
  <span class="text">{{ customButton?.name }}</span>
</button>
