import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Upass } from '../../core/models/dashboard/upass';
import { UpassService } from '../../core/services/dashboard/upass.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

import { FlashMessagesService } from 'angular2-flash-messages';

import { FormValidationService } from '../../core/services/common-service/fromvalidation.service';
import { NgForm } from '@angular/forms';

@Component({
  providers: [UpassService, FormValidationService],
  selector: 'app-edit-profile',
  styleUrls: ['./dashboard.component.css'],
  templateUrl: './editpassword.component.html'
})
export class EditPasswordComponent implements OnInit {
  public oldPasswordFormControl = this.formValidationService.passwordValidation();
  public newPasswordFormControl = this.formValidationService.passwordValidation();
  public confirmPasswordFormControl = this.formValidationService.passwordValidation();

  public data: any;
  public upass: Upass = new Upass('', '', '');
  private errorMessage: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;

  constructor(
    private router: Router,
    private formValidationService: FormValidationService,
    private upassService: UpassService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private flashMessagesService: FlashMessagesService
  ) {
  }

  public ngOnInit() {
    this.langVal = this.params.lang ? this.params.lang : environment.DEFAULT_LANGUAGE;

    if (!localStorage.getItem('currentUser')) {
      this.router.navigate([this.langVal + '/login']);
    }
  }

  public changePassword(form: NgForm) {
    const accessToken = this.userDetails.accessToken;
    this.upassService.changePassword(this.upass, accessToken).subscribe(
      (data) => this.initData(data, form),
      (error) => (this.errorMessage = error)
    );
  }

  public initData(data, form: NgForm) {
    this.data = data;
    if (data.error) {
      if (data.message.oldPassword) {
        this.flashMessagesService.show(data.message.oldPassword, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.newPassword) {
        this.flashMessagesService.show(data.message.newPassword, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.confirmPassword) {
        this.flashMessagesService.show(data.message.confirmPassword, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.errormsg) {
        this.flashMessagesService.show(data.message.errormsg, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    } else {
      this.flashMessagesService.show(data.message, {
        cssClass: 'alert-success',
        timeout: 5000
      });
      this.resetForm(form);
    }
  }

  public reset() {
    this.router.navigate([this.params.lang + '/dashboard']);
  }

  private resetForm(form: NgForm) {
    this.upass = new Upass('', '', '');
    form.resetForm();
    this.oldPasswordFormControl.reset();
    this.newPasswordFormControl.reset();
    this.confirmPasswordFormControl.reset();
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get diagnostic() {
    return JSON.stringify(this.upass);
  }
}
