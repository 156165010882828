import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiplomaService } from '../../core/services/diploma/diploma.service';
import { TrackLinksService } from '../../core/services/common-service/tracklinks.service';
import { DiplomaAccessLevel, DiplomaData, DiplomaDesign } from '../../core/models/diploma';
import { DiplomaFieldType } from '../../core/models/diploma-field';
import {
  ShareViewPasswordPromptComponent
} from '../share-view/share-view-password-prompt/share-view-password-prompt.component';
import { ShareViewService } from '../../core/services/share-view/share-view.service';
import { Observable } from 'rxjs';
import { DiplomaFieldService } from 'app/core/services/diploma/diploma-field.service';
import {
  TwoFactorAuthenticationBaseComponent
} from '../access-level/two-factor-authentication/two-factor-authentication-base/two-factor-authentication-base.component';
import { LanguageUtilService } from 'app/core/services/util/language-util.service';
import { UrlUtilService } from 'app/core/services/util/url-util.service';
import { CustomButtonGroupComponent } from 'app/modules/diploma/components/diploma-view-page/custom-button-group/custom-button-group.component';

//List view

@Component({
  providers: [TrackLinksService],
  selector: 'app-diploma',
  styleUrls: ['./diploma.component.css'],
  templateUrl: './diploma.component.html'
})
export class DiplomaComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  customButtonGroupComponent: CustomButtonGroupComponent;

  @Input() public withAccessLevel: boolean;
  @Input() public withGoalSelection: boolean;
  @Input() public diplomaAccessLevel: DiplomaAccessLevel;
  @Input() public sameUser: boolean;
  @Input() public openModel: any;
  @Input() public userHash: any;
  @Input() public userDetails: any;
  @Input() public trackLink: any;
  @Input() public shareData: any;
  @Input() public addToSocialProfile: any;
  @Input() public successful: any;
  @Input() public unsuccessful: any;
  @Input() public special: any;
  @Input() public diplomaDesign: DiplomaDesign;

  public data: any;
  public value: any;
  public datas: any;
  public identifierValue: any;

  @ViewChild('passwordModal', { static: false }) public modal: ShareViewPasswordPromptComponent;
  @ViewChild('selectShareMethodModal', { static: false }) public imageSelect: TemplateRef<any>;
  @ViewChild('accessLevelPrompt', { static: false }) public accessLevelPrompt: TwoFactorAuthenticationBaseComponent;
  public social;
  private firstTime: boolean = true;
  private errorMessage: string;
  private params: any = this.activatedRoute.snapshot.params;
  private identifierData = true;
  private specialShare: boolean;
  private specialFields: string[];
  public shareViewLoading: Observable<boolean>;

  constructor(
    private diplomaService: DiplomaService,
    private diplomaFieldService: DiplomaFieldService,
    private shareViewService: ShareViewService,
    private activatedRoute: ActivatedRoute,
    private languageUtilService: LanguageUtilService,
    private router: Router,
    private urlUtilService: UrlUtilService
  ) {
    this.shareViewLoading = this.diplomaService.getShareViewLoading();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.languageUtilService.updateLanguage(this.params.lang, false);
    this.specialShare = this.params.social === 'specialShare';
    this.checkRequestType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.firstTime && !this.withAccessLevel && !this.withGoalSelection && !this.special) {
      this.firstTime = false;
      this.getDiplomaData(this.params.uh, this.params.nicename);
    }
  }


  public getDiplomaData(uh, nicename) {
    const session = localStorage.getItem('browserSession');
    const accessToken = this.userDetails.accessToken;
    const adminHash = this.params?.view == 'adminView' ? this.params?.userHash : '';
    let token = null;
    if (adminHash) {
      token = this.activatedRoute.snapshot.queryParams['token'];
    }

    this.diplomaService.getPreviewType().subscribe((type) => {
      this.diplomaService
        .getDiplomaData(uh, nicename, this.params.lang, session, accessToken, this.specialShare || this.special, type, adminHash, token)
        .subscribe(
          (data) => this.initData(data),
          (error) => {
            this.errorMessage = error;
            this.router.navigate([this.params.lang + '/not-found'], {
              state: { errorMessage: 'Could not get diploma', isDiploma: true }
            });
          }
        );
    });
  }

  public initData(data) {
    if (data.incorrect) {
      this.router.navigate([this.params.lang + '/diploma-error']);
    } else if (!data.same_user && data.diploma_status === 2) {
      this.router.navigate([this.params.lang + '/not-found'], {
        state: { errorMessage: 'Permissions denied to view diploma', isDiploma: true }
      });
    } else {
      this.data = data;
      this.fillSpecialFields(data);

    }
  }

  public checkRequestType() {
    if (this.specialShare || this.special) {
      const subscription = this.shareViewService.checkPassword(this.params.uh).subscribe(
        (result) => {
          if (result) {
            this.modal.open(this.params.uh);
          } else {
            this.getDiplomaData(this.params.uh, this.params.nicename);
          }
          this.shareViewService.addSubscription(subscription);
        },
        () => {
          this.shareViewService.addSubscription(subscription);
          this.shareViewService.handleError(this.params.lang);
        }
      );

      return;
    }

    if (this.withAccessLevel) {
      this.accessLevelPrompt.setUserHash(this.userHash);
      return;
    }

    if (this.withGoalSelection) {
      return;
    }

    // if in ngOnChange value is not set to false load data
    if (this.firstTime) {
      this.getDiplomaData(this.params.uh, this.params.nicename);
    }
  }

  public htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  get language() {
    return this.params.lang;
  }

  // TODO
  public currentURL(diplomaCustomDomain) {
    return this.urlUtilService.getCurrentUrlForDiploma(diplomaCustomDomain) + this.router.url;
  }

  public validateIdentifier(uh, nicename, identifierValue) {
    const accessToken = this.userDetails.accessToken;
    this.diplomaService.validateIdentifier(uh, nicename, this.params.lang, accessToken, identifierValue).subscribe(
      (data) => this.initData1(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initData1(data) {
    this.value = data;
    this.data = this.getDiplomaData(this.params.uh, this.params.nicename);
    this.identifierData = false;
  }

  public fillSpecialFields(data: DiplomaData) {
    const diplomaFields = data.diploma.diplomaFieldData;
    this.specialFields = diplomaFields
      .filter((x) => {
        return x.special || this.diplomaFieldService.tableSpecial(x);
      })
      .map((x) => {
        return x.name;
      });
  }

  public handlePassword(event: boolean) {
    if (event) {
      this.getDiplomaData(this.params.uh, this.params.nicename);
    } else {
      this.router.navigate([this.params.lang + '/not-found'], {
        state: { errorMessage: 'Permissions denied to view diploma', isDiploma: true }
      });
    }
  }

  public handleAccessLevel(event: boolean) {
    if (event) {
      this.withAccessLevel = false;
    }
  }

  public handlePathwaySettingsUpdate(event: boolean) {
    if (event) {
      this.withGoalSelection = false;
    }
  }

  public ngOnDestroy(): void {
    this.shareViewService.destroySubscriptions();
  }

  public openImageSelect(social) {
    this.social = social;
    this.openModel(this.imageSelect, 'descriptionModal');
  }

  public handleShare(event, diplomaName, diplomaDescriptionPlain, diplomaTags, customDomain: string | null) {
    this.shareData(event, diplomaName, diplomaDescriptionPlain, diplomaTags, customDomain);
  }

  /**
   * Sorting function used from angular pipe keyvalue
   * @param a
   * @param b
   */
  public fieldsOrder(a, b) {
    const indexA = parseInt(a.key.replace('row', ''));
    const indexB = parseInt(b.key.replace('row', ''));

    return indexA - indexB;
  }

  public showTable(field) {
    return field.type === DiplomaFieldType.table && typeof field.value === 'object';
  }

  public showTableReplacement(field) {
    return field.type === DiplomaFieldType.table && typeof field.value === 'string' && field.value.length > 0;
  }
}
