import { Injectable } from '@angular/core';
import { SeoAndMetaTagsInterface } from '../../types/interfaces/seo/seo-and-meta-tags.interface';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class MetaDataService {

  constructor(private metaService: Meta, private titleService: Title) {
  }

  public setMetaData(currentUrl: string, currentLang: string, metaData?: SeoAndMetaTagsInterface, robotsNoIndex?: boolean): void {

    // If metadata is empty return
    if (!metaData) {
      return;
    }
    this.metaService.updateTag({ property: 'og:lang', content: currentLang });

    this.metaService.updateTag({ property: 'og:url', content: currentUrl });
    this.metaService.updateTag({ property: 'og:image', content: metaData.image });

    this.titleService.setTitle(metaData.title);
    this.metaService.updateTag({ property: 'og:title', content: metaData.title });

    this.metaService.updateTag({ name: 'description', content: metaData.description });
    this.metaService.updateTag({ property: 'og:description', content: metaData.description });

    // remove robots meta tag
    this.metaService.removeTag('name="robots"');

    if (metaData.robotsNoIndex || robotsNoIndex) {
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
    }


  }
}
