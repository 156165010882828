import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Bundle, BundleDiploma } from 'app/core/models/bundle';
import { DiplomaService } from 'app/core/services/diploma/diploma.service';

@Component({
  selector: 'app-bundle-sidebar',
  templateUrl: './bundle-sidebar.component.html',
  styleUrls: ['./bundle-sidebar.component.css']
})
export class BundleSidebarComponent implements OnInit, OnChanges {

  @Input() public bundle: Bundle;
  @Input() public currentDiplomaHash: string;

  @Output() public diplomaChange: EventEmitter<string> = new EventEmitter<string>();

  public activeDiplomaHash: string;
  public disableButtons: boolean = false;

  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeDiplomaHash = this.currentDiplomaHash;
    this.disableButtons = false;
  }

  ngOnInit() {
    this.activeDiplomaHash = this.currentDiplomaHash;
  }

  changeUrl(diplomaHash: string) {
    this.disableButtons = true;
    this.activeDiplomaHash = diplomaHash;
    this.diplomaChange.emit(diplomaHash);
  }

  bundleDiplomaOrder(a: BundleDiploma, b: BundleDiploma) {
    return a.weight - b.weight;
  }
}
