
<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 form">
        <h1 class="margin-bottom-20">{{ 'Update Email' | translate }}:</h1>
        <h5 class="margin-bottom-25 text-normal">
          {{ 'We could not find your email address.' | translate }}<br>
          {{ 'Please update your email address.' | translate }}
        </h5>
        <flash-messages></flash-messages>
        <form (ngSubmit)="onSubmit()">
          <mat-form-field>
            <input matInput type="email" name="username" placeholder="{{ 'Type your e-mail address' | translate }}" [(ngModel)]="email.email" [formControl]="emailFormControl">
            <mat-error *ngIf="emailFormControl.hasError('pattern')">
              {{ 'Please enter a valid email address' | translate }}
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              {{ 'Email is required' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="button-action">
            <button mat-raised-button type="submit" class="submit">
              {{ 'Update' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
