import { Location } from '@angular/common';
import { Subject, Observable, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PathwayService } from '../../../core/services/pathway/pathway.service';

@Component({
  providers: [PathwayService],
  selector: 'app-pathway-settings-base',
  templateUrl: './pathway-settings-base.component.html',
  styleUrls: ['./pathway-settings-base.component.css']
})
export class PathwaySettingsBaseComponent implements OnInit, OnDestroy {
  @Input() public isEdit: boolean;
  @Input() public orgainzationImage: string;
  @Input() private diplomaHash: string;

  @Output() public onSave = new EventEmitter<boolean>();

  public data: Observable<any>;

  public visibleForThirdParty: boolean = true;

  private params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private pathwayService: PathwayService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.fetchPathways();

    // set visibility for 3rd party users checkbox value
    this.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.visibleForThirdParty = data.visibleForThirdPartyUsers;
    }, (error) => {
      this.router.navigate([this.params.lang + '/not-found'], {
        state: { errorMessage: error.error.message }
      });
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public emitOnSave(saved: boolean): void {
    this.onSave.emit(saved);
  }

  public pathwayToggle(pathway: any, data: any): void {
    // REMOVE TO SELECT MULTIPLE PATHWAYS
    data.pathways.forEach((pathway) => (pathway.active = false));

    // Set active pathway
    pathway.active = !pathway.active;

    // Set not selected
    const result = data.pathways.find(({ active }) => active == 1);
    data.noSelected = result ? false : true;
  }

  public noPathwaySelected(data: any): void {
    data.noSelected = true;
    data.pathways.forEach((pathway) => (pathway.active = false));
  }

  public saveSettings(pathways: any, skip: boolean = false): void {

    const goalsData = this.formatPathways(pathways, skip);

    const formData = {
      goals: goalsData,
      visibleForThirdParty: this.visibleForThirdParty
    };

    this.pathwayService
      .saveSettings(this.diplomaHash, formData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.isEdit ? this.toDiploma() : this.emitOnSave(true);
        },
        (err) => {
          this.pathwayService.handleError(this.params.lang);
        }
      );
  }

  public back(): void {
    this.location.back();
  }

  private toDiploma() {
    this.pathwayService.redirectToDiploma(this.diplomaHash);
  }

  private fetchPathways(): void {
    this.data = this.pathwayService.fetchDiplomPathwaysForSettings(this.diplomaHash);
  }

  private formatPathways(pathways: any, skip: boolean): object {
    return [...pathways].reduce(
      (obj, pathway) => ({ ...obj, [pathway.id]: { active: skip ? false : pathway.active } }),
      {}
    );
  }
}
