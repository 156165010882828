<app-header *ngIf="this.withAccessLevel else designViewHeader" [diplomaDesign]="this.diplomaDesign" [defaultFont]="false"></app-header>
<ng-template #designViewHeader>
    <app-design-view-header-background [diplomaDesign]="this.diplomaDesign"></app-design-view-header-background>
</ng-template>

<ng-container *ngIf="data | async as data else null">
    <app-design-view-header [smallResolution]="true" [diplomaDesign]="this.diplomaDesign" [sameUser]="data?.diploma.same_user" [customSuportLink]="data.organization.customSupportPageLink">
    </app-design-view-header>
</ng-container>
<div class="container">
    <flash-messages></flash-messages>
    <div class="row flex-lg-row" *ngIf="data | async as data else loadingPart">
        <div class="diploma-wrapper">
            <div class="pl-0 pr-0 middle-col">
                <div class="diploma-information mx-auto">
                    <div class="info-header {{language}}">
                        <div class="header-item" id="verified">
                            <a title="{{'Click to see what this means' | translate}}"
                                (click)="openModel(verifiedModal, 'verifiedModal')">
                                <span class="iconify shield-icon" data-icon="zmdi-shield-check"
                                    data-inline="true"></span>
                                {{ 'Verified' | translate }}
                            </a>
                        </div>
                        <div class="header-item" *ngIf="data?.diploma.same_user" id="visibleToAll">
                            <a id="public" *ngIf="data?.diploma.diploma_status === 1; else privateLink"
                                routerLink="/{{ language }}/pdip/{{ data?.diploma.diploma_hash }}/1" target="_blank"
                                title="{{ 'Click to see how third parties see your /term/.\nYou can change the visibility in settings' | translate: {term: data?.diploma.template_term} }}">
                                <span class="iconify" data-icon="el:unlock-alt" data-inline="false"></span>
                                <span class="visible-text">{{ 'Visible to all' | translate }}</span>
                            </a>
                            <ng-template #privateLink>
                                <a id="private" routerLink="/{{ language }}/pdip/{{ data?.diploma.diploma_hash }}/1"
                                    target="_blank"
                                    title="{{ 'Click to see how third parties see your /term/.\nYou can change the visibility in settings' | translate: {term: data?.diploma.template_term} }}">
                                    <span class="iconify" data-icon="el-lock-alt" data-inline="false"></span>
                                    <span class="visible-text">{{ 'Private' | translate }}</span></a>
                            </ng-template>
                        </div>
                        <div class="header-item" *ngIf="data?.diploma.same_user" id="settings">
                            <a routerLink="/{{ language }}/editDiplomaStatus/{{ data?.diploma.diploma_hash }}/{{ data?.diploma.diploma_nice_name }}"
                                class="settings">
                                <i class="fa fa-cog" aria-hidden="true"></i>
                                {{ 'Settings' | translate }}&nbsp;
                            </a>
                        </div>
                    </div>
                    <div *ngIf="this.diplomaLoading | async else diploma_data">
                        <div class="diploma-presentation shadow">
                            <app-design-view-loading
                                [visibleText]="false"
                                [specialShareLoad]="false"
                            ></app-design-view-loading>
                        </div>
                    </div>
                    <ng-template #diploma_data>
                        <div class="diploma-presentation shadow" *ngIf="diplomaDesign.presentation_view == 2 else onlineView">
                            <app-diploma-design-view-image [views]="data?.diploma.views" [initialPage]=0
                                [openModel]="openModel" [sameUser]="data?.diploma.same_user">
                            </app-diploma-design-view-image>
                        </div>
                        <ng-template #onlineView>
                            <div class="diploma-presentation shadow online-view-info"
                                [ngClass]="{'auto-height': seeMore === false,
                                            'height-622': seeMore === true}">
                                <app-diploma-online-view [language]="language" [data]="data" [seeMore]="seeMore"
                                    [setSeeMore]="setSeeMore.bind(this)">
                                </app-diploma-online-view>
                            </div>
                        </ng-template>
                        <div *ngIf="seeMore === true" (click)="setSeeMore(false)" class="outside-see-more">
                            <span>{{ 'See more' | translate }}</span>
                        </div>
                        <div *ngIf="seeMore === false" (click)="setSeeMore(true)" class="outside-see-more">
                            <span>{{ 'See less' | translate }}</span>
                        </div>
                    </ng-template>
                </div>

                <!-- Pathways widgets -->
                <ng-container *ngIf="data?.diploma.pathways_enabled && data?.diploma.has_active_goals && data?.diploma.display_goals_widget">
                    <app-pathway-widget-base
                        [diplomaHash]="data.diploma.diploma_hash"
                    ></app-pathway-widget-base>
                </ng-container>
                <!-- Pathways widgets END -->

                <div class="organization-information mx-auto shadow">
                    <div class="company-details white-box">
                        <div class="row">
                            <div class="col-lg-4 align-self-top" *ngIf=" data?.organization.image">
                                <figure class="image">
                                    <img src="{{ data?.organization.image }}" class="org-img-responsive" />
                                </figure>
                            </div>
                            <div class="col-lg-{{ (data?.organization.image) ? '8' : '12' }}">
                                <span class="organization-bold orgNameWrap">
                                    {{ 'About /organization_name/' | translate:{organization_name:  data?.organization.name_about} }}
                                </span>
                                <p *ngIf=" data?.organization.description" [innerHTML]=" data?.organization.description"
                                    class="organization-description"></p>
                                <div class="social-follow"
                                    *ngIf=" data?.organization.web || data?.organization.facebook || data?.organization.linkedin || data?.organization.twitter || data?.organization.instagram">
                                    <span class="organization-bold">
                                        {{ 'Follow us on' | translate }}
                                    </span>
                                    <a class="organization-social" href="{{ data?.organization.web }}"
                                        *ngIf="data?.organization.web" target="_blank" rel="noopener noreferrer"
                                        (click)="trackLink( data?.organization.web, data?.diploma.template_id)">
                                        <i class="fa fa-home" aria-hidden="true"></i>
                                    </a>
                                    <a class="organization-social" href="{{ data?.organization.facebook }}"
                                        *ngIf=" data?.organization.facebook" target="_blank" rel="noopener noreferrer"
                                        (click)="trackLink( data?.organization.facebook, data?.diploma.template_id)">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                    <a class="organization-social" href="{{  data?.organization.linkedin }}"
                                        *ngIf=" data?.organization.linkedin" target="_blank" rel="noopener noreferrer"
                                        (click)="trackLink(data?.organization.linkedin, data?.diploma.template_id)">
                                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                    <a class="organization-social" href="{{  data?.organization.twitter }}"
                                        *ngIf=" data?.organization.twitter" target="_blank" rel="noopener noreferrer"
                                        (click)="trackLink(data?.organization.twitter, data?.diploma.template_id)">
                                        <div class="icon-wrapper">
                                            <img src="../../../assets/img/x-social-media-white-icon.png" alt="Twitter" aria-hidden="true">
                                        </div>
                                    </a>
                                    <a class="organization-social instagram" href="{{  data?.organization.instagram }}"
                                        *ngIf=" data?.organization.instagram" target="_blank" rel="noopener noreferrer"
                                        (click)="trackLink(data?.organization.instagram, data?.diploma.template_id)">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-design-view-footer class="show-above-1000"></app-design-view-footer>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 social-actions pr-0 side-container-wrapper {{language}}"
                *ngIf="data?.diploma.same_user else publicView">
                <app-design-view-header [diplomaDesign]="this.diplomaDesign" [sameUser]="data?.diploma.same_user" [customSuportLink]="data.organization.customSupportPageLink">
                </app-design-view-header>
                <div class="side-container">
                    <div class="side-text shadow-heavy"
                        [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.header_color ? diplomaDesign?.header_color : ''"
                        [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''">
                        <figure class="image">
                            <img src="{{ data?.recipient.recipient_image }}" alt="User Image" class="img-responsive">
                        </figure>
                        <div class="bottom-arrow"
                            [style.borderTopColor]="diplomaDesign?.custom_colors && diplomaDesign?.header_color ? diplomaDesign?.header_color : ''">
                        </div>
                        <span class="side-text-title">
                            {{ 'Hi, /recipient_name/' | translate:{recipient_name: data?.recipient.recipient_name} }}
                        </span>
                        <span *ngIf="data.diploma?.with_some_buttons; else noShareSide">
                            <div class="side-text-content"
                                *ngIf="this.language == 'ru-RU' && data?.diploma.template_term == 'диплом' else defaultSideText">
                                {{ 'You can easily share your digital diploma with your friends, family and colleagues.' | translate }}
                            </div>
                            <ng-template #defaultSideText>
                                <div class="side-text-content">
                                    {{ 'You can easily share your digital /term/ with your friends, family and colleagues.' | translate: {term: data?.diploma.template_term} }}
                                </div>
                            </ng-template>
                        </span>
                        <ng-template #noShareSide>
                            <div>
                                {{ 'Congratulations on your digital /term/.' | translate: {term: data?.diploma.template_term } }}<br>
                            </div>
                        </ng-template>
                    </div>
                    <app-bundle-sidebar
                        *ngIf="data.diploma.bundle.hasBundle"
                        [bundle]="data.diploma.bundle.bundle"
                        [currentDiplomaHash]="data.diploma.diploma_hash"
                        (diplomaChange)="changeDiplomaData($event)"
                    ></app-bundle-sidebar>
                    <div class="side-buttons shadow">
                        <span
                            *ngIf="data.diploma?.with_some_buttons"
                            class="side-text-title button-title"
                        >
                            {{ 'Start sharing' | translate }}
                        </span>
                        <div class="share-links">
                            <!-- Social share -->
                            <app-diploma-share-button-group
                                *ngIf="data.diploma?.with_some_buttons"
                                [diploma]="data?.diploma"
                                [linkedinDirectShare]="data?.organization.linkedinDirectShare"
                                [userLinkedinId]="data?.recipient.linkedin_id"
                            ></app-diploma-share-button-group>
                            <!-- End Social share -->
                            <span class="side-text-title button-title other-options-title">
                                {{ (data.diploma?.with_some_buttons ? "Other options" : "Options") | translate }}
                            </span>
                            <a routerLink="/{{ language }}/myDiplomaPDF/{{ data?.diploma.diploma_hash }}/{{ data?.diploma.diploma_id }}/generatePDF"
                                target="_blank" rel="noopener noreferrer" *ngIf="!data?.diploma.pno_has_value">
                                <div class="social-login-btn other-options ml-0">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
                                    {{ 'Download PDF' | translate }}
                                </div>
                            </a>
                            <a href="javascript:void(0)" (click)="openModel(downloadPdfModal, 'downloadPdfModal')"
                                *ngIf="data?.diploma.pno_has_value">
                                <div class="social-login-btn other-options ml-0">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
                                    {{ 'Download PDF' | translate }}
                                </div>
                            </a>

                            <a routerLink="/{{ language }}/dip-other-formats/{{ data?.diploma.diploma_hash }}/{{data?.recipient.recipient_nice_name}}" rel="noopener noreferrer" *ngIf="data?.diploma.other_formats">
                              <div class="social-login-btn other-options ml-0">
                                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;
                                {{ 'Other Formats' | translate }}
                              </div>
                            </a>

                            <a
                                *ngIf="data.diploma?.with_some_buttons"
                                (click)="addToSocialProfile('LinkedinProfile', data)"
                            >
                                <div class="social-login-btn other-options ml-0">
                                    <i class="fa fa-linkedin" aria-hidden="true"></i>&nbsp;
                                    {{ 'Add to Profile' | translate }}
                                </div>
                            </a>
                            <a href="javascript:void(0)" *ngIf="specialFieldsResult.length == 0 && this.diplomaAccessLevel?.access_level == 0; else elseBlock"
                                (click)="this.diplomaService.copyToClipboard(this.currentURL(data.diploma.diploma_custom_domain, data.diploma.diploma_hash), this.successful, this.unsuccessful)">
                                <div class="social-login-btn other-options ml-0">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;
                                    {{ 'Copy/share URL' | translate }}
                                </div>
                            </a>
                            <a href="javascript:void(0)" *ngIf="specialFieldsResult?.length > 0"
                                class="verified hidden-md-down text-center special-share"
                                (click)="openModel(whatIsShared, 'verifiedModal')">
                                <p class="margin-top-20">
                                    {{'What is being shared?' | translate}} <br>
                                    <span class="special-share-read-more">
                                        {{ 'Read more' | translate}} >>
                                    </span>
                                </p>
                                <ng-template #whatIsShared let-c="close" let-d="dismiss">
                                    <div class="modal-header">
                                        <h1 class="modal-title green-color">
                                            {{ 'What is being shared?' | translate }}
                                        </h1>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="d('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>
                                            {{ 'When sharing your diploma on social medias, viewers will be able to access your diploma including the diploma data as you see them except for the following data, which will be hidden:' | translate}}
                                        </p>
                                        <ul>
                                            <li *ngFor="let field of specialFieldsResult">
                                                {{ field }}
                                            </li>
                                        </ul>
                                        <p>
                                            {{ "If you want to share a password protected version where the more data is visible, you can use the 'Copy/share URL'-button." | translate }}
                                        </p>
                                    </div>
                                </ng-template>
                            </a>
                            <ng-template #elseBlock>
                                <a routerLink="/{{language}}/diploma-share-view/{{data?.diploma.diploma_hash}}">
                                    <div class="social-login-btn other-options ml-0">
                                        <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;
                                        {{ 'Copy/share URL' | translate }}
                                    </div>
                                </a>
                            </ng-template>
                            <a routerLink="/{{ language }}/editProfile" [queryParams]="{tab: 1}"
                                *ngIf="data.diploma.has_special">
                                <div class="social-login-btn other-options ml-0">
                                    <i class="fa fa-share" aria-hidden="true"></i>
                                    {{ 'Manage Views' | translate }}
                                </div>
                            </a>

                            <!-- Custom Buttons -->
                            <app-custom-button-group
                                *ngIf="data?.organization.enabledCustomButtons"
                                [templateId]="data?.diploma.template_id"
                                [diplomaHash]="data?.diploma.diploma_hash"
                            ></app-custom-button-group>
                            <!-- End Custom Buttons -->
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #publicView>
                <div
                    class="col-xl-2 col-lg-3 col-md-12 col-sm-12 social-actions pr-0 public-side side-container-wrapper {{params.lang}}">
                    <app-design-view-header [diplomaDesign]="this.diplomaDesign" [sameUser]="data?.diploma.same_user" [customSuportLink]="data.organization.customSupportPageLink">
                    </app-design-view-header>
                    <div class="side-container public-view" [ngClass]="{'logged-in': userDetails}">
                      <div class="side-text shadow-light" *ngIf='!userDetails'>
                        <span class="side-text-title" >
                          {{ 'Is this your /template_type/?' | translate: {template_type: data?.diploma.template_term} }} <br/> {{ 'Log in to share or download' | translate }}
                            </span>
                      </div>
                        <app-template-information [templateId]="data?.diploma.template_id" [viewType]="2"
                            [trackLink]="trackLink" [description]="data?.diploma.diploma_description"
                            [templateLink]="data?.diploma.template_link" [diplomaName]="data?.diploma.diploma_name"
                            [diplomaDesign]="diplomaDesign" [learnMoreFunction]="data?.diploma.learn_more_function"
                            [organizationImage]="data?.organization.image" [openModel]="openModel">
                        </app-template-information>
                        <a href="{{ data?.organization.web }}" target="_blank">
                            <div class="div-shadow margin-top-20 side-buttons"
                                [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.organization_box_color ? diplomaDesign?.organization_box_color : ''">
                                <span class="box-title"
                                    [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.organization_box_text_color ? diplomaDesign?.organization_box_text_color : ''">
                                    {{ 'Want to know more about /organization_name/' | translate:{organization_name: data?.organization.name_learn_more} }}?
                                </span>
                                <div class="box-link" (click)="trackLink(data?.organization.web, data?.diploma.template_id)"
                                    [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.organization_box_text_color ? diplomaDesign?.organization_box_text_color : ''">
                                    {{ 'Read more' | translate }} >>
                                </div>
                            </div>
                        </a>
                        <div class="side-buttons div-shadow margin-top-20 pno-validation" *ngIf="data?.diploma.pno_label"
                            (click)="openModel(validationModal, 'validationModal')">
                            <span class="box-title">
                                {{ 'Validate' | translate }}
                            </span>
                            <p class="box-title">{{ data?.diploma.pno_label }}</p>
                            <div class="box-link">
                                {{ 'Click here' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #selectShareMethodModal let-d="dismiss">
              <app-diploma-share-dialog
                [diplomaHash]="data?.diploma.diploma_hash"
                [dismiss]="d"
                [social]="this.social"
                (selectEvent)="handleShare($event, data?.diploma.diploma_name, data?.diploma.diploma_description_plain, data?.diploma.diploma_tags, data.diploma.diploma_custom_domain)">
              </app-diploma-share-dialog>
            </ng-template>
            <ng-template #verifiedModal let-c="close" let-d="dismiss">
                <app-verified-modal [data]="data" [language]="language" [dismiss]="d"></app-verified-modal>
            </ng-template>
            <ng-template #downloadPdfModal let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h2 class="modal-title" *ngIf="identifierData">
                        {{ 'Validate Personal Identifier' | translate }}
                    </h2>
                    <div *ngIf="value?.matched" class="text-center">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.matched }}
                        </h2>
                    </div>
                    <div *ngIf="value?.mis_matched" class="text-center">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.mis_matched }}
                        </h2>
                    </div>
                    <div *ngIf="value?.not_allowed" class="text-center">
                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.not_allowed }}
                        </h2>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div *ngIf="identifierData">
                            <mat-form-field>
                                <input matInput type="text" placeholder="{{ data?.diploma.pno_help_text }}"
                                    [(ngModel)]="identifierValue">
                            </mat-form-field>
                            <div class="button-group">
                                <button mat-raised-button type="submit" class="btn btn-light"
                                    (click)="validateIdentifier(data?.diploma.diploma_hash, data?.diploma.diploma_nice_name, identifierValue)">
                                    {{ 'Next' | translate }}
                                </button>
                                <button mat-flat-button type="button" class="btn btn-secondary reset" (click)="d('Cross click')">
                                    {{ 'Cancel' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="button-group">
                            <a
                                mat-raised-button
                                *ngIf="value?.matched"
                                routerLink="/{{ language }}/myDiplomaPDF/{{ data?.diploma.diploma_hash }}/{{ data?.diploma.diploma_id }}/{{ value.value }}/generatePDF"
                                target="_blank"
                                class="btn btn-light"
                                rel="noopener noreferrer"
                            >
                                <div class="pdf">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
                                    {{ 'Download PDF' | translate }}
                                </div>
                            </a>
                            <button
                                mat-flat-button
                                type="button"
                                class="btn btn-secondary reset"
                                (click)="d('Cross click');value = null; identifierData = true;"
                                *ngIf="value?.matched || value?.mis_matched || value?.not_allowed">
                                {{ 'Cancel' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Adds add to profile modal. Kept for future reference.
            <ng-template #addToProfileModal let-c="close" let-d="dismiss">
                <app-diploma-add-to-profile [dismiss]="d" [cancel]="c" [successful]="this.successful"
                    [unsuccessful]="this.unsuccessful" [language]="this.language" [data]="data"
                    [currentURL]="this.currentURL">
                </app-diploma-add-to-profile>
            </ng-template> -->
            <ng-template #validationModal let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h2 class="modal-title" *ngIf="identifierData">
                        {{ 'Validate Personal Identifier' | translate }}
                    </h2>
                    <div *ngIf="value?.matched" class="text-center">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.matched }}
                        </h2>
                    </div>
                    <div *ngIf="value?.mis_matched" class="text-center">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.mis_matched }}
                        </h2>
                    </div>
                    <div *ngIf="value?.not_allowed" class="text-center">
                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                        <h2 class="modal-title">
                            {{ value?.not_allowed }}
                        </h2>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div *ngIf="identifierData">
                            <mat-form-field>
                                <input matInput type="text" placeholder="{{  data?.diploma.pno_help_text }}"
                                    [(ngModel)]="identifierValue">
                            </mat-form-field>
                            <div>
                                <button type="submit" class="btn btn-default"
                                    (click)="validateIdentifier(data?.diploma.diploma_hash, data?.diploma.diploma_nice_name, this.identifierValue)">
                                    {{ 'Next' | translate }}
                                </button>
                                <button class="reset" type="button" class="btn btn-default" (click)="d('Cross click')">
                                    {{ 'Cancel' | translate }}
                                </button>
                            </div>
                        </div>
                        <button class="reset" type="button" class="btn btn-default"
                            (click)="d('Cross click'); value = null;identifierData=true;"
                            *ngIf="value?.matched || value?.mis_matched || value?.not_allowed">
                            {{ 'OK' | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<app-design-view-footer class="hide-below-1000"></app-design-view-footer>
<ng-template #loadingPart>
  <ng-container *ngIf="((!this.special && this.withAccessLevel) || this.withGoalSelection) else loading">

    <div
      *ngIf="!this.special && this.withAccessLevel else withGoalSelectionRef"
      class="diploma-presentation shadow middle-col align-vertically"
    >
      <app-two-factor-authentication-base
        [sameUser]="this.diplomaAccessLevel?.same_user"
        [has2faCode]="this.diplomaAccessLevel?.has_2fa_code"
        [templateType]="this.diplomaAccessLevel?.template_type"
        [orgainzationImage]="this.diplomaAccessLevel?.org_image"
        (isValid)="handleAccessLevel($event)"
        #accessLevelPrompt
      ></app-two-factor-authentication-base>
    </div>
    <ng-template #withGoalSelectionRef>
      <div
        class="diploma-presentation shadow middle-col align-vertically"
        *ngIf="this.withGoalSelection"
      >

        <app-pathway-settings-base
          [diplomaHash]="this.params.uh"
          (onSave)="handlePathwaySettingsUpdate($event)"
        ></app-pathway-settings-base>

      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>

    <div class="diploma-presentation shadow middle-col">
      <div class="row justify-content-md-center loader">
        <div class="col-md-8 col-lg-6">
          <div class="white-box margin-top-inverse-85 loading-page text-center">
            <h1 class="margin-bottom-30 green-color">
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
              <span class="sr-only">{{ 'Loading' | translate }}...</span>
            </h1>
            <p *ngIf="this.shareViewLoading | async else genericLoading">
              {{ 'Preparing the View' | translate }}...
            </p>
            <ng-template #genericLoading>
              {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
<app-share-view-password-prompt (passwordCheck)="handlePassword($event)" #passwordModal>
</app-share-view-password-prompt>
