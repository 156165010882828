import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor() {}

  initGoogle(callback: Function): boolean {
    google.accounts.id.initialize({
      client_id: environment.GOOGLE_CLIENT_ID,
      callback: (res: any) => {
        if (res.credential) {
          let decoded: any = jwt_decode(res.credential);

          callback(decoded.sub || '', decoded.email || '');
        }
      },
    });

    return true;
  }

  public googleLoginButton(googleButton: any) {
    google.accounts.id.renderButton(
      googleButton.nativeElement,
      {
        type: 'standard',
        size: 'large',
        width: '300',
        text: 'continue_with',
        shape: 'circle',
      } // customization attributes
    );
  }

  public googleLink(googleButton: any) {
    google.accounts.id.renderButton(
      googleButton.nativeElement,
      {
        type: 'standard',
        size: 'small',
      } // customization attributes
    );

    // click on google sign in
    let element: HTMLElement = document.querySelector('div[role="button"]') as HTMLElement;
    element.click();
  }
}
