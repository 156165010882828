import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { OtpAppQrCodes } from 'app/core/models/qr-codes';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TwoFactorAuthentication, UserTwoFactorQR } from "../../models/two-factor-authentication";

const OTP_QR_CODES = () => environment.API_START_URL + `api/get-otp-app-qr-codes`;
const ACTIVATE_2FA = (uHash: string) => environment.API_START_URL + `api/activate-2fa/${uHash}`;
const DEACTIVATE_2FA = (uHash: string) => environment.API_START_URL + `api/deactivate-2fa/${uHash}`;
const VALIDATE_TOKEN = (uHash: string) => environment.API_START_URL + `api/validate-token/${uHash}`;
const VERIFY_2FA_Code = (uHash: string) => environment.API_START_URL + `api/verify-action/${uHash}`;
const USER_2FA_QR_CODE = (uHash: string) => environment.API_START_URL + `api/user-2fa-qr-code/${uHash}`;
const ACTIVATE_2FA_WITH_SECRET = (uHash: string) => environment.API_START_URL + `api/activate-2fa-with-secret/${uHash}`;

@Injectable()
export class TwoFactorAuthenticationServise {

    constructor(
        private router: Router,
        private httpClient: HttpClient
    ) { }

    public updateTwoFactorAuthentication(uHash: string, twoFactorAuthEnabled: boolean, accessToken: string): Observable<TwoFactorAuthentication> {
        return this.httpClient.post<TwoFactorAuthentication>(
            (twoFactorAuthEnabled ? ACTIVATE_2FA(uHash) : DEACTIVATE_2FA(uHash)),
            { "two_factor_auth_enabled": twoFactorAuthEnabled },
            { headers: this.getHeaders(accessToken) }
        );
    }

    public enableUserTwoFactorWithSecret(uHash: string, accessToken: string): Observable<TwoFactorAuthentication> {
        return this.httpClient.post<TwoFactorAuthentication>(
            ACTIVATE_2FA_WITH_SECRET(uHash),
            { "two_factor_auth_enabled": true },
            { headers: this.getHeaders(accessToken) }
        );
    }

    public verifyCode(uHash: string, secret: string, accessToken: string): Observable<TwoFactorAuthentication> {
        return this.httpClient.post<TwoFactorAuthentication>(
            VERIFY_2FA_Code(uHash),
            { "secret": secret },
            { headers: this.getHeaders(accessToken) }
        );
    }

    public validateToken(uHash: string, token: string, accessToken: string): Observable<TwoFactorAuthentication> {
        return this.httpClient.post<TwoFactorAuthentication>(
            VALIDATE_TOKEN(uHash),
            { "token": token },
            { headers: this.getHeaders(accessToken) }
        );
    }

    public getUserQrCode(uHash: string, accessToken: string): Observable<UserTwoFactorQR> {
        return this.httpClient.get<UserTwoFactorQR>(
            USER_2FA_QR_CODE(uHash),
            { headers: this.getHeaders(accessToken) }
        );
    }

    public fetchOtpQrCodes(): Observable<OtpAppQrCodes> {
        return this.httpClient.get<OtpAppQrCodes>(OTP_QR_CODES());
    }

    public handleError(lang: string, errorMessage?: string, isDiploma?: boolean): void {
        this.router.navigate([lang + '/not-found'], { state: { errorMessage: errorMessage, isDiploma: isDiploma } });
    }

    public setToken(token: string | null): void {
        if (token) {
            localStorage.removeItem('currentUser2FA');
            localStorage.setItem(
                'currentUser2FA',
                JSON.stringify({
                    token: token,
                    scope: 'twoFactor'
                }),
            );
        }
    }

    private getHeaders(accessToken: string): HttpHeaders {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        });
    }
}