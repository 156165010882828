import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-dont-exist',
  templateUrl: './user-dont-exist.component.html',
  styleUrls: ['./user-dont-exist.component.css']
})
export class UserDontExistComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  public navigateToDashboard(){
    this.router.navigate([this.language + '/dashboard']);
  }

  get language() {
    return this.activatedRoute.snapshot.params.lang;
  }

}
