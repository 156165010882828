import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialShareService } from 'app/core/services/social-login/share/social-share.service';
import { DiplomaShareService } from '../../../../core/services/diploma-share/diploma-share.service';
import { socialShareButtons } from '../../../../constants/social-share.constants';
import { ActivatedRoute, Params } from '@angular/router';
import { DiplomaPresentationData } from '../../../../core/models/diploma';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import userAccessToken from 'app/core/helpers/user/access-token';
import { Share } from '../../../../core/models/share';
import { DiplomaService } from '../../../../core/services/diploma/diploma.service';
import { SwalService } from '../../../../core/services/util/swal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  providers: [DiplomaShareService],
  selector: 'app-diploma-share-button-group',
  templateUrl: './diploma-share-button-group.component.html',
  styleUrls: ['./diploma-share-button-group.component.css'],
})
export class DiplomaShareButtonGroupComponent implements OnInit, OnDestroy {
  @Input() public diploma: DiplomaPresentationData;
  @Input() private userLinkedinId: string | null;
  @Input() private linkedinDirectShare: boolean;

  @ViewChild('selectShareMethodModal', { static: false }) public imageSelect: TemplateRef<any>;

  public currentSocial: string;
  public socialShareButtons: Array<object> = socialShareButtons;
  private params: Params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private diplomaService: DiplomaService,
    private socialShareService: SocialShareService,
    private swalService: SwalService,
    private translateService: TranslateService // private flashMessagesUtilService: FlashMessagesUtilService
  ) {}

  ngOnInit() {
    this.socialShareService.socialShareInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public shareData = async (socialType: string): Promise<void> => {
    if (socialType == 'linkedin' && this.linkedinDirectShare && this.userLinkedinId) {
      const result = await this.socialShareService.checkAccessToken(socialType).toPromise();

      if (!result['accessToken']) {
        await this.socialShareService.linkedinAccessToken();
      }

      this.socialShareService
        .directShare(socialType, this.params.lang, this.diploma, this.userLinkedinId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (response) => {
            this.translateService
              .get(response.message, { social: "Linkedin"})
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((value: string) => {
                this.swalService.initSuccessToast(value);
              });
          },
          (error) => {
            this.translateService
              .get(error.error.message)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((value: string) => {
                this.swalService.initFailToast(value);
              });

            this.socialShareService.socialShare(socialType, this.params.lang, this.diploma);
          }
        );

      this.trackShareData(socialType);
      return;
    }

    this.socialShareService.socialShare(socialType, this.params.lang, this.diploma);
    this.trackShareData(socialType);
  };

  public openImageSelect($event: string): void {
    this.currentSocial = $event;
    this.openModel(this.imageSelect, 'descriptionModal');
  }

  public handleShare($event: string): void {
    this.shareData($event);
  }

  public openModel = (content, css, data = {}): void => {
    const modalRef = this.modalService.open(content, css ? { windowClass: css } : {});
    modalRef.componentInstance.data = data;
  };

  /**
   * Track add to profile click
   *
   * @param media
   */
  private trackShareData(media: string): void {
    const accessToken = userAccessToken();

    const share = new Share(this.diploma.diploma_hash, media);
    this.diplomaService
      .shareData(share, accessToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => result,
        (error) => error
      );
  }
}
