import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-diplomaerror',
  styleUrls: ['./diplomaerror.component.css'],
  templateUrl: './diplomaerror.component.html'
})
export class DiplomaErrorComponent {

  constructor(
    private translateService: TranslateService,
    private meta: Meta
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }
}
