<app-header></app-header>
<ng-container>
  <app-design-view-header [smallResolution]='true' [userMenu]='false'></app-design-view-header>
</ng-container>
<div class='container'>
  <div class='row flex-lg-row'>
    <div class='pl-0 pr-0 middle-col'>
      <div class='wrong-name-request mx-auto'>
        <div class='info-header'></div>
        <div class='request-wrap-content shadow form'>
          <h1 class='margin-bottom-30'>{{ "Request Name Change" | translate }}</h1>
          <div class='content' *ngIf='processTimeInDays'>
            <ng-container *ngIf='processTimeInDays === 1; else moreThanOne'>
              {{
              "You requested a change in the registered name. Your request is sent to the issuer. You can expect your request to be processed within /expected_process_time/ day"
                | translate: { expected_process_time: this.processTimeInDays }
              }}
            </ng-container>
            <ng-template #moreThanOne>
              {{
              "You requested a change in the registered name. Your request is sent to the issuer. You can expect your request to be processed within /expected_process_time/ days"
                | translate: { expected_process_time: this.processTimeInDays }
              }}
            </ng-template>
            <div class='continue-container'>
              <button (click)='continue()'class='continue-btn'>{{'Continue' | translate}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
