import {Component, Input, OnInit} from '@angular/core';
import {DiplomaService} from "../../../core/services/diploma/diploma.service";

@Component({
  selector: 'app-diploma-add-to-profile',
  templateUrl: './diploma-add-to-profile.component.html',
  styleUrls: ['./diploma-add-to-profile.component.css']
})
export class DiplomaAddToProfileComponent implements OnInit {

  @Input() public data;
  @Input() public dismiss;
  @Input() public cancel;
  @Input() public successful;
  @Input() public unsuccessful;
  @Input() public language;
  @Input() public currentURL;

  constructor(public diplomaService: DiplomaService) { }

  ngOnInit() {
  }

}
