import { Observable } from 'rxjs';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PathwayService } from '../../../core/services/pathway/pathway.service';

// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

@Component({
  providers: [PathwayService],
  selector: 'app-pathway-widget-base',
  templateUrl: './pathway-widget-base.component.html',
  styleUrls: ['./pathway-widget-base.component.css']

})
export class PathwayWidgetBaseComponent implements OnInit, OnChanges {
  @Input() private diplomaHash: string;

  public data: Observable<any>;

  constructor(private pathwayService: PathwayService) {
  }

  ngOnInit(): void {
    this.fetchPathwaysData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diplomaHash.previousValue && changes.diplomaHash.previousValue != changes.diplomaHash.currentValue) {
      this.fetchPathwaysData();
    }
  }


  private fetchPathwaysData() {
    this.data = this.pathwayService.fetchDiplomaPathwaysForWidget(this.diplomaHash);

  }

}
