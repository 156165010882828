import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DiplomaService } from 'app/core/services/diploma/diploma.service';
import { OrganizationService } from 'app/core/services/organization/organization.service';
import { UserService } from 'app/core/services/user/user.service';
import { HttpUtilService } from 'app/core/services/util/http-util.service';
import { LanguageUtilService } from 'app/core/services/util/language-util.service';
import { UrlUtilService } from 'app/core/services/util/url-util.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiplomaAccessLevel, DiplomaDesign } from '../../../core/models/diploma';
import { ShareViewService } from '../../../core/services/share-view/share-view.service';
import {
  TwoFactorAuthenticationBaseComponent
} from '../../access-level/two-factor-authentication/two-factor-authentication-base/two-factor-authentication-base.component';
import {
  ShareViewPasswordPromptComponent
} from '../../share-view/share-view-password-prompt/share-view-password-prompt.component';
import { CustomButtonGroupComponent } from 'app/modules/diploma/components/diploma-view-page/custom-button-group/custom-button-group.component';

@Component({
  providers: [NgbCarouselConfig],
  selector: 'app-diploma-design-view',
  templateUrl: './diploma-design-view.component.html',
  styleUrls: ['./diploma-design-view.component.css']
})
export class DiplomaDesignViewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  customButtonGroupComponent: CustomButtonGroupComponent;

  @Input() public withAccessLevel: boolean;
  @Input() public withGoalSelection: boolean;
  @Input() public diplomaAccessLevel: DiplomaAccessLevel;
  @Input() public openModel: any;
  @Input() public userHash: any;
  @Input() public userDetails: any;
  @Input() public trackLink: any;
  @Input() public shareData: any;
  @Input() public addToSocialProfile: any;
  @Input() public successful: any;
  @Input() public unsuccessful: any;
  @Input() public special: any;
  @Input() public diplomaDesign: DiplomaDesign;

  @ViewChild('passwordModal', { static: false }) public modal: ShareViewPasswordPromptComponent;
  @ViewChild('selectShareMethodModal', { static: false }) public imageSelect: TemplateRef<any>;
  @ViewChild('accessLevelPrompt', { static: false }) public accessLevelPrompt: TwoFactorAuthenticationBaseComponent;

  public data: Observable<any>;
  public value: any;
  public identifierValue: any;
  public specialFields: Observable<string[]>;
  public specialFieldsResult: string[];
  public seeMore: boolean | null = null;
  public social;
  private firstTime: boolean = true;
  public shareViewLoading: Observable<boolean>;
  public diplomaLoading: Observable<boolean>;
  private identifierData = true;
  private errorMessage: string;
  private params: any = this.activatedRoute.snapshot.params;

  constructor(
    private diplomaService: DiplomaService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private shareViewService: ShareViewService,
    private activatedRoute: ActivatedRoute,
    private httpUtilService: HttpUtilService,
    private router: Router,
    private languageUtilService: LanguageUtilService,
    private urlUtilService: UrlUtilService,
    config: NgbCarouselConfig
  ) {
    this.data = combineLatest([
      this.organizationService.getOrganizationObservable(),
      this.userService.getDiplomaRecipientObservable(),
      this.diplomaService.getDesignViewObservable()
    ]).pipe(
      map(([organization, recipient, diploma]) => {
        return {
          organization,
          recipient,
          diploma
        };
      })
    );

    this.specialFields = this.diplomaService.getSpecialFields();
    this.shareViewLoading = this.diplomaService.getShareViewLoading();
    this.diplomaLoading = this.diplomaService.getDiplomaLoadingObservable();
    config.interval = 0;
  }

  public ngOnInit() {
    this.languageUtilService.updateLanguage(this.params.lang, false);
  }

  ngAfterViewInit() {
    this.checkRequestType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.firstTime && !this.withAccessLevel && !this.withGoalSelection && !this.special) {
      this.firstTime = false;
      this.getData();
    }
  }


  public getData() {
    const session = localStorage.getItem('browserSession');
    const adminHash = this.params?.view == 'adminView' ? this.params?.userHash : '';
    let token = null;
    if (adminHash) {
      token = this.activatedRoute.snapshot.queryParams['token'];
    }

    this.diplomaService.getPreviewType()
      .subscribe((type) => {
        this.diplomaService.getDesignView(this.language, this.params.uh, session, this.userDetails.accessToken, this.special, this.params.public, type, adminHash, token);
      });
    this.specialFields.subscribe((result) => {
      this.specialFieldsResult = result;
    });
  }

  public initData1(data) {
    this.value = data;
    this.identifierData = false;
  }

  public validateIdentifier(uh, nicename, identifierValue) {
    const accessToken = this.userDetails.accessToken;
    this.diplomaService.validateIdentifier(uh, nicename, this.params.lang, accessToken, identifierValue).subscribe(
      (data) => this.initData1(data),
      (error) => (this.errorMessage = error)
    );
  }

  public handlePassword(event: boolean) {
    if (event) {
      this.getData();
    } else {
      this.router.navigate([this.params.lang + '/not-found'], {
        state: { errorMessage: 'Permissions denied to view diploma', isDiploma: true }
      });
    }
  }

  public handleAccessLevel(event: boolean): void {
    if (event) {
      this.withAccessLevel = false;
    }
  }

  public handlePathwaySettingsUpdate(event: boolean): void {
    if (event) {
      this.withGoalSelection = false;
    }
  }

  public checkRequestType() {
    if (this.special) {
      const subscription = this.shareViewService.checkPassword(this.params.uh).subscribe(
        (result) => {
          if (result) {
            this.modal.open(this.params.uh);
          } else {
            this.getData();
          }

          this.shareViewService.addSubscription(subscription);
        },
        () => {
          this.shareViewService.addSubscription(subscription);
          this.shareViewService.handleError(this.params.lang);
        }
      );

      return;
    }

    if (this.withAccessLevel) {
      this.accessLevelPrompt.setUserHash(this.userHash);

      return;
    }

    if (this.withGoalSelection) {
      return;
    }

    // if in ngOnChange value is not set to false load data
    if (this.firstTime) {
      this.getData();
    }
  }

  public ngOnDestroy(): void {
    this.diplomaService.destroySubscription();
  }

  get language() {
    return this.params.lang;
  }

  public currentURL(diplomaCustomDomain: string | null, diplomaHash: string): string {
    return this.urlUtilService.getCurrentUrlForDiploma(diplomaCustomDomain) + `/${this.language}/diploma/${diplomaHash}`;
  }

  public setSeeMore(value) {
    this.seeMore = value;
  }

  public openImageSelect(social) {
    this.social = social;
    this.openModel(this.imageSelect, 'descriptionModal');
  }

  public handleShare(event, diplomaName, diplomaDescriptionPlain, diplomaTags, customDomain: string | null) {
    this.shareData(event, diplomaName, diplomaDescriptionPlain, diplomaTags, customDomain);
  }

  public changeDiplomaData(diplomaHash: string) {
    this.httpUtilService.cancelPendingRequests();
    this.diplomaService.getDiplomaPresentationData(this.language, diplomaHash, this.userDetails.accessToken);
    window.history.pushState({}, 'Title', `/${this.language}/diploma/${diplomaHash}`);
  }

}
