import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ResetPassword } from '../../core/models/reset-password';
import { ResetPasswordService } from '../../core/services/password/reset-password.service';

import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

import { FlashMessagesService } from 'angular2-flash-messages';

import { FormValidationService } from '../../core/services/common-service/fromvalidation.service';

@Component({
  providers: [FormValidationService, ResetPasswordService],
  selector: 'app-forgot-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public passwordFormControl = this.formValidationService.passwordValidation();
  public confirmPasswordFormControl = this.formValidationService.passwordValidation();

  public res: any;
  public data: any;
  public params: any = this.activatedRoute.snapshot.params;
  public resetPassword = new ResetPassword(this.params.token, this.params.email, '', '');

  private error: any;
  private langVal: any;

  constructor(
    private formValidationService: FormValidationService,
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private flashMessagesService: FlashMessagesService
  ) {
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    if (localStorage.getItem('currentUser')) {
      this.router.navigate([this.langVal + '/dashboard']);
    }
  }

  public ngOnInit() {
    this.checkResetUrl(this.params.token, this.params.email);
  }

  public checkResetUrl(token, email) {
    this.resetPasswordService.checkResetUrl(token, email).subscribe(
      (res) => (this.res = res),
      (error) => (this.error = error)
    );
  }

  public onResetPassword() {
    this.resetPasswordService.onResetPassword(this.resetPassword, this.params.lang).subscribe(
      (data) => this.initData(data),
      (error) => this.initError(error)
    );
  }

  public initData(data) {
    if (data.error) {
      this.data = data;
      if (data.message.newPassword) {
        this.flashMessagesService.show(data.message.newPassword, { cssClass: 'alert-danger', timeout: 5000 });
      } else if (data.message.confirmPassword) {
        this.flashMessagesService.show(data.message.confirmPassword, { cssClass: 'alert-danger', timeout: 5000 });
      }
      this.checkResetUrl(this.params.token, this.params.email);
    } else {
      this.router.navigate(
        [this.params.lang + '/login'],
        { queryParams: { message: 'success_reset_password' } }
      );
    }
  }

  public initError(error) {
    this.error = error;
  }

  get language() {
    return this.params.lang;
  }

  get urlParams() {
    return this.params;
  }
}
