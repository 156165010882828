import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { OtpAppQrCodes } from "app/core/models/qr-codes";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TwoFactorAuthentication } from "../../../../core/models/two-factor-authentication";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TwoFactorAuthenticationServise } from "../../../../core/services/two-factor-authentication/two-factor-authentication.service";

@Component({
    providers: [TwoFactorAuthenticationServise],
    selector: 'app-two-factor-authentication-setup',
    templateUrl: './two-factor-authentication-setup.component.html',
    styleUrls: ['./two-factor-authentication-setup.component.css'],
})
export class TwoFactorAuthenticationSetupComponent implements OnInit, OnDestroy {
    @Input() public templateType: string;
    @Input() public orgainzationImage: string;
    @Input() private userHash: string;

    @Output() public isValid = new EventEmitter<boolean>();

    public qr: SafeResourceUrl;
    public form: FormGroup;
    public isFirstStep: boolean = true;
    public otpAppQrCodes: OtpAppQrCodes;

    private tries: number = 0;
    private params = this.activatedRoute.snapshot.params;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private _sanitizer: DomSanitizer,
        private twoFactorAuthenticationServise: TwoFactorAuthenticationServise
    ) { }

    ngOnInit() {
        this.fetchOtpQrCodes();
        this.form = this.formBuilder.group({
            secret: ['', [Validators.required]],
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public verifyTwoFactorAuthenticationCode(): void {
        const session = localStorage.getItem('browserSession');
        this.twoFactorAuthenticationServise.verifyCode(this.userHash, this.secret.value, session)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.initData(res);
            },
                () => {
                    this.twoFactorAuthenticationServise.handleError(this.params.lang);
                });
    }

    public initData(twoFactorAuth: TwoFactorAuthentication): void {
        if (!twoFactorAuth.data.isValid && this.tries < 3) {
            this.secret.setErrors({ 'incorrect': true });
            localStorage.removeItem('currentUser2FA');
        } else {
            this.twoFactorAuthenticationServise.setToken(twoFactorAuth.data.token);
            this.emitIsValid(twoFactorAuth.data.isValid);
        }
    }

    public getErrorMessage() {
        return this.secret.hasError('required')
            ? 'You must enter a value'
            : 'Invalid code';
    }

    public emitIsValid(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public toSecondStep(): void {
        this.enableTwoFactorAuthentication();
    }

    private enableTwoFactorAuthentication(): void {
        this.twoFactorAuthenticationServise.enableUserTwoFactorWithSecret(this.userHash, this.userDetails.accessToken)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: TwoFactorAuthentication) => {
                this.getUserQrCode(this.userHash, this.userDetails.accessToken);
            },
                () => {
                    this.twoFactorAuthenticationServise.handleError(this.params.lang);
                });
    }

    private getUserQrCode(uHash: string, accessToken: string): void {
        this.twoFactorAuthenticationServise.getUserQrCode(uHash, accessToken)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.qr = this._sanitizer.bypassSecurityTrustResourceUrl(
                    'data:image/jpg;base64,' + res.data.qrCode
                );
                this.isFirstStep = false;
            });
    }

    private fetchOtpQrCodes(): void {
        this.twoFactorAuthenticationServise.fetchOtpQrCodes()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.otpAppQrCodes = res;
            });
    }

    get userDetails() {
        if (localStorage.getItem('currentUser')) {
            const userDetail = JSON.parse(localStorage.getItem('currentUser'));
            if (userDetail.accessToken) {
                return userDetail;
            }
        }
        return false;
    }

    get secret() {
        return this.form.controls.secret;
    }
}
