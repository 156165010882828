import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-diplomasafe',
  styleUrls: ['./about-diplomasafe.component.css'],
  templateUrl: './about-diplomasafe.component.html',
})
export class AboutDiplomasafeComponent implements OnInit {

  constructor() {
    // empty
  }

  public ngOnInit() {
    // empty
  }

}
