import { environment } from '../../../../environments/environment';

export class ShareService {

  constructor() {}

  public shareLink(lang: string, uHash: string, niceName: string | undefined, social: string, customDomain: string | null = null): string {    
    if (customDomain) {
      return `${customDomain}/${lang}/diploma/${uHash}`;
    }

    return `${environment.APP_URL}/${lang}/diploma/${uHash}/${niceName}/${social}`;
  }
}
