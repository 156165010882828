import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { API_URL } from '../../../constants';
import { Observable } from 'rxjs';
import { DiplomaDesign, TransferDiploma } from '../../models/diploma';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiplomaTransferService {

  constructor(private httpClient: HttpClient,
              @Inject(API_URL) private readonly apiUrl: string) {
  }

  public getTransferRequest(
    requestUhash: string,
    lang: string,
    accessToken: string,
    auth: string
  ): Observable<{ status: string, message?: string, data: TransferDiploma, design: DiplomaDesign }> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    });
    let authSegment = '';
    if (auth) {
      authSegment = `/${auth}`;
    }

    return this.httpClient
      .get(`${this.apiUrl}api/get-diploma-transfer-request-change/${lang}/${requestUhash}${authSegment}`, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res));
  }

  public claimDiploma(
    requestUhash: string,
    lang: string,
    accessToken: string,
    register: boolean
  ) {
    let header = {};
    if (accessToken) {
      header = new HttpHeaders({
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      });
    }


    let registerSegment = '';
    let body = {};

    if (register) {
      registerSegment = `/${register}`;
      body = {
        client_id: environment.CLIENT_ID,
        client_secret: environment.CLIENT_SECRET,
        grant_type: environment.GRANT_TYPE_PASSWORD,
      };
    }

    return this.httpClient
      .post(`${this.apiUrl}api/claim-diploma-transfer-request-change/${lang}/${requestUhash}${registerSegment}`, body, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res));

  }

  private extractData(res: HttpResponse<any>): any {
    return res || {};
  }
}
