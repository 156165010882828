import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DiplomaService } from '../../core/services/diploma/diploma.service';
import { PathwayService } from '../../core/services/pathway/pathway.service';
import { Status } from '../../core/models/status';
import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  DiplomaExpireDeleteWarningComponent
} from './diploma-expire-delete-warning-modal/diploma-expire-delete-warning-modal.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Meta } from '@angular/platform-browser';

@Component({
  providers: [DiplomaService, PathwayService],
  selector: 'app-diploma-status',
  styleUrls: ['./diplomastatus.component.css'],
  templateUrl: './diplomastatus.component.html'
})
export class DiplomaStatusComponent implements OnInit {
  public data: any;
  public res: any;
  public status = new Status('', '');
  public autoDeleteExpired: any;
  public hasExpiryDate: boolean;
  private errorMessage: string;
  private statusStartVal: Status;
  private autoDeleteExpiredStartVal: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;
  private public: any;
  private modalRef: NgbModalRef;
  private successMessage: string = '';

  constructor(
    private modalService: NgbModal,
    private diplomaService: DiplomaService,
    private pathwayService: PathwayService,
    private metaService: Meta,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private flashMessagesService: FlashMessagesService
  ) {
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate([this.langVal + '/login']);
    }

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state ? navigation.extras.state as { data: string } : { data: '' };

    if (state.data) {
      this.successMessage = state.data;
    }
  }

  public ngOnInit() {

    const accessToken = this.userDetails.accessToken;
    this.getDiplomaStatus(this.params.lang, this.params.uh, accessToken);

  }

  public getDiplomaStatus(lang, uh, accessToken) {
    this.diplomaService.getDiplomaStatus(lang, uh, accessToken).subscribe(
      (data) => this.initData(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initData(data) {
    if (data.incorrect) {
      this.router.navigate([this.params.lang + '/diploma-error']);
    } else if (!data.same_user && data.diploma_status === 5) {
      this.router.navigate([this.params.lang + '/diploma-error']);
    } else {
      this.data = data;
      if (data.diploma_status === 1) {
        this.public = true;
      } else {
        this.public = false;
        this.metaService.addTag({ name: 'robots', content: 'noindex' });
      }
      this.status = new Status(this.params.uh, this.public);
      this.statusStartVal = this.public;
      this.autoDeleteExpired = data.diploma_is_retained;
      this.autoDeleteExpiredStartVal = data.diploma_is_retained;
      this.hasExpiryDate = data.diploma_has_expiry_date;
    }


    if (this.successMessage) {
      // Without timeout message is not displayed
      setTimeout(() => {
        this.flashMessagesService.show(this.successMessage, {
          cssClass: 'alert-success',
          timeout: 5000
        });
      }, 0);

    }
  }

  public deleteDiploma() {
    if (confirm('Are you sure you want to delete diploma?')) {
      const accessToken = this.userDetails.accessToken;
      this.diplomaService.deleteDiploma(accessToken, this.params.uh);
    }
  }

  public initData2(result) {
    if (!result.error) {
      this.router.navigate([this.params.lang + '/dashboard']);
    }
  }

  public reset() {
    this.router.navigateByUrl('/' + this.params.lang + '/diploma/' + this.params.uh + '/' + this.params.nicename);
  }

  public updateStatus() {
    const accessToken = this.userDetails.accessToken;

    if (this.status.status != this.statusStartVal) {
      this.diplomaService.updateDiplomaStatus(this.status, accessToken).subscribe(
        (res) => this.initRes(res),
        (error) => (this.errorMessage = error)
      );
    }
    if (this.autoDeleteExpired != this.autoDeleteExpiredStartVal) {
      this.updateDiplomaAutoDeleteExpiredStatus(accessToken);
    }
  }

  public updateDiplomaAutoDeleteExpiredStatus(accessToken) {
    const body = {
      uhash: this.status.uHash,
      retain: this.autoDeleteExpired
    };

    this.diplomaService.updateDiplomaAutoDeleteExpiredStatus(body, accessToken).subscribe(
      (res) => {
        this.initRes(res);
      },
      (error) => {
        this.errorMessage = error;
      }
    );
  }

  public autoDeleteExpiredChange(event: boolean) {
    if (!event) {
      this.modalRef = this.modalService.open(DiplomaExpireDeleteWarningComponent, { size: 'lg', centered: true });
      this.modalRef.componentInstance.changeAutoDeleteExpiredModal.subscribe(($event: boolean) => {
        this.cancelAutoDeleteExpiredChange($event);
      });
      this.modalRef.componentInstance.data = this.data;

      this.modalRef.result.then(
        () => {
        },
        () => {
          this.cancelAutoDeleteExpiredChange(true);
        }
      );
    }
  }

  public cancelAutoDeleteExpiredChange(event: boolean) {
    this.autoDeleteExpired = event;
    this.modalRef.close();
  }

  public initRes(result) {
    if (!result.error) {
      const lang = this.params.lang;
      const uh = this.params.uh;
      const nicename = this.params.nicename;
      this.router.navigate([lang + '/diploma/' + uh + '/' + nicename]);
    }
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get language() {
    return this.params.lang;
  }

  get diagnostic() {
    return JSON.stringify(this.status);
  }


  public editPathways(): void {
    this.pathwayService.redirectToPathwayPage(this.params.uh);
  }
}
