import { ActivatedRouteSnapshot, Data, Resolve } from '@angular/router';
import { SeoAndMetaTagsInterface } from '../../core/types/interfaces/seo/seo-and-meta-tags.interface';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class DefaultMetaDataResolver implements Resolve<SeoAndMetaTagsInterface> {

  constructor(private translateService: TranslateService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<SeoAndMetaTagsInterface> {
    return this.prepareSeoData(route.data);
  }

  /**
   *  Prepare default seo data based on the route data
   *
   * @param data
   * @private
   */
  private prepareSeoData(data: Data): Observable<SeoAndMetaTagsInterface> {
    const preparedData: SeoAndMetaTagsInterface = {
      title: environment.SEO_TITLE ,
      description: environment.SEO_DESCRIPTION,
      image: window.location.origin + '/assets/img/logo_with_text_large.png'
    };

    if (data.titlePrefix) {
      return this.buildWithTitlePrefix(preparedData, data.titlePrefix);
    }

    return of(preparedData);
  }

  /**
   * Prepare metadata with prefixed title
   *
   * @param prefix
   * @private
   */
  private buildWithTitlePrefix(preparedData: SeoAndMetaTagsInterface, prefix: string): Observable<SeoAndMetaTagsInterface> {

    return this.translateService.get(prefix).pipe(
      switchMap((prefixTranslation) => {
        return of(
          {
            ...preparedData,
            ['title']: `${prefixTranslation} - ${environment.SEO_TITLE}`
          } as SeoAndMetaTagsInterface
        );
      })
    );

  }

}
