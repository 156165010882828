<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-lg-9 col-md-8">
      <div class="white-box margin-top-inverse-85 form" >
        <div *ngIf="!status; else successMsg">
          <h1 class="margin-bottom-30">
            <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
            {{ 'Need Help?' | translate }}
          </h1>
          <p>
            {{ 'If you experience problems using the Diplomаsafe services, please give us a shout and we will get back to you as soon as possible.' | translate }}
          </p>
            <app-frequent-questions></app-frequent-questions>
          <p>
            {{ 'You can send us an email on' | translate }}
            <a href="mailto:support@diplomasafe.com" class="green-color text-semibold">support@diplomasafe.com</a>
            {{ 'or send a message below' | translate }}:
          </p>
          <flash-messages></flash-messages>
          <form (ngSubmit)="requestHelp()" autocomplete="off">
            <div class="row">
              <mat-form-field class="col-sm-6">
                <input matInput type="text" name="name" placeholder="{{ 'Your Name' | translate }}" [(ngModel)]="help.name" [formControl]="nameFormControl">
                <mat-error *ngIf="nameFormControl.hasError('required')">
                  {{ 'Name is required' | translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-sm-6">
                <input matInput type="email" name="email" placeholder="{{ 'Your Email' | translate }}" [(ngModel)]="help.email" [formControl]="emailFormControl">
                <mat-error *ngIf="emailFormControl.hasError('pattern')">
                  {{ 'Please enter a valid email address' | translate }}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  {{ 'Email is required' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-sm-12">
                <input matInput type="text" name="subject" placeholder="{{ 'What is your inquiry about?' | translate }}" [(ngModel)]="help.subject" [formControl]="subjectFormControl">
                <mat-error *ngIf="subjectFormControl.hasError('required')">
                  {{ 'Subject is required' | translate}}
                </mat-error>
              </mat-form-field>            </div>
            <div class="form-group margin-top-20">
              <label for="message">
                {{ 'Please try to describe the issue/problem as thorough as possible.' | translate }}
              </label>
              <textarea id="message" name="message" class="form-control" rows="5" [(ngModel)]="help.message" required></textarea>
            </div>
            <div class="button-action">
              <button mat-raised-button type="submit" class="submit">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                {{ 'Send Message' | translate }}
              </button>
            </div>
          </form>
        </div>
        <ng-template #successMsg>
          <h1 class="margin-bottom-30">
            <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
            {{ "Help is on it's way" | translate }}
          </h1>
          <p>
            {{ 'Thank you for your message, we will get back to you as soon as possible.' | translate }}<br>
            <a routerLink="/{{ language }}/dashboard" *ngIf="userDetails else publicView1" class="text-semibold green-color">
              {{ 'Go back to your overview' | translate }}
            </a>
            <ng-template #publicView1>
              <a routerLink="/{{ language }}/login" class="text-semibold green-color">
                {{ 'Go to login page' | translate }}
              </a>
            </ng-template>
          </p>
        </ng-template>
      </div>
    </div>
    <aside class="col-lg-3 col-md-4">
      <div class="box white-box text-center text-semibold green-color">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <a routerLink="/{{ language }}/dashboard" *ngIf="userDetails else publicView">
          {{ 'Back to overview' | translate }}
        </a>
        <ng-template #publicView>
          <a routerLink="/{{ language }}/login">
            {{ 'Go back' | translate }}
          </a>
        </ng-template>
      </div>
    </aside>
  </div>
</main>
<app-footer></app-footer>
