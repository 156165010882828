import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Subject, Subscription } from "rxjs";
import { ShareView } from "../../models/ShareView";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ShareViewService {
  private CREATE_VIEW_URL = environment.API_START_URL + "api/share-view/create";
  private ALL_VIEWS_URL = environment.API_START_URL + "api/share-view/all";
  private DELETE_VIEW_URL = environment.API_START_URL + "api/share-view/delete";
  private CHECK_FOR_PASSWORD = environment.API_START_URL + "api/share-view-pwc/";
  private UNBLOCK_VIEW = environment.API_START_URL + "api/share-view/unblock";
  private FIELDS_DATA = environment.API_START_URL + "api/share-view/fields-data/";
  private GET_LANGUAGES = environment.API_START_URL + "api/share-view-languages";
  private CHECK_LOADING_STATUS = environment.API_START_URL + "api/share-view/check-loading-status";

  public subscriptions: Subscription[] = [];
  public shareViewSubject: Subject<ShareView[]> = new Subject<ShareView[]>();
  public shareViewLanguages: Subject<object[]> = new Subject<object[]>();
  public successful: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.translateService.get("Copying to clipboard was successful").subscribe((value) => {
      this.successful = value;
    });
  }

  public createView(formData) {
    return this.http
      .post<{ error: boolean; message: string; url: string, id: number }>(this.CREATE_VIEW_URL, formData, {
        headers: {
          authorization: "Bearer " + this.userDetails.accessToken,
        },
      })
      .toPromise();
  }

  public getShareViews(lang: string) {
    const subscription = this.http
      .get<ShareView[]>(this.ALL_VIEWS_URL, {
        headers: {
          authorization: "Bearer " + this.userDetails.accessToken,
        },
      })
      .subscribe(
        (result) => {
          this.shareViewSubject.next(result);
          this.subscriptions.push(subscription);
        },
        () => {
          this.handleError(lang, "Can't get share view", true);
        }
      );
  }

  public deleteView(uhash, lang) {
    const subscription = this.http
      .post(
        this.DELETE_VIEW_URL,
        { view: uhash },
        {
          headers: {
            authorization: "Bearer " + this.userDetails.accessToken,
          },
        }
      )
      .subscribe(
        () => {
          this.getShareViews(lang);
          this.subscriptions.push(subscription);
        },
        () => {
          this.handleError(lang, "Can't delete share view", true);
        }
      );
  }

  public getFieldsData(uhash: string, lang: number) {
    return this.http
      .get(this.FIELDS_DATA + `${uhash}/${lang}`, {
        headers: {
          authorization: "Bearer " + this.userDetails.accessToken,
        },
      })
      .toPromise();
  }

  public sendPassword(password: string, uh: string, session) {
    return this.http.post<{ result: boolean; count: number }>(
      this.CHECK_FOR_PASSWORD + uh + "/" + session,
      {
        password,
      },
      {
        headers: {
          authorization: "Bearer " + this.userDetails.accessToken,
        },
      }
    );
  }

  public checkPassword(uHash: string) {
    return this.http.get<boolean>(this.CHECK_FOR_PASSWORD + uHash, {
      headers: {
        authorization: "Bearer " + this.userDetails.accessToken,
      },
    });
  }

  public addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  public destroySubscriptions() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public getViews() {
    return this.shareViewSubject.asObservable();
  }

  get languages() {
    return this.shareViewLanguages.asObservable();
  }

  get userDetails() {
    if (localStorage.getItem("currentUser")) {
      const userDetail = JSON.parse(localStorage.getItem("currentUser"));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  public copyToClipBoard(linkResult: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = linkResult;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand("copy");
      alert(this.successful);
    } catch (err) {
      alert("Oops, unable to copy");
    }
    document.body.removeChild(selBox);
  }

  public handleError(lang, errorMessage?: string, isDiploma?: boolean) {
    this.router.navigate([lang + "/not-found"], {
      state: { errorMessage: errorMessage, isDiploma: isDiploma },
    });
  }

  public unblock(uHash: string, lang) {
    const subscription = this.http
      .post(
        this.UNBLOCK_VIEW,
        { view: uHash },
        {
          headers: {
            authorization: "Bearer " + this.userDetails.accessToken,
          },
        }
      )
      .subscribe(
        () => {
          this.getShareViews(lang);
          this.subscriptions.push(subscription);
        },
        () => {
          this.handleError(lang, "Can't unblock share view", true);
        }
      );
  }

  public getLanguages(uHash: string, lang: string) {
    const subscription = this.http
      .post<object[]>(
        this.GET_LANGUAGES,
        { diploma_hash: uHash },
        {
          headers: {
            authorization: "Bearer " + this.userDetails.accessToken,
          },
        }
      )
      .subscribe(
        (data) => {
          this.shareViewLanguages.next(data);
          this.subscriptions.push(subscription);
        },
        () => {
          this.handleError(lang, "Can't get languages for share view", false);
        }
      );
  }

  public checkLoadingStatus(shareViewId: number) {
    return this.http
      .get<{share_view_loaded: boolean}>(this.CHECK_LOADING_STATUS + `/${shareViewId}`, {
        headers: {
          authorization: 'Bearer ' + this.userDetails.accessToken,
        },
      });
  }
}
