import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state('in', style({
      height: '*',
      visibility: 'visible',
      opacity: 1,
      overflow: 'hidden'
    })),
    state('out', style({
      height: '0',
      visibility: 'hidden',
      opacity: 0,
      overflow: 'hidden'
    })),
    transition('in => out', [group([
        animate('500ms ease-in-out', style({
          'opacity': '0'
        })),
        animate('600ms ease-in-out', style({
          'height': '0'

        })),
        animate('700ms ease-in-out', style({
          'visibility': 'hidden'
        }))
      ]
    )]),
    transition('out => in', [group([
        animate('1ms ease-in-out', style({
          'visibility': 'visible'
        })),
        animate('600ms ease-in-out', style({
          'height': '*'
        })),
        animate('800ms ease-in-out', style({
          'opacity': '1'
        }))
      ]
    )])

  ])
];
