<app-header></app-header>
<div class="container">
  <div class="row flex-lg-row">
    <div class="pl-0 pr-0 middle-col">
      <div class="wrong-name-request mx-auto">
        <div class="info-header"></div>
        <div class="request-wrap-content shadow form">
          <h1 class="margin-bottom-30">{{ 'Report Wrong Name' | translate }}</h1>
          <flash-messages></flash-messages>
          <form (submit)="saveRequestChange()">
            <mat-form-field>
              <input
                matInput
                type="text"
                name="current_name"
                placeholder="{{ 'Current name' | translate }}"
                [formControl]="form.get('currentName')"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="text"
                name="correct_name"
                placeholder="{{ 'Correct name' | translate }}"
                [formControl]="form.get('correctName')"
              />
            </mat-form-field>

            <div class="button-action">
              <button mat-raised-button type="submit" class="submit pull-left">
                {{ 'OK' | translate }}
              </button>
              <div class="pull-left">
                <button class="cancelButton" (click)="back()">
                  {{ 'Cancel' | translate }}
                </button>
              </div>
              <div class="reset-div"></div>
            </div>
          </form>
        </div>
      </div>
      <app-design-view-footer class="show-above-1000"></app-design-view-footer>
    </div>
  </div>
</div>
