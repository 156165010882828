<app-header [inheritedLanguage]="this.language"></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 text-center">
        <div class="margin-bottom-30 red-color" id="not-found-title">
          {{
            this.data.errorMessage
              ? 'Sorry - ' + this.data.errorMessage
              : 'Sorry - Nothing to See' | translate
          }}
        </div>
        <img src="../../assets/img/not-found-error.png" alt="">
        <div class="margin-bottom-30" id="not-found-information">
          <div *ngIf="data?.isDiploma; else standardError">
            {{ 'If you were looking for a diploma, it may be' | translate }}
            <ul>
              <li>{{ 'Private and only visible to the owner of the diploma' | translate }}*</li>
              <li>{{ 'Non-existing or deleted' | translate }}</li>
              <li>{{ 'Revoked' | translate }}</li>
            </ul>
            * {{ 'If you are the owner and the diploma is marked private, you can access the diploma if you log in at ' | translate }}
            <a [href]="this.appUrl()">{{ this.appUrl() }}</a>
          </div>
          <ng-template #standardError>
            * {{ 'If you are not log in you can do it at ' }}
            <a [href]="this.appUrl()">{{ this.appUrl() }}</a>
          </ng-template>
        </div>
	      <a class="btn btn-success text-uppercase" routerLink="{{homepageUrl}}">{{ 'Go Back to the Homepage' | translate }}</a>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
