import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../services/maintenance/maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router, private maintenanceService: MaintenanceService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (this.maintenanceService.isInMaintenanceMode()) {
      this.router.navigate(['/maintenance']);

      return false;
    }

    return true;
  }
}
