<section class="container diplomasafe">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10">
      <h3 class="text-center margin-bottom-20">{{ 'About /Diplomasafe/'  | translate:{Diplomasafe: 'Diplomasafe'} }}:</h3>
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-4 text-center">
          <figure class="hat-logo"></figure>
        </div>
        <div class="col-lg-10 col-md-9 col-sm-8">

          <p>
            {{ "/Diplomasafe/ is a platform for secure digital diplomas, issued by schools, universities and other institutions and training facilities working professionally with education. /Diplomasafe/ safeguards and prevent fraud for everyone in the diploma ecosystem - namely the issuers of diplomas, the receivers of diplomas and the verifiers of diplomas." | translate:{Diplomasafe: 'Diplomasafe'} }}
          </p>
          <p>
            {{ '/Diplomasafe/ - The proof of education' | translate: {Diplomasafe: 'Diplomasafe'}  }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
