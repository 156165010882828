<article class='pathway-container'>
  <div class='relative'>
    <h4 class='pathway-name clearfix'>{{ pathway.name }} <span *ngIf='pathway.showPercent'>({{ pathway.achievedPercent || 0 }}
      % {{ 'achieved' | translate }})</span>
      <span (click)='toggleGoalDetails()' class='pathway-goal-details-btn' style='float: right'>
            <i class='fa fa-solid fa-angle-up' *ngIf="animateGoalDetails==='in'"></i>
            <i class='fa fa-solid fa-angle-down' *ngIf="animateGoalDetails==='out'"></i>
          </span>
    </h4>
    <div class='pathway-goal-details'>
      <div [@slideInOut]='animateGoalDetails' class='row'>
        <div class='pathway-goal-details-badge col-lg-3'>
          <img [src]='pathway.goal.badge' [attr.title]='pathway.name'>
        </div>
        <div class='pathway-goal-details-description col-lg-9'>
          <div [innerHTML]='pathway.goal.description'></div>
          <a [attr.href]='pathway.goal.learn_more' *ngIf='pathway.goal.learn_more'
             target='_blank'>{{ 'Learn more' | translate }}...</a>
        </div>
      </div>
    </div>
    <div class='relative'>
      <swiper
        #swiper
        [config]='config'
        (unlock)='onUnlock($event)'
        (lock)='onLock($event)'
        class='mySwiper'
      >
        <ng-template swiperSlide *ngFor="let subGoal of pathway['subGoals']">
          <div class='pathway-badge' (click)='toggleSubGoalDetails(subGoal)'>
            <img [src]='subGoal.badge' [ngClass]="subGoal.achieved ? 'active' : 'inactive'" />

            <div class='pathway-sg-current' *ngIf='subGoal.is_current'></div>
          </div>
        </ng-template>
      </swiper>
      <button mat-mini-fab class='slidePrev' (click)='slidePrev()' *ngIf='displayArrowButtons'>
        <div>
          &#10094;
        </div>
      </button>
      <button mat-mini-fab class='slideNext' (click)='slideNext()' *ngIf='displayArrowButtons'>
        <div>
          &#10095;
        </div>
      </button>
    </div>
    <div [@slideInOut]='animateSubGoalDetails' class='pathway-sg-details'>
      <div class='row' *ngIf='selectedSubGoal'>
        <div class='pathway-sg-badge col-lg-3'>
          <img [src]='selectedSubGoal.badge_full' [attr.title]='selectedSubGoal.name' [attr.alt]='selectedSubGoal.name'>
          <div class='pathway-sg-diploma-link' *ngIf='selectedSubGoal.diploma'>
            <a
              [href]='generateDiplomaUrl(selectedSubGoal.diploma.diploma_uhash, selectedSubGoal.diploma.diploma_nice_name)'>
              {{ 'Click to see /template_type/' | translate: { "template_type": selectedSubGoal.diploma.template_type } }}
            </a>
          </div>
        </div>
        <div class='col-lg-9'>
          <div [innerHTML]='selectedSubGoal.description'></div>
          <a [attr.href]='selectedSubGoal.learn_more' *ngIf='selectedSubGoal.learn_more'
             target='_blank'>{{ 'Learn more' | translate }}...</a>

        </div>
      </div>
    </div>
  </div>
</article>
