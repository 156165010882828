<div class="loading-wrapper row justify-content-md-center">
    <div class="loading-box">
        <h1 class="loading-title">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw spinner"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
        </h1>
        <div *ngIf="this.visibleText">
            <p *ngIf="this.specialShareLoad else genericLoading">
                {{ 'Preparing the View' | translate }}...
            </p>
            <ng-template #genericLoading>
                {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
            </ng-template>
        </div>
    </div>
</div>
