<app-design-view-header-background
  [diplomaDesign]="design"
  *ngIf="this.diplomaDesign$ | async as design; else genericBackground"
>
</app-design-view-header-background>
<ng-template #genericBackground>
  <app-design-view-header-background> </app-design-view-header-background>
</ng-template>
<ng-container>
  <ng-container *ngIf="claimable$ | async as data else null">
  <app-design-view-header [smallResolution]="true" [userMenu]="false" [customSuportLink]="data?.customSupportPageLink"> </app-design-view-header>
</ng-container>
</ng-container>
<div class="container">
  <div class="row flex-lg-row">
    <div class="pl-0 pr-0 middle-col">
      <div class="diploma-information mx-auto">
        <div class="info-header"></div>
        <div class="diploma-presentation shadow" *ngIf="claimable$ | async as diploma; else loadingPart">
          <div class="diploma-online-presentation shadow">
            <flash-messages></flash-messages>
            <div class="diploma-main">
              <div *ngIf="diploma.org_image" class="organization-image">
                <img [src]="diploma.org_image" alt="" />
              </div>
              <div class="diploma-info">
                <div class="title">
                  {{ diploma.receiver }}
                </div>
                <div class="subtitle" *ngIf="!this.isBundle && !diploma.is_bundle; else bundle_subtitle">
                  {{
                    "/organization_name/ has issued a digital /template_type/ to you."
                      | translate: { organization_name: diploma.issuer_name, template_type: diploma.claim_text_type }
                  }}
                </div>
                <ng-template #bundle_subtitle>
                  <div class="subtitle">
                    {{
                      "/organization_name/ has issued a digital /template_type/ to you."
                        | translate: { organization_name: diploma.issuer_name, template_type: diploma.bundle_data.type }
                    }}
                  </div>
                </ng-template>
                <div class="course_title" *ngIf="!this.isBundle && !diploma.is_bundle; else bundle_list">
                  {{ diploma.course_title }}
                </div>
                <ng-template #bundle_list>
                  {{ "Bundle contents:" | translate }}
                  <div class="course_title" *ngFor="let bundleTemplate of diploma.bundle_data.templates">
                    {{ bundleTemplate.label }}
                  </div>
                </ng-template>
                <div *ngIf="diploma.login || (!this.oidc && diploma.login) || (this.loginClaim && this.registerEmail); else claim">
                  <app-claim-login
                    [userEmail]="this.loginClaim ? this.registerEmail : diploma.receiver_email"
                    [lang]="this.params.lang"
                    [socialLogins]="diploma.social_logins"
                    [hasSocialLogin]="diploma.has_social_login"
                    [hasRegisteredSupportEmail]="diploma.has_support_email"
                    [diplomaType]="diploma.claim_button_type"
                    (loginSubmit)="this.reload()"
                  ></app-claim-login>
                </div>
                <ng-template #claim>
                  <div *ngIf="!diploma.registered; else registered">
                    <app-claim-unregistered
                      *ngIf="!diploma.has_claim_email_prompt || this.registerEmail; else registerEmailPrompt"
                      [isBundle]="this.isBundle || diploma.is_bundle"
                      [bundleData]="diploma.bundle_data"
                      [diplomaType]="diploma.claim_button_type"
                      [hasRegisteredSupportEmail]="diploma.has_support_email"
                      [lang]="this.params.lang"
                      (claimRegister)="this.claimDiplomaRegister(diploma.claim_button_type, diploma.has_pending_diploma_change_request)"
                      (claimRegisterBundle)="this.claimBundleRegister(diploma.bundle_data.bundle_hash)"
                    >
                    </app-claim-unregistered>
                    <ng-template #registerEmailPrompt>
                      <app-claim-unregistered-email-prompt
                        [claimFlowEmailPromptDescription]="diploma.claim_flow_email_prompt_description"
                        [isBundle]="this.isBundle || diploma.is_bundle"
                        [bundleData]="diploma.bundle_data"
                        [diplomaType]="diploma.claim_button_type"
                        [hasRegisteredSupportEmail]="diploma.has_support_email"
                        [lang]="this.params.lang"
                        (claimRegisterWithEmail)="claimRegisterWithEmail($event)"
                        >
                      </app-claim-unregistered-email-prompt>
                    </ng-template>
                  </div>
                  <ng-template #registered>
                    <app-claim-registered
                      [isBundle]="this.isBundle || diploma.is_bundle"
                      [bundleData]="diploma.bundle_data"
                      [diplomaType]="diploma.claim_button_type"
                      [sameUser]="diploma.same_user"
                      [receiverMail]="diploma.receiver_email"
                      [userMail]="diploma.user_email"
                      [lang]="this.params.lang"
                      [hasRegisteredSupportEmail]="diploma.has_support_email"
                      (claim)="this.claimDiploma(diploma.claim_button_type, diploma.has_pending_diploma_change_request)"
                      (claimBundle)="this.claimBundle(diploma.bundle_data.bundle_hash)"
                      (logout)="this.logout(this.isBundle || diploma.is_bundle)"
                    >
                    </app-claim-registered>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-design-view-footer class="show-above-1000"></app-design-view-footer>
    </div>
    <div class="header-wrapper col-xl-2 col-lg-3 col-md-12 col-sm-12">
      <ng-container *ngIf="claimable$ | async as data else null">
      <app-design-view-header [userMenu]="false" [helpGrow]="false" [customSuportLink]="data?.customSupportPageLink"></app-design-view-header>
    </ng-container>
    </div>
  </div>
</div>
<app-design-view-footer class="hide-below-1000"></app-design-view-footer>
<ng-template #loadingPart>
  <div class="row justify-content-md-center loader">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 loading-page text-center">
        <h1 class="margin-bottom-30 green-color">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
          <span class="sr-only">{{ "Loading" | translate }}...</span>
        </h1>
        <p>{{ "Loading" | translate }}... {{ "Please Wait" | translate }}...</p>
      </div>
    </div>
  </div>
</ng-template>
