<div class="checkbox-warning">
  <span [hidden]="this.warningHidden">{{
    'You must accept the terms & conditions, privacy statement and profile creation in order to proceed' | translate
  }}</span>
</div>
<div class="terms flex-row">
  <div class="flex-column terms-checkbox&quot;">
    <input type="checkbox" [(ngModel)]="this.termsAccepted" (change)="this.warningHidden = true" />
  </div>
  <div class="flex-column">
    <div class="col-10">
      {{ "I accept Diplomasafe's " | translate }}
      <a href="https://diplomasafe.com/eula" class="link text-semibold" target="_blank" rel="noopener noreferrer">
        {{ 'terms and conditions,' | translate }}
      </a>
      <a href="https://diplomasafe.com/privacy" class="link text-semibold" target="_blank" rel="noopener noreferrer">
        {{ 'privacy statement' | translate }}
      </a>
      {{ 'and the creation of a profile using my name and e-mail' | translate }}
    </div>
  </div>
</div>
<div>
  <div *ngIf="!this.isBundle; else bundleClaimButton">
    <div class="claim-button-wrap">
      <button
        type="button"
        class="btn claim-button"
        ngClass="{{ this.termsAccepted ? 'btn-primary' : 'btn-outline-primary disabled-button' }}"
        (click)="this.handleAccept()"
      >
        {{ 'Claim /type/' | translate : { type: this.diplomaType } }}
      </button>
    </div>

    <app-wrong-name-request-button
      [lang]="lang"
      [templateType]="this.diplomaType"
      *ngIf="hasRegisteredSupportEmail"
    ></app-wrong-name-request-button>
  </div>
  <ng-template #bundleClaimButton>
    <button
      type="button"
      class="btn claim-button"
      ngClass="{{ this.termsAccepted ? 'btn-primary' : 'btn-outline-primary disabled-button' }}"
      (click)="this.handleAccept()"
    >
      {{ 'Claim /type/' | translate : { type: this.bundleData.type } }}
    </button>
  </ng-template>
</div>
