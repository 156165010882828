import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { allLanguages } from '../../../constants/language-constants';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageUtilService {
  constructor(
    private cookieService: CookieService,
    private translateService: TranslateService
  ) {}

  public getSortedLanguages() {
    allLanguages.sort((a, b) => a.native.localeCompare(b.native));

    return allLanguages;
  }

  public setLangCookie(langVal: string): void {

    this.cookieService.set('langVal', langVal, {
      expires: 400, //max expiration is 400 days
      path: '/',
    });
  }

  public updateLanguage(langVal: string, reload: boolean = true): void {
    this.translateService
      .use(langVal)
      .pipe(take(1))
      .subscribe(() => {
        if (langVal === this.cookieService.get('langVal')) {
          return;
        }

        this.setLangCookie(langVal);

        if (reload) {
          const currentUrl = window.location.pathname;
          const newUrl = currentUrl.replace(/^\/[^\/]+/, `/${langVal}`);

          // Update the URL and reload the page if necessary
          window.location.href = newUrl;
        }
      });
  }
}
