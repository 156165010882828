import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TwoFactorAuthenticationServise } from '../../../../core/services/two-factor-authentication/two-factor-authentication.service';
import { TwoFactorAuthenticationPromptComponent } from '../two-factor-authentication-prompt/two-factor-authentication-prompt.component';

@Component({
  providers: [TwoFactorAuthenticationServise],
  selector: 'app-two-factor-authentication-base',
  templateUrl: './two-factor-authentication-base.component.html',
  styleUrls: ['./two-factor-authentication-base.component.css'],
})
export class TwoFactorAuthenticationBaseComponent implements OnInit, OnDestroy {
  @Input() public enabled2FA: boolean;
  @Input() public has2faCode: boolean;
  @Input() public templateType: string;
  @Input() public isEditProfile: boolean;
  @Input() public orgainzationImage: string;
  @Input() private sameUser: boolean;

  @Output() public isValid = new EventEmitter<boolean>();

  @ViewChild('setupModal', { static: false }) setupModal: any;
  @ViewChild('twoFactorPrompt', { static: false }) twoFactorPrompt: TwoFactorAuthenticationPromptComponent;

  public userHash: string;
  public isFirstStep: boolean = true;

  private modalRef: MatDialogRef<any>;
  private params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private modal: MatDialog,
    private activatedRoute: ActivatedRoute,
    private twoFactorAuthenticationServise: TwoFactorAuthenticationServise
  ) {}

  ngOnInit() {
    if (!this.sameUser) {
      this.twoFactorAuthenticationServise.handleError(this.params.lang, 'Permissions denied to view diploma', true);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public changeTwoFactorAuthentication($event: boolean): void {
    this.isValid.emit($event);
  }

  public openVerificationModal(uHash: string, event: boolean) {
    this.twoFactorPrompt.openCodeVerification(uHash);
  }

  public openSetupModal(uHash: string) {
    this.userHash = uHash;

    this.modalRef = this.modal.open(this.setupModal, {
      restoreFocus: false,
      disableClose: true,
      hasBackdrop: true,
      width: '500px',
    });
  }

  public emitIsValid(isValid: boolean, closeModal: boolean | null) {
    this.isValid.emit(isValid);

    if (closeModal) {
      this.modalRef.close();
    }
  }

  public setUserHash(uHash: string) {
    this.userHash = uHash;
  }
}
