import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organization } from "app/core/models/Organization";
import { environment } from "environments/environment";
import { Observable, Subject, Subscription } from "rxjs";

const orgInfoUrl = (diplomaHash: string, langCode: string) =>
  `${environment.API_START_URL}api/org-claim-info/${diplomaHash}/${langCode}`;

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  private organization: Subject<Organization>;
  private subscriptions: Subscription[];

  constructor(private http: HttpClient) {
    this.organization = new Subject<Organization>();
    this.subscriptions = [];
  }

  public getDiplomaOrganizationData(diplomaHash: string, langCode: string) {
    const subscription$ = this.http
      .get<Organization>(orgInfoUrl(diplomaHash, langCode))
      .subscribe((data) => {
        this.subscriptions.push(subscription$);
        this.organization.next(data);
      });
  }

  public setOrganization(organization: Organization) {
    this.organization.next(organization);
  }

  public getOrganizationObservable(): Observable<Organization> {
    return this.organization.asObservable();
  }

  public clearSubscriptions() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
