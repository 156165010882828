import { HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs/Observable";

import {
  Claimable,
  ClaimLoginResponse,
  ClaimResponse,
} from "../../../core/models/claim";

import { environment } from "../../../../environments/environment";
import { LoginService } from "app/core/services/login/login.service";
import { HttpClient } from "@angular/common/http";
import { FlashMessagesService } from "angular2-flash-messages";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../user/user.service";

const CLAIM_URL = () => environment.API_START_URL + "api/claim-diploma";
const CLAIM_REGISTER_URL = () =>
  environment.API_START_URL + "api/claim-diploma-register";
const CLAIN_BUNDLE_URL = () => environment.API_START_URL + "api/claim-bundle";
const CLAIM_REGISTER_BUNDLE_URL = () =>
  environment.API_START_URL + "api/claim-bundle-register";

const getDiplomaForClaim = (
  hash: String,
  lang: String,
  id: String,
  session: String
) => {
  return (
    `${environment.API_START_URL}api/get-claim-diploma/${lang}/${hash}/${id}` +
    (session ? `/${session}` : "")
  );
};

const getBundleForClaim = (
  hash: String,
  lang: String,
  id: String,
  session: String
) => {
  return (
    `${environment.API_START_URL}api/get-claim-bundle/${lang}/${hash}/${id}` +
    (session ? `/${session}` : "")
  );
};

@Injectable({
  providedIn: "root",
})
export class ClaimService implements OnDestroy {
  private claimData$: Subject<Claimable>;
  private somethingWentWrongMessage: string;
  private claimDiplomaErrorMessage: string;
  private claimDiplomaDundleErrorMessage: string;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private loginService: LoginService,
    private flashService: FlashMessagesService,
    private translateService: TranslateService
  ) {
    this.claimData$ = new Subject<Claimable>();
    this.translateService.get("Something went wrong !").subscribe((value) => {
      this.somethingWentWrongMessage = value;
    });
    this.translateService.get("Can't claim diploma!").subscribe((value) => {
      this.claimDiplomaErrorMessage = value;
    });
    this.translateService
      .get("Can't claim diploma bundle!")
      .subscribe((value) => {
        this.claimDiplomaDundleErrorMessage = value;
      });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public fetchClaimData(
    hash: String,
    lang: String,
    id: String,
    accessToken: String,
    oidc: boolean,
    isBundle: boolean
  ) {
    const session = localStorage.getItem("browserSession");
    const header = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };

    const userHash = accessToken ? "1" : "";
    let url = isBundle
      ? getBundleForClaim(hash, lang, id, session)
      : getDiplomaForClaim(hash, lang, id, session);
    url = url + (userHash ? `/${userHash}` : "");

    this.httpClient
      .get<Claimable>(url, {
        headers: header,
      })
      .subscribe(
        (res) => {
          if (
            (res.diploma_status && res.diploma_status === 2) ||
            res.claimed_bundle
          ) {
            this.router.navigate([lang, "diploma", res.diploma_hash]);
          } else if (res.openid && oidc && res.registered) {
            document.location.href =
              environment.API_START_URL + "api/openid/" + res.openid;
          } else {
            this.claimData$.next(res);
          }
        },
        (err) => {
          this.router.navigate([lang + "/not-found"], {
            state: { errorMessage: "Can't claim diploma", isDiploma: true },
          });
        }
      );
  }

  public claimDiploma(
    diplomaHash: String,
    auditHash: String,
    from: String,
    lang: String
  ) {
    const token = this.userService.accessToken;
    this.httpClient
      .post<ClaimResponse>(
        CLAIM_URL(),
        {
          diploma_hash: diplomaHash,
          audit_hash: auditHash,
          from: from,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (!res.error && res.diploma_status === 2) {
            window.location.href = `/${lang}/diploma/${res.diploma_hash}`;
          }
        },
        (err) => {
          this.handleError(err, this.claimDiplomaErrorMessage);
        }
      );
  }

  public claimRegster(
    issueHash: string,
    auditHash: String,
    from: String,
    lang: String,
    registerEmail: String | null
  ) {
    this.httpClient
      .post<ClaimLoginResponse>(CLAIM_REGISTER_URL(), {
        diploma_hash: issueHash,
        audit_hash: auditHash,
        client_id: environment.CLIENT_ID,
        client_secret: environment.CLIENT_SECRET,
        grant_type: environment.GRANT_TYPE_PASSWORD,
        from: from,
        register_email: registerEmail,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (!res.error) {
            this.setTokens(res);
            this.router.navigate([lang, "diploma", res.hash]);
          }
        },
        (err) => this.handleError(err)
      );
  }

  private setTokens(data: ClaimLoginResponse) {
    localStorage.removeItem("currentUser");
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        accessToken: data.access_token,
        expiresIn: data.expires_in,
        refreshToken: data.refresh_token,
        tokenType: data.token_type,
      })
    );

    localStorage.removeItem("userHash");
    localStorage.setItem("userHash", data.user_hash);
  }

  public claimBundleRegster(
    issueHash: string,
    auditHash: String,
    from: String,
    lang: String,
    registerEmail: String | null
  ) {
    this.httpClient
      .post<ClaimLoginResponse>(CLAIM_REGISTER_BUNDLE_URL(), {
        issued_hash: issueHash,
        audit_hash: auditHash,
        client_id: environment.CLIENT_ID,
        client_secret: environment.CLIENT_SECRET,
        grant_type: environment.GRANT_TYPE_PASSWORD,
        from: from,
        register_email: registerEmail
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (!res.error) {
            this.setTokens(res);
            this.router.navigate([lang, "diploma", res.hash]);
          }
        },
        (err) => this.handleError(err)
      );
  }

  public claimBundle(
    issuedHash: String,
    auditHash: String,
    from: String,
    lang: String
  ) {
    const token = this.userService.accessToken;
    this.httpClient
      .post<ClaimResponse>(
        CLAIN_BUNDLE_URL(),
        {
          issued_hash: issuedHash,
          audit_hash: auditHash,
          from: from,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (!res.error) {
            this.router.navigate([lang, "diploma", res.diploma_hash]);
          }
        },
        (err) => {
          this.handleError(err, this.claimDiplomaDundleErrorMessage);
        }
      );
  }

  get claimData(): Observable<Claimable> {
    return this.claimData$.asObservable();
  }

  private handleError(error: HttpResponse<any> | any, errorMessage?: string) {
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
    }
    this.flashService.show(errorMessage || this.somethingWentWrongMessage, {
      cssClass: "alert-danger",
    });
  }
}
