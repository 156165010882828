import { Injectable } from "@angular/core";
import { HttpRequest, HttpHeaders } from "@angular/common/http";

@Injectable()
export class DefaultRequestOptions extends HttpHeaders {
  constructor() {
    // Set the default 'Content-Type' header
    super();

    this.set("Content-Type", "application/json");
  }
}

export const requestOptionsProvider = {
  provide: HttpRequest,
  useClass: DefaultRequestOptions,
};
