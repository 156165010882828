import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FileData } from '../../core/models/dashboard/filedata';
import { Profile } from '../../core/models/dashboard/profile';
import { ProfileService } from '../../core/services/dashboard/profile.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

import { FlashMessagesService } from 'angular2-flash-messages';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { FormValidationService } from '../../core/services/common-service/fromvalidation.service';
import { SocialData } from '../../core/models/dashboard/socialdata';
import {
  TwoFactorAuthenticationBaseComponent
} from '../access-level/two-factor-authentication/two-factor-authentication-base/two-factor-authentication-base.component';

@Component({
  providers: [FormValidationService, ProfileService],
  selector: 'app-edit-profile',
  styleUrls: ['./dashboard.component.css'],
  templateUrl: './editprofile.component.html'
})
export class EditProfileComponent implements OnInit {
  @ViewChild('accessLevel', { static: false })
  public accessLevel: TwoFactorAuthenticationBaseComponent;

  public emailFormControl = this.formValidationService.emailValidation();
  public nameFormControl = this.formValidationService.inputValidation();

  public sub: any;
  public result: any;
  public data: any;
  public countries: any;
  public country: any;
  public fbLinkStatus: boolean;
  public liLinkStatus: boolean;
  public gpLinkStatus: boolean;
  public filedata = new FileData('');
  public profile = new Profile('', '', '', '', '', '', '', '', '', '', '', '', '');
  private socialData = new SocialData('', '', '', '');
  public userHash: string;
  private errorMessage: string;
  private data1: any;
  private error: any;
  private files: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;
  private message: any;
  private confirmMessage: any;
  private provider: any;
  // private modalRef: NgbModalRef;
  public tab: number;

  constructor(
    public authService: SocialAuthService,
    private formValidationService: FormValidationService,
    private router: Router,
    private profileService: ProfileService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private flashMessagesService: FlashMessagesService
  ) {
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate([this.langVal + '/login']);
    }
    this.translateService.get('Are you sure you want to unlink this account?').subscribe((value) => {
      this.confirmMessage = value;
    });
  }

  public ngOnInit() {
    this.userHash = null;
    this.fbLinkStatus = false;
    this.liLinkStatus = false;
    this.gpLinkStatus = false;

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) {
        this.tab = params.tab;
        this.router.navigate([], {
          queryParams: {
            tab: null
          },
          queryParamsHandling: 'merge'
        });
      }
    });

    const accessToken = this.userDetails.accessToken;
    this.getUserDetails(accessToken);
    this.getCountries();
  }

  public getCountries() {
    const accessToken = this.userDetails.accessToken;
    this.profileService.getCountries(accessToken).subscribe(
      (data) => (this.countries = data),
      (error) => (this.errorMessage = error)
    );
  }

  public getCountryById(countryId) {
    const accessToken = this.userDetails.accessToken;
    this.profileService.getCountryById(countryId, accessToken).subscribe(
      (data) => this.initData3(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initData3(data) {
    const countryCode = data.countries.country_code;
    document.getElementById('countryCode').innerHTML = countryCode;
  }

  public fileChange(event) {
    const body: any = this.filedata.oldname;
    const bodyString = body;
    const accessToken = this.userDetails.accessToken;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // a file was selected
      const file: File = fileList[0];
      if (file.name.length < 1) {
        alert('invalid file name');
      } else if (
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/gif' &&
        file.type !== 'image/jpeg'
      ) {
        alert('The file does not match png, jpg or gif');
      } else if (file.size > 1000000) {
        alert('The file is too big');
      } else {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('bodyString', bodyString);
        this.profileService.updateProfilePic(formData, accessToken).subscribe(
          (data) => this.initData2(data),
          (error) => (this.errorMessage = error)
        );
      }
    }
  }

  public removeImage() {
    const body: any = this.filedata.oldname;
    const bodyString = body;
    const accessToken = this.userDetails.accessToken;
    const formData = new FormData();
    formData.append('file', '');
    formData.append('bodyString', bodyString);
    this.profileService.updateProfilePic(formData, accessToken).subscribe(
      (data) => this.initData2(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initData2(data) {
    const accessToken = this.userDetails.accessToken;
    this.getUserDetails(accessToken);
  }

  public getUserDetails(accessToken) {
    this.profileService.getUserDetails(accessToken).subscribe(
      (data) => this.initData(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initData(data) {
    this.data = data;
    const fbLinkStatusKey = 'fb_link_status';
    const liLinkStatusKey = 'li_link_status';
    const gpLinkStatusKey = 'gp_link_status';
    const nameKey = 'name';
    const emailKey = 'email';
    const phoneKey = 'phone';
    const countryKey = 'country';
    const descriptionKey = 'description';
    const imageKey = 'image';
    const publicKey = 'is_public';
    const facebookKey = 'facebook';
    const linkedinKey = 'linkedin';
    const googleplusKey = 'googleplus';
    const twitterKey = 'twitter';
    // tslint:disable-next-line:variable-name
    const pi_validation = 'pi_validation';
    const enabled_2fa = 'enabled_2fa';
    const has_2fa_code = 'has_2fa_code';

    this.userHash = data['userHash'];
    this.fbLinkStatus = data[fbLinkStatusKey];
    this.liLinkStatus = data[liLinkStatusKey];
    this.gpLinkStatus = data[gpLinkStatusKey];
    this.profile = new Profile(
      data[nameKey],
      data[publicKey],
      data[emailKey],
      data[phoneKey],
      data[countryKey],
      data[descriptionKey],
      data[facebookKey],
      data[linkedinKey],
      data[googleplusKey],
      data[twitterKey],
      data[pi_validation],
      data[enabled_2fa],
      data[has_2fa_code]
    );

    this.filedata = new FileData(data[imageKey]);
  }

  public reset() {
    this.router.navigate([this.params.lang + '/dashboard']);
  }

  public updateProfile() {
    const accessToken = this.userDetails.accessToken;
    this.profileService.updateProfile(this.profile, this.langVal, accessToken).subscribe(
      (result) => this.initData1(result),
      (error) => (this.errorMessage = error)
    );
  }

  public initData1(result) {
    const accessToken = this.userDetails.accessToken;
    if (result.error) {
      this.result = result;
      if (result.message.name) {
        this.flashMessagesService.show(result.message.name, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (result.message.email) {
        this.flashMessagesService.show(result.message.email, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (result.message.errormsg) {
        this.flashMessagesService.show(result.message.errormsg, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
      return true;
    } else {
      this.router.navigate([this.params.lang + '/dashboard']);
    }
  }

  // Social Link
  public linkSocial(provider: string) {
    let providerId: string;
    if (provider === 'facebook') {
      providerId = FacebookLoginProvider.PROVIDER_ID;
    } else if (provider === 'google') {
      providerId = GoogleLoginProvider.PROVIDER_ID;
    }
    this.sub = this.authService
      .signIn(providerId)
      .then((data) => {
        this.checkSocialLink(data, provider);
      })
      .catch((error) =>
        this.flashMessagesService.show(error, {
          cssClass: 'alert-danger',
          timeout: 10000
        })
      );
  }

  public unlinkSocial(provider) {
    if (confirm(this.confirmMessage)) {
      const accessToken = this.userDetails.accessToken;
      if (provider === 'facebook') {
        this.provider = 'FB';
      } else if (provider === 'linkedin') {
        this.provider = 'LI';
      } else if (provider === 'google') {
        this.provider = 'GP';
      }
      this.socialData = new SocialData(this.provider, '', '', '');
      this.profileService.linkFacebook(this.socialData, accessToken, this.params.lang).subscribe(
        (result) => this.linkData(result),
        (error) => (this.errorMessage = error)
      );
    }
  }

  public checkSocialLink(resp, provider) {
    const accessToken = this.userDetails.accessToken;
    if (provider === 'facebook') {
      this.provider = 'FB';
    } else if (provider === 'google') {
      this.provider = 'GP';
    }
    this.socialData = new SocialData(this.provider, resp.uid, resp.name, resp.email);
    this.profileService.linkFacebook(this.socialData, accessToken, this.params.lang).subscribe(
      (result) => this.linkData(result),
      (error) => (this.errorMessage = error)
    );
  }

  public linkData(result) {
    if (!result.error) {
      alert(result.message);
      this.router.navigate([this.langVal + '/editProfile']);
    }
  }

  public changeTwoFactorAuthentication(event: boolean) {
    if (event && !this.profile.has_2fa_code) {
      this.accessLevel.openSetupModal(this.userHash);
    } else {
      this.accessLevel.openVerificationModal(this.userHash, event);
    }
  }

  private enableTwoFactorAuthentication(): void {
    // this.accessLevel.openViewQR(this.userHash, true);
  }

  public handleAccessLevel(event: boolean) {
    this.profile.enabled_2fa = event;
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get diagnostic() {
    return JSON.stringify(this.profile);
  }

  get diagnostic1() {
    return JSON.stringify(this.filedata);
  }

  get openIdSession() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail.scope === 'openid';
      }
    }
    return false;
  }
}
