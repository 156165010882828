import { Component, NgModule, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ProfileService } from "../../core/services/dashboard/profile.service";

import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from "angular2-flash-messages";
import { environment } from "../../../environments/environment";
import { FormValidationService } from "../../core/services/common-service/fromvalidation.service";
import { Email } from "../../core/models/email";

@Component({
  providers: [ProfileService, FormValidationService],
  selector: "app-missing-email",
  styleUrls: ["./missing-email.component.css"],
  templateUrl: "./missing-email.component.html",
})
export class MissingEmailComponent implements OnInit {
  public emailFormControl = this.formValidationService.emailValidation();
  public data: any;
  public email = new Email("");
  private errorMessage: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal;

  public constructor(
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formValidationService: FormValidationService,
    private flashMessagesService: FlashMessagesService,
    private translateService: TranslateService
  ) {
    // empty
  }

  public ngOnInit() {
    // if (localStorage.getItem("currentUser")) {
    //   const accessToken = this.userDetails.accessToken;
    //   this.getUserDetail(accessToken);
    // } else {
    //   this.router.navigate([this.params.lang + "/login/"]);
    // }
  }

  public onSubmit() {
    const username = this.email.email;
    if (username !== "") {
      const accessToken = this.userDetails.accessToken;
      this.profileService.updateEmail(this.email, accessToken, this.params.lang).subscribe(
        (res) => this.initData(res),
        (error) => (this.error = error)
      );
    } else {
      this.translateService.get("Please input your email address.").subscribe((value) => {
        this.flashMessagesService.show(value, { cssClass: "alert-danger", timeout: 5000 });
      });
    }
  }

  public initData(data) {
    if (data.error) {
      this.data = data;
    } else {
      // this.router.navigate([localStorage.getItem("returnUrl")]);
    }
  }

  public getUserDetail(accessToken) {
    this.profileService.getUserDetail(accessToken).subscribe(
      (data) => this.initUserData(data),
      (error) => this.initUserError(error)
    );
  }

  public initUserData(data) {
    this.data = data;
    if (data.email.split("@")[1] !== "diplomasafe.invalid") {
      if (localStorage.getItem("returnUrl")) {
        this.router.navigate([localStorage.getItem("returnUrl")]);
      } else {
        this.router.navigate([this.params.lang + "/dashboard/"]);
      }
    }
  }

  public initUserError(error) {
    if (error.status) {
      this.onlogout();
    }
  }

  get userDetails() {
    if (localStorage.getItem("currentUser")) {
      const userDetail = JSON.parse(localStorage.getItem("currentUser"));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  public onlogout() {
    localStorage.clear();
    // this.router.navigate([this.langVal + "/login"]);
  }
}
