import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonGroupComponent } from './components/diploma-view-page/custom-button-group/custom-button-group.component';
import { CustomButtonComponent } from './components/diploma-view-page/custom-button-group/custom-button/custom-button.component';
import { CustomButtonService } from './services/custom-buton.service';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faPrint,
  faAt,
  faHeadset,
  faCartShopping,
  faMoneyBillWave,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFile as farFile,
  faMessage as farMessage,
  faEnvelope as farEnvelope,
  faHandPointUp as farHandPointUp,
  faCircleQuestion as farCircleQuestion,
  faCreditCard as farCreaditCard,
  faBell as farBell,
} from '@fortawesome/free-regular-svg-icons';

// Include fontawesome icons if new are added
@NgModule({
  declarations: [CustomButtonGroupComponent, CustomButtonComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [CustomButtonGroupComponent],
  providers: [CustomButtonService],
})
export class DiplomaModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faPrint, // ['fas', 'cart-shopping']
      faAt,
      faHeadset,
      faCartShopping, // ['fas', 'cart-shopping']
      faMoneyBillWave,
      faTruckFast,
      farFile, // ['far', 'file']
      farMessage, // ['far', 'message']
      farEnvelope,
      farHandPointUp,
      farCircleQuestion,
      farCreaditCard,
      farBell
    );
  }
}
