import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiplomaRequestChangeService } from 'app/core/services/diploma-request-change/diploma-request-change.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-request-success-page',
  templateUrl: './request-success-page.component.html',
  styleUrls: ['./request-success-page.component.css']
})
export class RequestSuccessPageComponent implements OnInit, OnDestroy {
  public params = this.activatedRoute.snapshot.params;
  public processTimeInDays!: number;

  private ngUnsubscribe$: Subject<void> = new Subject();
  private accessToken!: string;
  private langVal!: string;

  constructor(
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private diplomaRequestChangeService: DiplomaRequestChangeService,
    private location: LocationStrategy
  ) {
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.accessToken = this.userDetails.accessToken;
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }

    this.diplomaRequestChangeService
      .getiplomaRequestChangeProcessTime(this.params.uhash, this.langVal, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.processTimeInDays = Number(res.process_time);
        },
        (err) => {
          this.router.navigate([this.params.lang + '/not-found'], {
            state: { errorMessage: 'Could not get diploma request change', isDiploma: true }
          });
        }
      );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  continue(){
    this.router.navigate([this.params.lang + '/dashboard']);
  }
}
