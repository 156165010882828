import { NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AppComponent } from './app.component';
import { EditPasswordComponent } from './components/dashboard/editpassword.component';
import { EditProfileComponent } from './components/dashboard/editprofile.component';
import { DiplomaLandingComponent } from './components/diploma/diploma-landing/diploma-landing.component';
import {
  DiplomaOtherFormatsComponent
} from './components/diploma/diploma-other-formats/diploma-other-formats.component';
import { DiplomaErrorComponent } from './components/diploma/diplomaerror.component';
import { DiplomaShortComponent } from './components/diploma/diplomashort.component';
import { DiplomaStatusComponent } from './components/diploma/diplomastatus.component';
import { MyDiplomasComponent } from './components/diploma/mydiplomas.component';
import { MyDiplomasPDFComponent } from './components/diploma/mydiplomaspdf.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/forgot-password/reset-password.component';
import { HelpComponent } from './components/help/help.component';
import { LoginComponent } from './components/login/login.component';
import { MissingEmailComponent } from './components/missing-email/missing-email.component';
import { NoStorageComponent } from './components/no-storage/no-storage.component';
import { UserDontExistComponent } from './components/open-id-connect/errors/user-dont-exist/user-dont-exist.component';
import { OpenIdComponentComponent } from './components/open-id-connect/open-id-component.component';
import { TermsComponent } from './components/terms/terms.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { UnsubscribeconfirmComponent } from './components/unsubscribe/unsubscribeconfirm.component';
import { UserComponent } from './components/user/user.component';
import { ClaimBaseComponent } from './components/claim/claim-base/claim-base.component';
import { ShareViewComponent } from './components/diploma/share-view/share-view.component';
import {
  WrongNameRequestComponent
} from './components/diploma/request-changes/wrong-name-request/wrong-name-request.component';
import {
  RequestSuccessPageComponent
} from './components/diploma/request-changes/wrong-name-request/request-success-page/request-success-page.component';
import {
  LinkedinLoginResponceComponent
} from './components/auth/handle-redirect/linkedin/linkedin-login-responce/linkedin-login-responce.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { IsMaintenanceEnabledGuard } from './core/guards/is-maintenance-enabled.guard';
import {
  InitDiplomaTransferComponent
} from './components/diploma/request-changes/init-diploma-transfer/init-diploma-transfer.component';
import { TransferComponent } from './components/diploma/transfer/transfer.component';
import { PathwaySettingsPageComponent } from './components/pathways/settings/page/pathway-settings-page.component';
import { DefaultMetaDataResolver } from './resolvers/metaData/default-meta-data.resolver';
import { DiplomaMetaDataResolver } from './resolvers/metaData/diploma-meta-data.resolver';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
    pathMatch: 'full',
    canActivate: [IsMaintenanceEnabledGuard]
  },
  {
    path: '',
    canActivate: [MaintenanceGuard],
    // canActivateChild: [MetaGuard],
    children: [
      {
        component: AppComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Test'
        },
        path: ':lang/testPage'
      },
      {
        component: ClaimBaseComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          bundle_claim: false
        },
        path: ':lang/claim/:uhash/:id'
      },
      {
        component: ClaimBaseComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          bundle_claim: true
        },
        path: ':lang/bclaim/:uhash/:id'
      },
      {
        component: TransferComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/transfer/:uhash'
      },
      {
        component: OpenIdComponentComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/openid/:hash'
      },
      {
        component: MyDiplomasPDFComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Diploma PDF'
        },
        path: ':lang/myDiplomaPDF/:uh/:id/generatePDF'
      },
      {
        component: DiplomaOtherFormatsComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'download other formats'
        },
        path: ':lang/dip-other-formats/:uh/:nicename'
      },
      {
        component: UserDontExistComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/user-error'
      },
      {
        component: MyDiplomasPDFComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Diploma PDF'
        },
        path: ':lang/myDiplomaPDF/:uh/:id/:pno/generatePDF'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/dip/:uh'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/dip/:uh/:nicename'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/dip/:uh/:nicename/:social'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/diploma/:uh'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/diploma/:uh/:nicename'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/diploma/:uh/:nicename/:social'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/diploma/:uh/:nicename/:view/:userHash'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/share/:uh/:social'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/pdip/:uh/:public'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/dip/:uh/:social'
      },
      {
        component: DiplomaLandingComponent,
        resolve: {
          metaData: DiplomaMetaDataResolver
        },
        path: ':lang/diploma/:uh/:social'
      },
      {
        component: ShareViewComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/diploma-share-view/:uh'
      },
      {
        component: DiplomaShortComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/s/:baseCode'
      },
      {
        component: DiplomaShortComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/s/:baseCode/:type'
      },
      {
        component: DiplomaErrorComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Diploma Error'
        },
        path: ':lang/diploma-error'
      },
      {
        component: DiplomaStatusComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Edit Diploma Status'
        },
        path: ':lang/editDiplomaStatus/:uh/:nicename'
      },
      {
        component: WrongNameRequestComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/reportWrongName/:uhash'
      },
      {
        component: PathwaySettingsPageComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/pathways/diploma/:uhash'
      },
      {
        component: InitDiplomaTransferComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/initDiplomaTransfer/:uhash'
      },
      {
        component: RequestSuccessPageComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/successfullRequest/:uhash'
      },
      {
        component: MyDiplomasComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Overview',
        },
        path: ':lang/myDiplomas'
      },
      {
        component: ForgotPasswordComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Reset my password'
        },
        path: ':lang/forgot-password'
      },
      {
        component: ResetPasswordComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Reset Password'
        },
        path: ':lang/reset-password/:token/:email'
      },
      {
        component: LoginComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Login'
        },
        path: ':lang/login'
      },
      {
        component: LinkedinLoginResponceComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: 'login/linkedin'
      },
      {
        component: TermsComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/terms'
      },
      {
        component: MyDiplomasComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Overview',
        },
        path: ':lang/dashboard'
      },
      {
        component: EditPasswordComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Change Password'
        },
        path: ':lang/changePassword'
      },
      {
        component: EditProfileComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Edit Profile'
        },
        path: ':lang/editProfile'
      },
      {
        component: UnsubscribeComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/unsubscribe/diploma/:uh/:id'
      },
      {
        component: UnsubscribeconfirmComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/unsubscribe/confirm/:uh/:id'
      },
      {
        component: UserComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Overview'
        },
        path: ':lang/usr/:id/:nicename'
      },

      {
        component: HelpComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/help'
      },
      {
        component: MissingEmailComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/missingEmail'
      },
      {
        component: MissingEmailComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: ':lang/missingEmail/:uh/:id'
      },
      {
        component: NoStorageComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Browser outdated'
        },
        path: ':lang/storage-not-supported'
      },
      {
        component: NotFoundComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: 'Not found'
        },
        path: ':lang/not-found'
      },
      {
        component: LoginComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        path: './'
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.DEFAULT_LANGUAGE + '/login'
      },
      {
        path: ':lang',
        pathMatch: 'full',
        redirectTo: environment.DEFAULT_LANGUAGE + '/login'
      },
      {
        component: NotFoundComponent,
        resolve: {
          metaData: DefaultMetaDataResolver
        },
        data: {
          titlePrefix: '404 Error'
        },
        path: '**'
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
