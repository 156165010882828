<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 form">
        <h1 class="margin-bottom-10">{{ 'Reset my password' | translate }}:</h1>
        <div *ngIf="!status; else successMsg">
          <p>
            {{ 'Enter your email address and we will send you a link to reset your password.' | translate }}
          </p>
          <flash-messages></flash-messages>
          <form (ngSubmit)="onForgotPassword()" >
            <mat-form-field>
              <input matInput type="email" name="username" placeholder="{{ 'Type your e-mail address' | translate }}" [(ngModel)]="forgotPassword.email" [formControl]="emailFormControl">
              <mat-error *ngIf="emailFormControl.hasError('pattern')">
                {{ 'Please enter a valid email address' | translate }}
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                {{ 'Email is required' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="button-action">
              <button mat-raised-button type="submit" class="submit full-width">
                <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                {{ 'Send Reset Code' | translate }}
              </button>
            </div>
            <div class="button-action">
              <a mat-raised-button routerLink="/{{ language }}/login{{ (checkRegisterPage) ? checkRegisterPage : '' }}" class="full-width" *ngIf="!checkLoginPage">
                <i class="fa fa-user" aria-hidden="true"></i>&nbsp;
                {{ 'Back To Login' | translate }}
              </a>
            </div>
          </form>
        </div>
        <ng-template #successMsg>
          <p class="margin-top-20">
            {{ "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder." | translate }}
          </p>
          <div class="button-action">
            <a routerLink="/{{ language }}/login">
              <button class="submit full-width" type="button" mat-raised-button>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
                {{ 'Return to login page' | translate }}
              </button>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
