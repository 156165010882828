import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganizationService } from 'app/core/services/organization/organization.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DiplomaService } from 'app/core/services/diploma/diploma.service';
import { DiplomaDesign } from 'app/core/models/diploma';
import { LoginService } from 'app/core/services/login/login.service';
import { ClaimService } from 'app/core/services/claim/claim.service';
import { environment } from 'environments/environment';
import { TrackLinks } from 'app/core/services/common-service/tracklinks';
import { TrackLinksService } from 'app/core/services/common-service/tracklinks.service';
import { Claimable } from 'app/core/models/claim';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmClaimDiplomaDialogComponent
} from '../../shared/wrong-name-request-button/confirm-claim-diploma-dialog/confirm-claim-diploma-dialog.component';
import { UserService } from 'app/core/services/user/user.service';

@Component({
  selector: 'app-claim-base',
  templateUrl: './claim-base.component.html',
  styleUrls: ['./claim-base.component.css']
})
export class ClaimBaseComponent implements OnInit, OnDestroy {
  claimable$: Observable<Claimable>;
  diplomaDesign$: Observable<DiplomaDesign>;
  params = this.activatedRoute.snapshot.params;
  isBundle: boolean;
  oidc: boolean;
  claimedFrom: String;

  public loginClaim: Boolean = false;
  public registerEmail: String | null = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private diplomaService: DiplomaService,
    private activatedRoute: ActivatedRoute,
    private trackLinksService: TrackLinksService,
    private organizationService: OrganizationService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.oidc = !(
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.disableOidc
    );
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.isBundle = data.bundle_claim;
      this.getData();
      if (!this.isBundle) {
        this.diplomaService.checkDesignView(
          this.params.uhash,
          this.userService.accessToken,
          false
        );
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.claimedFrom = params['from'];
    });

    this.claimable$ = this.claimService.claimData;
    this.diplomaDesign$ = this.diplomaService.getDiplomaDesign();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  public getData() {
    this.claimService.fetchClaimData(
      this.params.uhash,
      this.params.lang,
      this.auditHash,
      this.userService.accessToken,
      this.oidc,
      this.isBundle
    );
  }

  public claimDiploma(templateType, hasPendingRequestChanges) {

    if (hasPendingRequestChanges) {
      const dialogRef = this.dialog.open(ConfirmClaimDiplomaDialogComponent, {
        hasBackdrop: true,
        width: '500px',
        data: { templateType }
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result) {
          this.processClaimDiploma();
        }
      });

      return;
    }

    this.processClaimDiploma();
  }

  private processClaimDiploma() {
    this.claimService.claimDiploma(
      this.params.uhash,
      this.auditHash,
      this.claimedFrom,
      this.params.lang
    );
  }

  public claimBundle(bundleHash: string) {
    this.claimService.claimBundle(
      bundleHash,
      this.auditHash,
      this.claimedFrom,
      this.params.lang
    );
  }

  public claimRegisterWithEmail(email: string): void {
    this.userService.userEmailExists(email)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.registerEmail = email;
          if (result['isRegistered']) {
            this.loginClaim = true;
          }
        },
        (error) => {}
      );
  }

  public claimDiplomaRegister(templateType, hasPendingRequestChanges) {
    if (hasPendingRequestChanges) {
      const dialogRef = this.dialog.open(ConfirmClaimDiplomaDialogComponent, {
        hasBackdrop: true,
        width: '500px',
        data: { templateType }
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result) {
          this.processClaimDiplomaRegister();
        }
      });

      return;
    }

    this.processClaimDiplomaRegister();
  }

  private processClaimDiplomaRegister(){
    this.claimService.claimRegster(
      this.params.uhash,
      this.auditHash,
      this.claimedFrom,
      this.params.lang,
      this.registerEmail
    );
  }

  public claimBundleRegister(bundleHash: string) {
    this.claimService.claimBundleRegster(
      bundleHash,
      this.auditHash,
      this.claimedFrom,
      this.params.lang,
      this.registerEmail
    );
  }

  public destroySubscriptions() {
    this.organizationService.clearSubscriptions();
  }

  public reload() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  public trackLink(link: String, templateId: String) {
    this.trackLinksService
      .trackOutgoingLinks(
        new TrackLinks(
          environment.APP_URL + this.router.url,
          link,
          localStorage.getItem('browserSession'),
          templateId
        )
      )
      .subscribe((res) => {
        console.log(res);
      });
  }

  public logout(bundle: boolean) {
    this.loginService.logout().subscribe((res) => {
      localStorage.removeItem('userHash');
      localStorage.removeItem('currentUser');
      const claimLinkPrefix = bundle ? 'bclaim' : 'claim';
      this.router.navigate(
        [this.params.lang, claimLinkPrefix, this.params.uhash, this.auditHash],
        {
          state: {
            disableOidc: true
          }
        }
      );
    });
  }

  get auditHash() {
    return this.params.id.split('?')[0];
  }
}
