import { Component, OnDestroy, OnInit } from '@angular/core';
import { DiplomaTransferService } from '../../../core/services/diploma/diploma-transfer.service';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { DiplomaDesign } from '../../../core/models/diploma';
import { LoginService } from '../../../core/services/login/login.service';
import { ClaimLoginResponse, TransferClaimNewUserResponse } from '../../../core/models/claim';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit, OnDestroy {

  public data!: any;
  public design!: DiplomaDesign;
  private params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe$: Subject<void> = new Subject();
  private accessToken!: string;
  private langVal!: string;

  public errorMessage: string = '';

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private readonly diplomaTransferService: DiplomaTransferService,
    private flashService: FlashMessagesService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {
    this.accessToken = this.userDetails.accessToken;

    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    const auth = this.accessToken ? '1' : '';
    this.diplomaTransferService.getTransferRequest(this.params.uhash, this.langVal, this.accessToken, auth)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          if (res.status === 'error' && res.message) {
          this.errorMessage = res.message;
          }

          this.design = res.design;
          this.data = res.data;
        },
        (err) => {
          this.router.navigate([this.params.lang + '/not-found'], {
            state: { errorMessage: 'Transfer request not found.', isDiploma: true }
          });
        });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public processClaim(register) {

    this.diplomaTransferService.claimDiploma(this.params.uhash, this.langVal, this.accessToken, register)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          if (register && res.response) {
            this.setTokens(res.response);
          }

          this.router.navigate([this.langVal, 'diploma', res.diploma_uhash]);
        },
        (err) => {
          this.flashService.show(err.error.message, {
            cssClass: 'alert-danger'
          });
        });

  }

  public logout() {
    this.loginService.logout().subscribe((res) => {
      localStorage.removeItem('userHash');
      localStorage.removeItem('currentUser');
      this.router.navigate(
        [this.params.lang, 'transfer', this.params.uhash]
      );
    });
  }

  private setTokens(data: TransferClaimNewUserResponse) {
    localStorage.removeItem('currentUser');
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        accessToken: data.access_token,
        expiresIn: data.expires_in,
        refreshToken: data.refresh_token,
        tokenType: data.token_type
      })
    );

    localStorage.removeItem('userHash');
    localStorage.setItem('userHash', data.user_hash);
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

}
