import { Component, OnInit, ViewChild, EventEmitter, Output, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import FacebookLoginResponse from 'app/core/models/auth/facebook-login-response';
import LoginResponse from 'app/core/models/auth/login-response';
import { UserSocialLogin } from 'app/core/models/UserSocialLogin';
import { LoginService } from 'app/core/services/login/login.service';
import { SocialLoginService } from 'app/core/services/social-login/social-login.service';
import { FlashMessagesUtilService } from 'app/core/services/util/flash-messages-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-social-logins',
  templateUrl: './social-logins.component.html',
  styleUrls: ['./social-logins.component.css'],
})
export class SocialLoginsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public socialLogins: UserSocialLogin;
  @Input() public clamingFlow: boolean = false;
  @Output() public onLoginProcessing: EventEmitter<boolean> = new EventEmitter<boolean>();

  private params = this.activatedRoute.snapshot.params;
  @ViewChild('googleButton') googleButton: any;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private socialLoginService: SocialLoginService,
    private flashMessagesUtilService: FlashMessagesUtilService
  ) {}

  public ngOnInit(): void {
    this.socialLoginService.socialLoginInit(this.googleCallback.bind(this));
    window['facebookLogin'] = this.facebookLogin.bind(this);
  }

  public ngAfterViewInit(): void {
    this.socialLoginService.googleLoginButton(this.googleButton);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public facebookLogin(res: FacebookLoginResponse) {    
    if (res.status == 'connected' && res.authResponse['userID']) {
      this.socialLoginService.getFacebookEmail()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((email: string) => {      
          this.clamingFlow
            ? this.socialSignIn('facebook', res.authResponse['userID'])
            : this.checkSocialLogin('facebook', res.authResponse['userID'], email);
        });
    }
  }

  public googleCallback(userId: string, userEmail: string) {    
    if (userId) {
      this.clamingFlow
        ? this.socialSignIn('google', userId)
        : this.checkSocialLogin('google', userId, userEmail);
    }
  }

  public linkedInLogin() {    
    if (this.clamingFlow && this.params.uhash) {
      sessionStorage.setItem('clamingFlow', `${this.params.uhash}/${this.params.id}`);
      this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
        sessionStorage.setItem('clamingFlowParams', JSON.stringify(params));
      });
    }

    this.socialLoginService.linkedinLogin();
  }

  private checkSocialLogin(socialType: string, socialId: string, socialEmail: string) {
    this.onLoginProcessing.emit(true);

    this.socialLoginService
      .checkSocialLogin(socialType, socialId, socialEmail)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res.redirect) {
            this.onLoginProcessing.emit(false);
              this.loginService.clearSessionRedirectToLogin(res.uhash, socialType);
          } else {
            this.socialSignIn(socialType, socialId);
          }
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  // social login
  private socialSignIn(socialType: string, socialId: string) {
    this.socialLoginService
      .socialLogin(socialType, socialId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res['user']) {
            this.initData(res['user']);
          }
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  public initError(message: string): void {
    this.onLoginProcessing.emit(false);

    this.flashMessagesUtilService.flashMessages(message, 'danger');
  }

  private initData(data: LoginResponse) {
    this.loginService.login(data, !this.clamingFlow);
  }
}
