<div class="log-with-social">
  <p class="text-center no-margin margin-bottom-10">- {{ 'or log in with your social accounts' | translate }} -</p>

  <!-- Google -->
  <div *ngIf="!clamingFlow || socialLogins.google" #googleButton class="google-login-button"></div>

  <!-- Facebook -->
  <div
    *ngIf="!clamingFlow || socialLogins.facebook"
    class="fb-login-button"
    data-width="100%"
    data-max-rows="1"
    data-size="large"
    data-scope="email"
    data-button-type="continue_with"
    data-layout="rounded"
    data-auto-logout-link="false"
    data-use-continue-as="true"
    data-onlogin="facebookLogin"
  ></div>

  <!-- LinkedIn -->
  <button *ngIf="!clamingFlow || socialLogins.linkedin" class="linkedin-login-button full-width" (click)="linkedInLogin()" type="button">
    <i class="fa fa-linkedin"></i>
    <span>
      {{ 'Continue with /social/' | translate:{social: 'LinkedIn'} }}
    </span>
  </button>
</div>
