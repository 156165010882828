<header [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.header_color ? diplomaDesign?.header_color : ''">
  <div>
    <div class="container" [ngClass]="{'source-sans-pro': !defaultFont}">
      <div class="menu language dropdown pull-right" [style.color]="
        diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
          ? diplomaDesign?.header_text_color
          : ''">
        <span class="dropdown-toggle noselect" data-toggle="dropdown">
          <ng-template *ngFor="let language of languages" [ngIf]="selectedLanguage == language['langCode']">
            <figure [ngClass]="language['flag']"></figure>
            {{ language['native'] }}
          </ng-template>
          <span class="caret"></span>
        </span>
        <ul class="dropdown-menu">
          <li *ngFor="let language of languages" [ngClass]="selectedLanguage == language['langCode'] ? 'active' : ''" (click)="updateLanguage(language['langCode'])">
            <a [routerLink]="'/' + language['langCode'] + currentUrl" [queryParams]="currentUrlParams" [appOnHoverBackground]="diplomaDesign?.header_color">
              <figure [ngClass]="language['flag']"></figure>
              {{ language['native'] }}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="mainData?.name else elseLoginBtn">
        <div class="menu dropdown pull-right"
          [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''">
          <span class="dropdown-toggle text-semibold noselect" data-toggle="dropdown">
            <i class="fa fa-user green-color" aria-hidden="true"></i>&nbsp;
            {{ mainData?.name }}
            <span class="caret"></span>
          </span>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/{{ selectedLanguage }}/dashboard" [appOnHoverBackground]="diplomaDesign?.header_color">
                <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp;
                {{ 'Overview' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/{{ selectedLanguage }}/editProfile" [appOnHoverBackground]="diplomaDesign?.header_color">
                <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                {{ 'Edit Profile' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/{{ selectedLanguage }}/changePassword" *ngIf="!mainData?.social" [appOnHoverBackground]="diplomaDesign?.header_color">
                <i class="fa fa-key" aria-hidden="true"></i>&nbsp;
                {{ 'Change Password' | translate }}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="onlogout()" [appOnHoverBackground]="diplomaDesign?.header_color">
                <i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;
                {{ 'Logout' | translate }}
              </a>
            </li>
          </ul>
        </div>

        <a  href="{{ customSuportLink }}" class="help pull-right noselect" [style.color]="
          diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''" *ngIf="customSuportLink.length>0; else defaultUrl">
          <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
          {{ 'Need Help?' | translate }}
        </a>
        <ng-template #defaultUrl>
        <a  routerLink="/{{ selectedLanguage }}/help" class="help pull-right noselect" [style.color]="
          diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''">
          <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
          {{ 'Need Help?' | translate }}
        </a>
        </ng-template>
      </div>
      <ng-template #elseLoginBtn>
        <a routerLink="/{{ selectedLanguage }}/login{{ (checkRegisterPage) ? checkRegisterPage : '' }}" class="login pull-right text-semibold noselect" *ngIf="!checkLoginPage"
          [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''">
          <i class="fa fa-user" aria-hidden="true"></i>
          {{ 'Login' | translate }}
        </a>
        <a routerLink="/{{ selectedLanguage }}/help" class="help pull-right noselect" [style.color]="
          diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''">
          <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
          {{ 'Need Help?' | translate }}
        </a>
      </ng-template>
    </div>
  </div>
</header>
