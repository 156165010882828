import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ProfileService } from '../../core/services/dashboard/profile.service';
import { LanguageUtilService } from '../../core/services/util/language-util.service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

import userAccessToken from 'app/core/helpers/user/access-token';
import { LoginService } from 'app/core/services/login/login.service';
import { UrlUtilService } from 'app/core/services/util/url-util.service';
import { Subject, throwError } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DiplomaDesign } from '../../core/models/diploma';

@Component({
  providers: [ProfileService, LoginService],
  selector: 'app-header',
  styleUrls: ['./header.component.css'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public diplomaDesign: DiplomaDesign;
  @Input() public inheritedLanguage: string;
  @Input() public defaultFont?: boolean = true;
  @Input() public customSuportLink: string = '';
  @Input() public diploma: any;

  public mainData: any;
  public currentUrl: string;
  public currentUrlParams: object;
  public checkHelpPage: boolean;
  public checkLoginPage: boolean;
  public checkRegisterPage: string | boolean;
  public languages: any = this.languageUtilService.getSortedLanguages();

  private langVal: string;
  private params: any = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private languageUtilService: LanguageUtilService,
    private urlUtilService: UrlUtilService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.langVal = this.inheritedLanguage || this.params.lang || environment.DEFAULT_LANGUAGE;

    this.getChecks();

    if (!this.checkHelpPage) {
      this.urlUtilService.checkLocalStorageSupports(this.langVal);
    }

    this.languageUtilService.updateLanguage(this.langVal, false);

    this.setUrls();

    if (userAccessToken()) {
      this.getUserDetail(userAccessToken());
    }

    this.inheritedLanguage = null;
  }


  public getUserDetail(accessToken: string): void {
    this.profileService
      .getUserDetail(accessToken)
      .pipe(take(1))
      .subscribe(
        (data) => this.initUserData(data),
        (error) => this.initUserError(error)
      );
  }

  public initUserData(data) {
    if (data.error) {
      this.onlogout();
    } else {
      this.mainData = data;
      if (data.email.split('@')[1] === 'diplomasafe.invalid') {
        this.setReturnUrl();
        this.router.navigate([this.params.lang + '/missingEmail/']);
      }
    }
  }

  public initUserError(error) {
    if (error.status) {
      this.onlogout();
    }
  }

  get userDetails() {
    try {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(localStorage.getItem('currentUser'));
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  public onlogout() {
    this.loginService
      .logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        localStorage.clear();
        this.router.navigate([this.cookieService.get('langVal') + '/login']);
      });
  }

  public updateLanguage(langVal: string) {
    this.languageUtilService.updateLanguage(langVal);
  }

  get selectedLanguage() {
    return this.translateService.currentLang;
  }

  private getChecks(): void {
    this.checkLoginPage = this.urlUtilService.checkLoginPage();
    this.checkHelpPage = this.urlUtilService.checkHelpPage();
    this.checkRegisterPage = this.urlUtilService.checkRegisterPage(this.params.uh, this.params.id);
  }

  private setUrls(): void {
    this.currentUrl = this.urlUtilService.getCurrentUrl();
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.currentUrlParams = params || {};
    });
  }

  private setReturnUrl() {
    const currentUrl = this.router.url;
    const page = currentUrl.split('/')[2];
    if (currentUrl.split('/')[2] !== 'missingEmail') {
      try {
        localStorage.removeItem('returnUrl');
        const returnUrl = currentUrl.slice(1, 250);
        localStorage.setItem('returnUrl', returnUrl);
      } catch (error) {
        return throwError(error);
      }
    }
  }
}
