<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 text-center">
        <h1 class="margin-bottom-30 red-color">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'Error' | translate }}
        </h1>
        <p class="margin-bottom-30">
		      {{ 'Oops! The diploma not found.' | translate }}
	      </p>
	      <a class="btn btn-success text-uppercase" routerLink="/">{{ 'Go Back to the Homepage' | translate }}</a>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
