import { ActivatedRoute, Router } from '@angular/router';
import { DiplomaFieldType } from '../../../core/models/diploma-field';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, DoCheck, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ShareViewService } from '../../../core/services/share-view/share-view.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareLinkDialogComponent } from '../../share-view/share-link-dialog/share-link-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-share-view',
  templateUrl: './share-view.component.html',
  styleUrls: ['./share-view.component.css']
})
export class ShareViewComponent implements OnInit {

  public allChecked = false;
  public fields: object[] = [];
  public disableSubmit = false;
  public params = this.activatedRoute.snapshot.params;
  public diplomaFieldType = DiplomaFieldType;
  public languages: Observable<object[]>;
  public selectedLanguage: number;

  public form: FormGroup;

  private enabledFields = {};

  @Output() public onSubmit: EventEmitter<boolean>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private shareViewService: ShareViewService,
    private flashMessagesService: FlashMessagesService
  ) {

  }

  async ngOnInit() {
    this.shareViewService.getLanguages(this.params.uh, this.params.lang);
    this.languages = this.shareViewService.languages;

    await this.loadFieldsData(this.params.lang);

    this.form = this.formBuilder.group({
      linkName: ['', [
        Validators.required,
        Validators.pattern('[A-Za-z0-9]{3,20}')
      ]],
      password: ['', [
        Validators.pattern('[A-Za-z0-9]{3,20}')
      ]],
      repeatPassword: [''],
      validity: ['', [Validators.required]],
      language: ['', [Validators.required]]
    });



    this.onSubmit = new EventEmitter<boolean>();
  }

  public submitForm() {
    if (this.form.valid) {
      const data = this.form.value;
      data.fields = this.enabledFields;
      data.diploma_hash = this.params.uh;
      data.ui_lang = this.params.lang;

      this.disableSubmit = true;

      this.shareViewService.createView(data)
        .then((res) => {
          if (res.error && res.message === 'UniqueName') {
            this.linkName.setErrors({ uniqueName: true });
            this.disableSubmit = false;
          }

          if (!res.error && res.url) {
            this.openLinkResult(res.url, res.id);
          }
        })
        .catch(() => {
          this.shareViewService.handleError(this.params.lang);
        });
    }
  }

  public openLinkResult(link: string, id: number) {
    this.dialog.open(ShareLinkDialogComponent, {
      hasBackdrop: true,
      maxHeight: '900px',
      width: '500px',
      data: { linkResult: link, shareViewId: id }
    });

    this.dialog.afterAllClosed.subscribe(res => {
      this.router.navigate([`${this.params.lang}/diploma/${this.params.uh}`]);
    });
  }

  get linkName() {
    return this.form.controls.linkName;
  }

  get password() {
    return this.form.controls.password;
  }

  get validity() {
    return this.form.controls.validity;
  }

  get repeatPassword() {
    return this.form.controls.repeatPassword;
  }

  get language() {
    return this.form.controls.language;
  }

  public hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  public change(event) {
    if (event.data['type'] !== 'table') {
      if (event.value) {
        this.enabledFields[event.data['fieldId']] = event.data;
        return;
      }

      delete this.enabledFields[event.data['fieldId']];
      return;
    }

    if (event.value) {
      if (!this.enabledFields[event.data['fieldId']]) {
        this.enabledFields[event.data['fieldId']] = { type: 'table', value: {} };
      }

      this.enabledFields[event.data['fieldId']]['value'][event.data['index']] = event.data;
      return;
    }

    if (this.enabledFields[event.data['fieldId']]) {
      delete this.enabledFields[event.data['fieldId']]['value'][event.data['index']];
    }
  }

  public isChecked(data) {
    if (data.type !== 'table') {
      //If the field ID persists in the enabled field it is 'checked'
      return this.enabledFields[data.fieldId];
    }

    //If the field ID persists in the enabled fields
    //And the row index persists in the field values it is 'checked'
    return (this.enabledFields[data.fieldId] && this.enabledFields[data.fieldId]['value'][data.index]);
  }


  public checkPasswords() {
    if (this.password.value !== this.repeatPassword.value) {
      this.repeatPassword.setErrors({ noMatch: true });
    }
  }

  /**
   * Sorting function used from angular pipe keyvalue
   * @param a
   * @param b
   */
  public fieldsOrder(a, b) {
    const indexA = parseInt(a.key.replace('row', ''));
    const indexB = parseInt(b.key.replace('row', ''));

    return indexA - indexB;
  }

  public toggleAll(event) {
    this.allChecked = event;
    this.enabledFields = {};

    if (event) {
      this.fields.forEach((el) => {

        if (el['type'] !== 'table') {
          this.change({
            value: true,
            data: { fieldId: el['id'], type: el['type'], value: el['id'] }
          });

          return;
        }

        for (const row in el['value']) {
          this.change({
            value: true,
            data: { fieldId: el['id'], index: row, type: el['type'] }
          });
        }

      });
    }

  }

  public ngOnDestroy(): void {
    this.shareViewService.destroySubscriptions();
  }

  public cancel() {
    this.router.navigate([`${this.params.lang}/diploma/${this.params.uh}`]);
  }

  /**
   * Loads diploma fields data
   *
   * @private
   */
  private async loadFieldsData(langId: number) {
    this.shareViewService.getFieldsData(this.params.uh, langId)
      .then((res) => {
        this.fields = res['data'];
      })
      .catch((err) => {
        this.fields = [];
        setTimeout(() => {
          this.flashMessagesService.show(
            this.translate.instant('Error'), {
              cssClass: 'alert-danger',
              timeout: 5000
            });
        });
      });
  }

  public languageSelected(event) {

    this.loadFieldsData(event.value);
  }
}
