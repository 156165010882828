// Observable Version
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { DiplomaUserData } from '../../models/user';
import { UserWithTwoFactor, UserWithDiplomaAccess } from '../../models/two-factor-authentication';

import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import getHeaders from 'app/core/helpers/http/http-headers';
import userAccessToken from 'app/core/helpers/user/access-token';

const USER_WITH_2FA = (userHash: string) => environment.API_START_URL + `api/user-with-2fa/${userHash}`;
const GET_USER_DATA_URL = (uhash: string) => environment.API_START_URL + `api/userDetails/${uhash}`;
const GET_LOGGED_USER_DATA = () => environment.API_START_URL + `api/getUserDetails`;
const GET_USER_DIPLOMA_URL = () => environment.API_START_URL + `api/userDiplomas`;
const GET_USER_EMAIL_EXISTS = () => environment.API_START_URL + `api/user-email-exists`;
const HAS_DIPLOMA_ACCESS = (userHash: string, diplomaHash: string) =>
  environment.API_START_URL + `api/admin-has-diploma-access/${userHash}/${diplomaHash}`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private diplomaRecipient: Subject<DiplomaUserData> = new Subject<DiplomaUserData>();

  constructor(private router: Router, private httpClient: HttpClient, private cookieService: CookieService) {}

  public getUserData(uhash: string): Observable<any> {
    return this.httpClient
      .get(GET_USER_DATA_URL(uhash))
      .catch(this.handleError);
  }

  public getLoggedUserData(): Observable<any> {
    return this.httpClient
      .get(GET_LOGGED_USER_DATA(), { headers: getHeaders() })
      .catch((err) => this.handleError(err));
  }

  public userEmailExists(email: string): Observable<any> {
    return this.httpClient.get(GET_USER_EMAIL_EXISTS(), { params: { email }})
  }

  public getUserDiploma(id, lang): Observable<any> {
    return this.httpClient.get(GET_USER_DIPLOMA_URL() + '/' + lang + '/' + id).catch(this.handleError);
  }

  public setDiplomaRecipient(recipient: DiplomaUserData) {
    this.diplomaRecipient.next(recipient);
  }

  public getDiplomaRecipientObservable(): Observable<DiplomaUserData> {
    return this.diplomaRecipient.asObservable();
  }

  public isTwoFactorEnabled(userHash: string, accessToken: string): Observable<UserWithTwoFactor> {
    return this.httpClient.get<UserWithTwoFactor>(USER_WITH_2FA(userHash), { responseType: 'json' }).pipe(
      catchError((err, caught) => {
        throw err;
      })
    );
  }

  public adminHasDiplomaAccess(userHash: string, diplomaHash: string): Observable<UserWithDiplomaAccess> {
    return this.httpClient
      .get<UserWithDiplomaAccess>(HAS_DIPLOMA_ACCESS(userHash, diplomaHash), {
        responseType: 'json',
      })
      .pipe(
        catchError((err, caught) => {
          throw err;
        })
      );
  }

  public clearSessionRedirectToLogin(uhash: string = '') {
    localStorage.clear();
    this.router.navigate([this.cookieService.get('langVal') + '/login'], { queryParams: { social: uhash } });
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.clearSessionRedirectToLogin();
      }
      const body = error || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  get accessToken(): string {
    return userAccessToken();
  }
}
