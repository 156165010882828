/* tslint:disable:variable-name */
export class SocialLogin {
  constructor(
    public client_id: any,
    public client_secret: any,
    public grant_type: any,
    public social_id: string,
    public social_type: string,
    public scope: any
  ) {}
}
