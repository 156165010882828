import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from 'app/constants';
import { DiplomaRequestChangeResponceInterface } from 'app/core/types';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class DiplomaRequestChangeService {
  constructor(
    private httpClient: HttpClient,
    private loginService: LoginService,
    @Inject(API_URL) private readonly apiUrl: string
  ) {}

  public createDiplomaRequestChange(
    data: { correct_name: string; diploma_uhash: string; change_request_type: string },
    lang: string,
    accessToken: string
  ): Observable<DiplomaRequestChangeResponceInterface> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .post(`${this.apiUrl}api/diploma-request-changes/${lang}`, data, { headers: header })
      .map(this.extractData);
  }

  public updateDiplomaRequestChange(
    requestUhash: string,
    data: { correct_name: string; diploma_uhash: string; change_request_type: string },
    lang: string,
    accessToken: string
  ): Observable<DiplomaRequestChangeResponceInterface> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .patch(`${this.apiUrl}api/diploma-request-changes/${lang}/${requestUhash}`, data, { headers: header })
      .map(this.extractData);
  }

  public getiplomaRequestChangeProcessTime(
    requestUhash: string,
    lang: string,
    accessToken: string
  ): Observable<{ error: boolean; process_time: number }> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .get(`${this.apiUrl}api/diploma-request-changes/${lang}/${requestUhash}/process-time`, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res));
  }

  private extractData(res: HttpResponse<any>): any {
    return res || {};
  }

  public createDiplomaTransferRequest(
    data: { name: string; email: string; email_confirmation: string; diploma_uhash: string; diploma_request_type: string },
    lang: string,
    accessToken: string
  ): Observable<DiplomaRequestChangeResponceInterface> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .post(`${this.apiUrl}api/diploma-transfer-request-change/${lang}`, data, { headers: header })
      .map(this.extractData);
  }

  public cancelDiplomaTransferRequest(
    requestUhash: string,
    lang: string,
    accessToken: string
  ): Observable<DiplomaRequestChangeResponceInterface> {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .patch(`${this.apiUrl}api/diploma-transfer-request-change/${lang}/${requestUhash}`, {}, { headers: header })
      .map(this.extractData);
  }
}
