import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "environments/environment";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught: Observable<HttpEvent<any>>) => {
        if (err instanceof HttpErrorResponse && err.status == 401) {
          localStorage.clear();
          window.location.href = environment.APP_URL + "/" + "en-US" + "/login";
        }

        throw err;
      })
    );
  }
}
