
<div *ngIf="diplomaIsClaimed; else notClaimedDiploma">
  <button (click)="openDialog()" mat-raised-button class="wrong-name-claimed-diploma-button">{{ 'Report wrong name in /templateType/' | translate:{templateType} }}</button>
</div>
<ng-template #notClaimedDiploma>
  <div class="wrong-name-request" >
    <a routerLink="/{{ lang }}/reportWrongName/{{ params.uhash }}" class="text-semibold">
      {{ 'Is your name misspelled? - Click here!' | translate }}
    </a>
  </div>
</ng-template>
