import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { OpenIdServiceService } from "../../core/services/openid-connect/open-id-service.service";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "app-open-id-component",
  templateUrl: "./open-id-component.component.html",
  styleUrls: ["./open-id-component.component.css"],
})
export class OpenIdComponentComponent implements OnInit, OnDestroy {
  public differentSessionHash: Observable<string>;
  public language: string = this.route.snapshot.params.lang;

  constructor(
    private openIdService: OpenIdServiceService,
    private route: ActivatedRoute
  ) {
    this.differentSessionHash =
      this.openIdService.differentSessionHashObservable();
  }

  ngOnDestroy(): void {
    this.openIdService.deleteSubscription();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.openIdService.openId(params.hash, params.lang);
    });
  }
}
