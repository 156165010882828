import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  styleUrls: ['./not-found.component.css'],
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

  private params = this.activatedRoute.snapshot.params;
  private supportedLanguages = ['en-US', 'da-DK', 'pt-PT', 'nl-NL', 'it-IT'];
  public language: string;
  public data: { isDiploma: string, errorMessage: string };
  public homepageUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  public ngOnInit() {
    const browserLanguage = navigator.language;
    this.data = history.state || '';

    if (this.params.lang) {
      this.language = this.params.lang;
    } else if (this.supportedLanguages.includes(browserLanguage)) {
      this.language = browserLanguage;
    } else {
      this.language = 'en-US';
    }


    if (!localStorage.getItem('currentUser')) {
      this.homepageUrl = `/${this.language}/login`;
    } else {
      this.homepageUrl = `/${this.language}/dashboard`;
    }
  }

  public appUrl() {
    return environment.APP_URL;
  }
}
