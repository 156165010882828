import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiRoutes} from "../../../constants/api-routes";
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})

export class BlockcertsService {

  constructor(
    private http: HttpClient
  ) {
  }

  downloadCertificate(diplomaId) {
    return this.http.post(ApiRoutes.apiDownloadBlockcerts, {
      'diploma_id': diplomaId
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.userDetails.accessToken,
        'Content-Type': 'application/json',
      }
    }).toPromise()
      .then((data: any) => {

        let fileName = data.id.split(':')[2] + '.json';

        const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
        FileSaver.saveAs(blob, fileName);

        return data;
      });
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
