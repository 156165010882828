import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {ShareOption} from '../../../core/models/ShareOption';
import {DiplomaShareService} from '../../../core/services/diploma-share/diploma-share.service';

@Component({
  providers: [DiplomaShareService],
  selector: 'app-diploma-share-dialog',
  templateUrl: './diploma-share-dialog.component.html',
  styleUrls: ['./diploma-share-dialog.component.css'],
})
export class DiplomaShareDialogComponent implements OnInit, OnDestroy {

  @Input() public diplomaHash: string;
  @Input() public social: string;
  @Input() public dismiss: any;
  @Input() public data: any;
  @Output() public selectEvent: EventEmitter<boolean|string>;

  public diplomaShareImages: Observable<ShareOption[]>;
  private params: any;

  constructor(private diplomaShareService: DiplomaShareService,
              private route: ActivatedRoute) {
    this.diplomaShareImages = diplomaShareService.diplomaImages();
    this.params = this.route.snapshot.params;
    this.selectEvent = new EventEmitter<boolean|string>();
  }

  ngOnInit() {
    this.getImages(this.diplomaHash);
  }

  ngOnDestroy() {
    this.diplomaShareService.destroySubscriptions();
  }

  public getImages(hash) {
    this.diplomaShareService.getDiplomaShareImages(this.params.lang, hash);
  }

  public selectShareOption(value) {
    const subscription = this.diplomaShareService
      .selectShareMethod(this.diplomaHash, value)
      .subscribe((result) => {
        this.diplomaShareService.pushSubscription(subscription);
        this.selectEvent.emit(this.social);
        this.dismiss('Close');
      }, (error) => {
        this.diplomaShareService.pushSubscription(subscription);
        this.selectEvent.emit(false);
        this.dismiss('Close');
      });
  }
}
