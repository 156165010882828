import {Component, Input, OnInit} from '@angular/core';
import {DiplomaService} from "../../../core/services/diploma/diploma.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-diploma-validation',
  templateUrl: './diploma-validation.component.html',
  styleUrls: ['./diploma-validation.component.css']
})
export class DiplomaValidationComponent implements OnInit {

  @Input() public dismiss;
  @Input() public getData;
  public identifierValue;
  public value;
  public data;

  @Input() private userDetails;
  public identifierData = true;
  private params = this.activatedRoute.snapshot.params;
  private errorMessage: string;

  constructor(private diplomaService: DiplomaService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  public validateIdentifier(uh, nicename, identifierValue) {
    const accessToken = this.userDetails.accessToken;
    this.diplomaService.validateIdentifier(uh, nicename, this.params.lang, accessToken, identifierValue)
        .subscribe(
            (data) => this.initData(data),
            (error) => this.errorMessage = error);
  }

  public initData(data) {
    this.value = data;
    this.data = this.getData(this.params.uh, this.params.nicename);
    this.identifierData = false;
  }
}
