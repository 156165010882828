import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Login } from '../../../../core/models/login';
import { environment } from '../../../../../environments/environment';
import { LoginService } from '../../../../core/services/login/login.service';
import { FlashMessagesUtilService } from '../../../../core/services/util/flash-messages-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-login',
  templateUrl: './transfer-login.component.html',
  styleUrls: ['./transfer-login.component.css']
})
export class TransferLoginComponent implements OnInit, OnDestroy {

  @Input() public lang: String;

  public form: FormGroup;
  public loginData = new Login(
    environment.CLIENT_ID,
    environment.CLIENT_SECRET,
    environment.GRANT_TYPE_PASSWORD,
    '',
    '',
    '',
    ''
  );
  private ngUnsubscribe: Subject<void> = new Subject<void>();


  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService,
              private flashMessagesUtilService: FlashMessagesUtilService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern(/^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,9}))$/)
        ]
      ],
      password: ['', [Validators.required]]
    });
  }

  public onLogin() {
    this.loginService
      .makeLogin(this.loginData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          data['user']
            ? this.loginService.login(data['user'], false)
            : this.initError('Incorrect Username or Password');
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  private initError(message: string) {
    this.flashMessagesUtilService.flashMessages(message, 'danger');
  }

  get username() {
    return this.form.controls.username;
  }

  get password() {
    return this.form.controls.password;
  }

}
