<footer [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.header_color ? diplomaDesign?.header_color : ''">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="footer-menu">
          <div class="privacy dropup">
            <span class="dropdown-toggle noselect reset" data-toggle="dropdown" [style.color]="
              diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
                ? diplomaDesign?.header_text_color
                : ''">
              {{ 'Privacy & Terms' | translate }}
              <span class="caret"></span>
            </span>
            <ul class="dropdown-menu">
              <li>
                <a href="https://diplomasafe.com/privacy/" target="_blank" rel="noopener noreferrer" [appOnHoverBackground]="diplomaDesign?.header_color">
                  {{ 'Privacy Statement' | translate }}
                </a>
              </li>
              <li>
                <a href="https://diplomasafe.com/eula/" target="_blank" rel="noopener noreferrer" [appOnHoverBackground]="diplomaDesign?.header_color">
                  {{ 'Terms & Conditions' | translate }}
                </a>
              </li>
              <li>
                <a href="https://diplomasafe.com/cookies/" target="_blank" rel="noopener noreferrer" [appOnHoverBackground]="diplomaDesign?.header_color">
                  {{ 'Cookie Policy' | translate }}
                </a>
              </li>
            </ul>
          </div>
          <div class="help noselect d-sm-block">
            <i class="fa fa-life-ring green-color" aria-hidden="true"></i>
            <a href="{{ customSuportLink }}" [style.color]="
              diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
                ? diplomaDesign?.header_text_color
                : ''" *ngIf="customSuportLink; else defaultUrl">
              {{ 'Need Help?' | translate }}
            </a>
            <ng-template #defaultUrl>
            <a routerLink="/{{ language }}/help" [style.color]="
              diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
                ? diplomaDesign?.header_text_color
                : ''">
              {{ 'Need Help?' | translate }}
            </a>
          </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="powered-by" [style.color]="
          diplomaDesign?.custom_colors && diplomaDesign?.header_text_color
            ? diplomaDesign?.header_text_color
            : ''">
          <div>{{ 'Powered By' | translate }} Diplomasafe</div>
        </div>
      </div>
    </div>
  </div>
</footer>
