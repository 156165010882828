<main class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box text-center">
        <h1 class="margin-bottom-30 red-color">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ 'Error' | translate }}
        </h1>
        <p class="margin-bottom-30">
          {{ 'Your browser is outdated.' | translate }}
          <br>
          {{ 'Please update your browser or try with another browser.' | translate }}
        </p>
        <a class="btn btn-success text-uppercase" routerLink="/{{ language }}/help">{{ 'Click here for help' | translate }}</a>
      </div>
    </div>
  </div>
</main>
