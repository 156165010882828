<ngx-loading-bar></ngx-loading-bar>
<div class="{{ isDemo }}">
    <div class="demo-div">
        <div class="container">
        <div class="row">
            <div class="col-md-4 d-none d-md-block" style="white-space: nowrap">
            This is a Diplomasafe demo site
            </div>
            <div class="col-md-4" style="white-space: nowrap">
            This is a Diplomasafe demo site
            </div>
            <div class="col-md-4 d-none d-md-block" style="white-space: nowrap">
            This is a Diplomasafe demo site
            </div>
        </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
