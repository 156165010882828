<div class='col-md-12 col-lg-12 dialog'>
  <div *ngIf='this.data | async as data; else genericLoading'>
    <div *ngIf='!this.isEdit' class='mb-3'>
      <img
        *ngIf='this.orgainzationImage; else diplomasafeImage'
        [src]='this.orgainzationImage'
        class='organization-logo-2fa'
      />
      <ng-template #diplomasafeImage>
        <img
          src='../../../../assets/img/logo_with_text_large.png'
          alt="{{ 'Diplomasafe logo' | translate }}"
          class='organization-logo-2fa'
        />
      </ng-template>
    </div>
    <div>
      <div *ngIf='!this.isEdit' class='header mb-3'>
        {{ 'You can configure diploma pathway' | translate }}
      </div>
      <!-- Pathway list -->
      <div class='pathway-select'>
        <input
          id='pathway-no-selected'
          type='checkbox'
          (click)='noPathwaySelected(data)'
          [checked]='data?.noSelected'
        />
        <label
          for='pathway-no-selected'
          [ngClass]="data.noSelected ? 'active' : 'inactive'"
        >
          <div class='check'></div>

          <div class='pathway-content'>
            <div class='pathway-badge'>
              <div class='pathway-badge-none' [ngClass]="data.noSelected ? 'active' : 'inactive'">
                <i class="fa fa-solid fa-ban"></i>
              </div>
            </div>
            <div>
              {{ 'None' | translate }}
            </div>
          </div>

          <span class='btnBefore'></span>
          <span class='btnAfter'></span>
        </label>

        <ng-container *ngFor='let pathway of data?.pathways'>
          <input
            id='pathway-{{ pathway.id }}'
            type='checkbox'
            (click)='pathwayToggle(pathway, data)'
            [checked]='pathway.active'
          />
          <label
            for='pathway-{{ pathway.id }}'
            [ngClass]="pathway.active ? 'active' : 'inactive'"
          >
            <div class='check'></div>

            <div class='pathway-content'>
              <div class='pathway-badge'>
                <img [src]='pathway.badge' [ngClass]="pathway.active ? 'active' : 'inactive'" />
              </div>
              <div>
                {{ pathway.name }}
              </div>
            </div>

            <span class='btnBefore'></span>
            <span class='btnAfter'></span>
          </label>
        </ng-container>
      </div>
      <div class='visible-for-third-party'>
        <label for='thirdPartyVisible'
               matTooltip="{{'When enabled, anyone can see your progress for achieving the goal the same way you see it.' | translate }}"
               [matTooltipPosition]='"above"'
        >
          <input type='checkbox' [(ngModel)]='visibleForThirdParty' id='thirdPartyVisible'>
          {{ 'Visible to 3rd parties' | translate }}</label>
      </div>

      <div class='center button-group'>
        <button mat-raised-button type='button' class='btn-success button-width' (click)='saveSettings(data.pathways)'>
          {{ 'Save' | translate }}
        </button>

        <button
          *ngIf='!this.isEdit; else cancelBtn'
          mat-raised-button
          type='button'
          class='btn-success button-width'
          (click)='saveSettings(data.pathways, true)'
        >
          {{ 'Skip' | translate }}
        </button>
        <ng-template #cancelBtn>
          <button mat-raised-button type='button' class='btn-danger button-width' (click)='back()'>
            {{ 'Cancel' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #genericLoading> {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...</ng-template>
</div>
