import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserSocialLogin } from 'app/core/models/UserSocialLogin';
import { FacebookService } from '../socials/facebook.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GoogleService } from '../socials/google.service';
import { SocialLogin } from 'app/core/models/auth/social-login';
import CheckSocialLogin from 'app/core/models/auth/check-social-login';
import { LinkedinService } from '../socials/linkedin.service';
import getHeaders from 'app/core/helpers/http/http-headers';

const SOCIAL_LOGIN = () => environment.API_START_URL + `api/social-login`;
const GET_SOCIAL_LOGINS = () => environment.API_START_URL + `api/get-social-logins`;
const LINK_SOCIAL_LOGIN = () => environment.API_START_URL + `api/link-social-login`;
const CHECK_SOCIAL_LOGIN = () => environment.API_START_URL + `api/check-social-login`;
const UNLINK_SOCIAL_LOGIN = () => environment.API_START_URL + `api/unlink-social-login`;

@Injectable({
  providedIn: 'root',
})
export class SocialLoginService {
  constructor(
    private httpClient: HttpClient,
    private googleService: GoogleService,
    private facebookService: FacebookService,
    private linkedinService: LinkedinService
  ) {}

  public getSocialLogins(): Observable<UserSocialLogin> {
    return this.httpClient.get<UserSocialLogin>(GET_SOCIAL_LOGINS(), {
      headers: getHeaders(),
    });
  }

  public getFacebookEmail(): Observable<any>  {
    return this.facebookService.getEmail();
  }

  public googleLoginButton(googleButton: any) {
    return this.googleService.googleLoginButton(googleButton);
  }

  public googleLink(googleButton: any) {
    return this.googleService.googleLink(googleButton);
  }

  public socialLoginInit(callback: Function): void {
    this.facebookService.initFacebook();
    this.googleService.initGoogle(callback);
  }

  public facebookLink(): Observable<any> {
    return this.facebookService.facebookLogin();
  }

  public linkedinLogin() {
    return this.linkedinService.linkedinLogin();
  }

  public enableLink(socialType: string, socialId: string): Observable<any> {
    return this.httpClient.post(
      LINK_SOCIAL_LOGIN(),
      { socialType: socialType, socialId: socialId },
      { headers: getHeaders() }
    );
  }

  public disableLink(socialType: string) {
    return this.httpClient.post(UNLINK_SOCIAL_LOGIN(), { socialType: socialType }, { headers: getHeaders() });
  }

  public socialLogin(socialType: string, socialId: string) {
    return this.httpClient.post(SOCIAL_LOGIN(), this.getSocialLoginObject(socialType, socialId), {
      headers: getHeaders(true),
    });
  }

  public checkSocialLogin(socialType: string, socialId: string, socialEmail: string): Observable<CheckSocialLogin> {
    return this.httpClient.post<CheckSocialLogin>(
      CHECK_SOCIAL_LOGIN(),
      { socialType, socialId, socialEmail },
      { headers: getHeaders(true) }
    );
  }

  private getSocialLoginObject(socialType: string, socialId: string): SocialLogin {
    return new SocialLogin(
      environment.CLIENT_ID,
      environment.CLIENT_SECRET,
      environment.GRANT_TYPE_PASSWORD,
      socialId,
      socialType,
      ''
    );
  }
}
