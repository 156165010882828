<app-header></app-header>
<main class="container margin-bottom-70">
    <div class="row justify-content-md-center">
        <div class="col">

            <div class="white-box margin-top-inverse-85 form" *ngIf="this.languages | async as langs else loadingComponent">
                <h1 class="margin-bottom-30">
                    {{ 'Create a Customized View' | translate }}
                </h1>

                <flash-messages></flash-messages>

                <form [formGroup]="form" (ngSubmit)="submitForm()">
                    <mat-dialog-content>
                        <p>
                            {{
                                'You can share a link to your diploma but still limiting access to certain data in the diploma by creating a customized view. You may protect the view with a password and you may restrict the period that the view shall be active.' | translate
                            }}
                        </p>
                        <mat-form-field class="full-width">

                            <input matInput required type="text" placeholder="{{ 'Link Name' | translate }}" title="{{'Insert a name to identify this view e.g. “Job Application” or “View without grades”' | translate
             }}" formControlName="linkName">
                            <mat-error *ngIf="hasError('linkName','required')">
                                {{ 'View name is required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="hasError('linkName','uniqueName')">
                                {{ 'Name should be unique.' | translate }}
                            </mat-error>
                            <mat-error *ngIf="hasError('linkName','pattern')">
                                {{ 'Allowed letters and numbers. Minimum length 3. Maximum length 20.' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput type="password" placeholder="{{ 'Password' | translate }}"
                                   formControlName="password" (change)="checkPasswords()" (blur)="checkPasswords()"
                                   title="{{'You may protect the link with a password. If the password is set only user knowing the password may get access using the link.' | translate }}">
                            <mat-error *ngIf="hasError('password','pattern')">
                                {{ 'Allowed letters and numbers. Minimum length 3. Maximum length 20.' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput type="password" placeholder="{{ 'Repeat Password' | translate }}"
                                   formControlName="repeatPassword" value="" (change)="checkPasswords()"
                                   (blur)="checkPasswords()">
                            <mat-error
                                    *ngIf="hasError('repeatPassword', 'noMatch')">
                                {{ 'Passwords don\'t match' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'Validity' }}</mat-label>
                            <mat-select required formControlName="validity"
                                        title="{{'Choose how long the view shall be active. When the view is no longer active, it does no longer give access to your diploma.' | translate}}">
                                <mat-option value="24">{{ 'One day' | translate }}</mat-option>
                                <mat-option value="168">{{ 'One week' | translate }}</mat-option>
                                <mat-option value="720">{{ 'One month' | translate }}</mat-option>
                                <mat-option value="8760">{{ 'One year' | translate }}</mat-option>
                                <mat-option value="0">{{ 'Until disabled' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'Language' }}</mat-label>
                            <mat-select required formControlName="language" [(ngModel)]="this.selectedLanguage" (selectionChange)="this.languageSelected($event)">
                                <mat-option *ngFor="let lang of langs" [value]="lang.id">{{ lang.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <h1 mat-dialog-title class="mb-3 green-color" *ngIf="this.fields.length > 0">
                            {{ 'Choose fields to be visible in the view' | translate }}</h1>

                        <div class="font-weight-bold text-right" *ngIf="this.fields.length > 0">
                            {{ 'Toggle all' | translate }}
                            <ui-switch size="small" (change)="toggleAll($event)" [checked]="this.allChecked"
                                       defaultBgColor="red" class="align-middle toggle-all-switch">
                            </ui-switch>
                        </div>

                        <table class="fields-table">
                            <tr *ngFor="let field of fields">
                                <ng-container *ngIf="field.type !== 'table'">
                                    <td style="width: 100%" class="d-flex justify-content-between">
                                      {{ field['nameTranslated'] }}:
                                        {{
                                            isChecked({
                                                fieldId: field.id,
                                                type: field.type
                                            })
                                                    ? field['value']['visible']
                                                    : field['value']['hidden']
                                        }}
                                        <ui-switch size="small"
                                                   [checked]="!!isChecked({
                                                   fieldId: field.id,
                                                   type: field.type
                                                   })"
                                                   (change)="change({
                                                   value :$event,
                                                   data: {fieldId: field.id, type: field.type, value: field.id}
                                                   })"
                                                   defaultBgColor="red" class="align-middle">
                                        </ui-switch>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="field.type === this.diplomaFieldType.table">

                                    <table class="table">
                                        <thead *ngIf="field.settings.settings.visible">
                                        <tr>
                                            <th scope="col" *ngFor="let col of field.settings.cols">
                                                {{ col['visible'] ? col['colName'] : '' }}
                                            </th>

                                            <th><div class="float-right">{{ 'Visible' | translate}}</div></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let row of field.value | keyvalue: fieldsOrder">

                                            <ng-container *ngIf="isChecked({
                                                           fieldId: field.id,
                                                           type: field.type,
                                                           index: row.key
                                                           }); else hiddenRow">
                                                <td *ngFor="let colData of row['value']['visible']" [innerHtml]="colData"></td>
                                            </ng-container>

                                            <ng-template #hiddenRow>
                                                <td *ngFor="let colData of row['value']['hidden']" [innerHtml]="colData"></td>
                                            </ng-template>

                                            <td class='actionButton'>
                                                <ui-switch size="small"
                                                           [checked]="!!isChecked({
                                                           fieldId: field.id,
                                                           type: field.type,
                                                           index: row.key
                                                           })"
                                                           (change)="change({
                                                           value :$event,
                                                           data: {
                                                           fieldId: field.id,
                                                           index: row.key,
                                                           type: field.type}
                                                           })"
                                                           defaultBgColor="red" class="float-right">
                                                </ui-switch>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </tr>
                        </table>
                    </mat-dialog-content>
                    <mat-dialog-actions>
                        <div class="button-action">
                            <button mat-raised-button type="button" class="cancel" (click)="cancel()">
                                {{ 'Cancel' | translate }}
                            </button>
                            <button mat-raised-button class="submit" type="submit"
                                    [disabled]="!this.form.valid || this.disableSubmit">{{ 'Create' | translate }}
                            </button>
                        </div>
                    </mat-dialog-actions>
                </form>

            </div>
        </div>
    </div>
</main>

<ng-template #loadingComponent>
    <div class="loading">
        <app-loading></app-loading>
    </div>
</ng-template>
<app-footer></app-footer>
