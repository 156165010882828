import { ErrorHandler, Injectable } from '@angular/core';
import * as Raven from 'raven-js';

import { environment } from '../../../../environments/environment';

Raven
  .config(environment.SENTRY_DSN)
  .install();

export class RavenErrorHandler implements ErrorHandler {
  public handleError(err: any): void {
    Raven.captureException(err);
  }
}
