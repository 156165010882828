<div style="white-space: pre; word-break: break-word; overflow-wrap: break-word; text-wrap: wrap;">
  {{ claimFlowEmailPromptDescription }}
</div>
<div class="inputs">
  <mat-error *ngIf="emailFormControl.hasError('pattern')">
    {{ 'Please enter a valid email address' | translate }}
  </mat-error>
  <mat-error *ngIf="emailFormControl.hasError('required')">
    {{ 'Email is required' | translate }}
  </mat-error>
  <mat-error *ngIf="data.email !== data.reEmail">
    {{ 'Email not matching' | translate }}
  </mat-error>

  <div class="form-group input-group">
    <input
      autocomplete="off"
      [formControl]="emailFormControl"
      class="form-control"
      placeholder="{{ 'New email' | translate }}"
      type="email"
      [(ngModel)]="data.email"
    />
  </div>
  <div class="form-group input-group">
    <input
      autocomplete="off"
      [formControl]="emailFormControl"
      class="form-control"
      placeholder="{{ 'Retype new email' | translate }}"
      type="email"
      [(ngModel)]="data.reEmail"
    />
  </div>
</div>

<button type="button" class="btn btn-primary claim-button" (click)="claimRegister()">
  {{ 'Continue' | translate }}
</button>
