import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SeoAndMetaTagsInterface } from '../../core/types/interfaces/seo/seo-and-meta-tags.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DiplomaService } from '../../core/services/diploma/diploma.service';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class DiplomaMetaDataResolver implements Resolve<SeoAndMetaTagsInterface> {
  // state: RouterStateSnapshot

  public constructor(private diplomaService: DiplomaService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<SeoAndMetaTagsInterface> {
    return this.diplomaService.getMetaData(route.params.lang, route.params.uh).pipe(
      switchMap((diploma) => {

        return of({
          title: (diploma.diploma_name.length ? diploma.diploma_name : environment.SEO_TITLE),
          description: (diploma.diploma_description_plain.length ? diploma.diploma_description_plain : environment.SEO_DESCRIPTION),
          image: diploma.diploma_image.length ? diploma.diploma_image : window.location.origin + '/assets/img/logo_with_text_large.png',
          robotsNoIndex: diploma.diploma_status === 1 ? false : true
        } as SeoAndMetaTagsInterface);
      })
    );
  }

}
