import {Component, Input, OnInit} from '@angular/core';
import {DiplomaData} from "../../../core/models/diploma";

@Component({
  selector: 'app-verified-modal',
  templateUrl: './verified-modal.component.html',
  styleUrls: ['./verified-modal.component.css'],
})
export class VerifiedModalComponent implements OnInit {

  @Input() public data: DiplomaData;
  @Input() public language: string;
  @Input() public dismiss: any;

  constructor() { }

  ngOnInit() {
  }
}
