import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import getHeaders from 'app/core/helpers/http/http-headers';
import { DiplomaPresentationData } from 'app/core/models/diploma';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

const DIRECT_SHARE = (langCode: string, uhash: string): string => `${environment.API_START_URL}api/${langCode}/${uhash}/direct-share/linkedin`;
const GET_ACCESS_TOKEN = (): string => `${environment.API_START_URL}api/linkedin-access-token`;
const CHECK_ACCESS_TOKEN = (): string => `${environment.API_START_URL}api/check-access-token/linkedin`;

@Injectable({
  providedIn: 'root',
})
export class LinkedinService {
  private redirectToLogin = (): string => `${environment.LINKEDIN_REDIRECT_URL}/login/linkedin`;
  private linkedinRequest = (redirectUrl: string): string => `${environment.LINKEDIN_URL}?response_type=code&client_id=${environment.LINKEDIN_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=r_liteprofile,r_emailaddress,w_member_social`;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {}

  public linkedinLogin() {
    window.location.href = this.linkedinRequest(this.redirectToLogin());
  }

  public checkAccessToken() {
    return this.httpClient.get(
      CHECK_ACCESS_TOKEN(),
      { headers: getHeaders(true), withCredentials: true }
    );
  }

  public getAccessToken() {
    return new Promise<void>((resolve) => {
      const win = window.open(this.linkedinRequest(this.redirectToLogin()));
      win.sessionStorage.setItem('directShareFlow', 'true');

      const timer = setInterval(() => {
        if (win.closed) {
          clearInterval(timer);
          resolve();
        }
      }, 500);
    });
  }

  public getLinkedinUserData(code: string): Observable<any> {
    return this.httpClient.post(
      GET_ACCESS_TOKEN(),
      { code: code },
      { headers: getHeaders(true), withCredentials: true }
    );
  }

  public share(link: string, name: string, description: string): void {
    // see https://developer.linkedin.com/docs/share-on-linkedin
    const shareUrl =
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
      encodeURIComponent(link) +
      '&title=' +
      encodeURIComponent(name.substring(0, 190)) +
      '&summary=' +
      encodeURIComponent(description.substring(0, 246));

    window.open(shareUrl + '&source=Diplomasafe', 'sharer', 'toolbar = no, status = 0, width = 548, height = 325');
  }

  public directShare(userLinkedInId: string, link: string, diploma: DiplomaPresentationData): Observable<any> {
    return this.httpClient.post(
      DIRECT_SHARE(this.cookieService.get('langVal') || 'en-US', diploma.diploma_hash),
      {
        userLinkedInId,
        shareTitle: diploma.diploma_name,
        shareText: diploma.diploma_share_text,
        link,
      },
      {
        headers: getHeaders(),
        withCredentials: true,
      }
    );
  }
}
