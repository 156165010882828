import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable, throwError } from "rxjs";

import { ResetPassword } from "../../../core/models/reset-password";

import { environment } from "../../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class ResetPasswordService {
  private apiUrl = environment.API_START_URL + "api/checkResetUrl";
  private apiResetUrl = environment.API_START_URL + "api/resetPassword";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public checkResetUrl(token, email): Observable<ResetPassword[] | {}> {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .get(this.apiUrl + "/" + token + "/" + email, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public onResetPassword(body: any, lang): Observable<ResetPassword[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .post(this.apiResetUrl, body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
