import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DiplomaRequestChangeService } from 'app/core/services/diploma-request-change/diploma-request-change.service';
import { DiplomaService } from 'app/core/services/diploma/diploma.service';
import { DiplomaRequestChangeTypeEnum } from 'app/core/types';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wrong-name-request',
  templateUrl: './wrong-name-request.component.html',
  styleUrls: ['./wrong-name-request.component.css'],
})
export class WrongNameRequestComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public params = this.activatedRoute.snapshot.params;

  public diploma!: any;
  public errorMessages: any = {};

  private ngUnsubscribe$: Subject<void> = new Subject();
  private accessToken!: string;
  private langVal!: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private diplomaService: DiplomaService,
    private diplomaRequestChangeService: DiplomaRequestChangeService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit(): void {
    this.accessToken = this.userDetails.accessToken;

    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }

    this.diplomaService
      .getDiplomaRequestChangeData(this.params.uhash, DiplomaRequestChangeTypeEnum.CHANGE_NAME, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.diploma = res;

          this.buildForm(res);
        },
        (err) => {
          this.router.navigate([this.params.lang + '/not-found'], {
            state: { errorMessage: 'Could not get diploma', isDiploma: true },
          });
        }
      );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private buildForm(diploma) {
    this.form = this.fb.group({
      currentName: new FormControl({ value: diploma.currentRecipientName ?? '', disabled: true }),
      correctName: new FormControl(diploma.requestValue ?? '', { validators: Validators.required }),
    });
  }

  back(): void {
    this.location.back();
  }

  public saveRequestChange() {
    const formData = this.form.getRawValue();

    const preparedFormData = {
      correct_name: formData.correctName,
      diploma_uhash: this.diploma.diplomaUhash,
      change_request_type: DiplomaRequestChangeTypeEnum.CHANGE_NAME,
    };

    if (this.diploma.requestUhash) {
      this.updateRequest(preparedFormData, this.langVal);
      return;
    }

    this.createNewRequest(preparedFormData, this.langVal);
  }

  private createNewRequest(preparedFormData: any, lang: string) {
    this.diplomaRequestChangeService
      .createDiplomaRequestChange(preparedFormData, this.params.lang, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.router.navigate([lang + '/successfullRequest/' + res.diploma_request_change_uhash]);
        },
        (err) => {
          if (err.error.errors) {
            this.flashMessagesService.show(err.error.errors[0], {
              cssClass: 'alert-danger',
              timeout: 5000,
            });
          }

          this.flashMessagesService.show(err.error.message, {
            cssClass: 'alert-danger',
            timeout: 5000,
          });
        }
      );
  }

  private updateRequest(preparedFormData: any, lang: string) {
    this.diplomaRequestChangeService
      .updateDiplomaRequestChange(this.diploma.requestUhash, preparedFormData, lang, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.router.navigate([lang + '/successfullRequest/' + res.diploma_request_change_uhash]);
        },
        (err) => {
          if (err.error.errors) {
            this.flashMessagesService.show(err.error.errors[0], {
              cssClass: 'alert-danger',
              timeout: 5000,
            });
          }

          this.flashMessagesService.show(err.error.message, {
            cssClass: 'alert-danger',
            timeout: 5000,
          });
        }
      );
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
