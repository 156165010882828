import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

import getHeaders from '../../helpers/http/http-headers';

const apiUrl = environment.API_START_URL;

const GET_DIPLOMA_GOALS_FOR_SETTINGS = (diplomaHash: string, language: string): string =>
  `${apiUrl}api/${language}/diploma/${diplomaHash}/goal-settings`;
const GET_DIPLOMA_GOALS_FOR_WIDGET = (diplomaHash: string, language: string): string =>
  `${apiUrl}api/${language}/diploma/${diplomaHash}/goal-widget`;
const SAVE_DIPLOMA_GOALS = (diplomaHash: string): string => `${apiUrl}api/diploma/${diplomaHash}/goal-settings`;

@Injectable()
export class PathwayService {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {}

  public fetchDiplomPathwaysForSettings(diplomaHash: string): Observable<Object> {
    return this.httpClient.get(GET_DIPLOMA_GOALS_FOR_SETTINGS(diplomaHash, this.cookieService.get('langVal')), {
      headers: getHeaders(),
    });
  }

  public fetchDiplomaPathwaysForWidget(diplomaHash: string): Observable<Object> {
    return this.httpClient.get(GET_DIPLOMA_GOALS_FOR_WIDGET(diplomaHash, this.cookieService.get('langVal')), {
      headers: getHeaders(),
    });
  }

  public saveSettings(diplomaHash: string, data: object): Observable<any> {
    return this.httpClient.post(SAVE_DIPLOMA_GOALS(diplomaHash), data, { headers: getHeaders() });
  }

  public handleError(lang: string, errorMessage?: string, isDiploma?: boolean): void {
    this.router.navigate([lang + '/not-found'], { state: { errorMessage: errorMessage, isDiploma: isDiploma } });
  }

  public redirectToPathwayPage(diplomaHash: string) {
    this.ngZone.run(() => {
      this.router.navigate([this.cookieService.get('langVal') + `/pathways/diploma/${diplomaHash}`]);
    });
  }

  public redirectToDiploma(diplomaHash: string) {
    this.ngZone.run(() => {
      this.router.navigate([this.cookieService.get('langVal') + `/diploma/${diplomaHash}`]);
    });
  }
}
