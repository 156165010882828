import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ShareViewService } from '../../../core/services/share-view/share-view.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, Subscription, timer } from 'rxjs';
import { mergeMap, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.css']
})
export class ShareLinkDialogComponent implements OnInit, OnDestroy {

  public isShareViewLoaded = false;
  private observableRef: Subscription;

  constructor(
    public shareViewService: ShareViewService,
    @Inject(MAT_DIALOG_DATA) public data: { linkResult: string, shareViewId: number }
  ) {
  }

  ngOnInit() {

    // check is  share view loaded every 10 seconds
    this.observableRef = timer(3000, 5000).pipe(
      takeWhile(() => this.isShareViewLoaded === false),
      mergeMap(() => this.shareViewService.checkLoadingStatus(this.data.shareViewId))
    ).subscribe((response) => {
      this.isShareViewLoaded = response.share_view_loaded;
    });

    // if for 5 min share view is not loaded just display link
    setTimeout(() => {
      this.isShareViewLoaded = true;
    }, 300000);
  }

  ngOnDestroy(): void {
    this.observableRef.unsubscribe();
  }

}
