<div class="modal-header">
  <h2 class="modal-title">
    {{ 'Keep after expiry' | translate }}
  </h2>
  <button type="button" class="close" aria-label="Close" (click)="changeAutoDeleteExpiredStatusEvent(true)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="data?.diploma_expired; else privateExpriredPart">
    <h5>
      {{ 'Warning: If you continue, your /template_type/ will be deleted.' | translate:{ template_type: data?.diploma_template_type } }}
    </h5>
  </div>
  <ng-template #privateExpriredPart>
    <h5>
      {{ 'Warning: If you continue your /template_type/ will be deleted /grace_period_expire/ days after expiry.' | translate:{ template_type: data?.diploma_template_type, grace_period_expire: data?.diploma_grace_period_expired } }}
    </h5>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="changeAutoDeleteExpiredStatusEvent(true)">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="changeAutoDeleteExpiredStatusEvent(false)">Continue</button>
</div>