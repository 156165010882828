import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DiplomaService } from '../../../../core/services/diploma/diploma.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { DiplomaRequestChangeTypeEnum } from '../../../../core/types';
import { FlashMessagesService } from 'angular2-flash-messages';
import {
  DiplomaRequestChangeService
} from '../../../../core/services/diploma-request-change/diploma-request-change.service';

@Component({
  selector: 'app-init-diploma-transfer',
  templateUrl: './init-diploma-transfer.component.html',
  styleUrls: ['./init-diploma-transfer.component.css']
})
export class InitDiplomaTransferComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public params = this.activatedRoute.snapshot.params;
  public data!: any;

  private accessToken!: string;
  private langVal!: string;

  private ngUnsubscribe$: Subject<void> = new Subject();

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private flashMessagesService: FlashMessagesService,
              private diplomaService: DiplomaService,
              private diplomaRequestChangeService: DiplomaRequestChangeService
  ) {
  }

  ngOnInit(): void {
    this.accessToken = this.userDetails.accessToken;

    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }

    this.buildForm();

    this.diplomaService.getDiplomaStatus(this.langVal, this.params.uhash, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (data) => {
          this.data = data;

          if (this.data.has_active_transfer_request) {
            this.router.navigate([this.langVal + '/editDiplomaStatus/' + this.params.uhash + '/' + this.data.diploma_nice_name]);
          }

        }
      );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private buildForm() {
    this.form = this.fb.group({
      name: new FormControl('', { validators: Validators.required }),
      email: new FormControl('', { validators: Validators.required }),
      email_confirmation: new FormControl('', { validators: Validators.required })
    });
  }

  public saveRequestChange() {

    this.clearFormErrors();

    const formData = this.form.getRawValue();

    const preparedFormData = {
      name: formData.name,
      email: formData.email,
      email_confirmation: formData.email_confirmation,
      diploma_uhash: this.params.uhash,
      diploma_request_type: DiplomaRequestChangeTypeEnum.USER_DIPLOMA_TRANSFER
    };


    this.diplomaRequestChangeService
      .createDiplomaTransferRequest(preparedFormData, this.params.lang, this.accessToken)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          const navigationExtras: NavigationExtras = {state: {data: res.message}};
          this.router.navigate([this.langVal + '/editDiplomaStatus/' + this.params.uhash + '/' + this.data.diploma_nice_name], navigationExtras);

        },
        (err) => {

          if (err.status === 422) {
            this.setValidationErrors(err.error.errors);
          }

          if (err.status !== 422) {
            this.flashMessagesService.show(err.error.message, {
              cssClass: 'alert-danger',
              timeout: 5000
            });
          }
        }
      );
  }

// TODO: create  validation helper
  public setValidationErrors(validationErrors: any[]) {
    Object.keys(validationErrors).forEach(prop => {
      const formControl = this.form.get(prop);
      if (formControl) {
        // activate the error message
        formControl.setErrors({
          serverError: validationErrors[prop]
        });
      }
    });
  }

  public clearFormErrors() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setErrors(null);
    });

  }

  back(): void {
    this.location.back();
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

}
