import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ShareOption} from '../../models/ShareOption';

@Injectable({
  providedIn: 'root',
})
export class DiplomaShareService {

  private getImagesUrl = environment.API_START_URL + 'api/getImages';
  private changeShareMethodUrl = environment.API_START_URL + 'api/shareMethod';

  private diplomaImagesSubject: Subject<ShareOption[]>;
  private subscriptions: Subscription[];
  constructor(private http: HttpClient) {
    this.subscriptions = [];
    this.diplomaImagesSubject = new Subject<ShareOption[]>();
  }

  public getDiplomaShareImages(lang, hash) {
    const subscription$ = this.http.get<ShareOption[]>(this.getImagesUrl + '/' + lang + '/' + hash, {
      headers: this.getHeaders(this.userDetails.accessToken),
    }).subscribe((result) => {
      this.subscriptions.push(subscription$);
      this.diplomaImagesSubject.next(result);
    });
  }

  public selectShareMethod(hash, value) {
    return this.http.post(this.changeShareMethodUrl, {
        hash,
        value,
      }, {
        headers: this.getHeaders(this.userDetails.accessToken),
      });
  }

  public destroySubscriptions() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  public diplomaImages(): Observable<ShareOption[]> {
    return this.diplomaImagesSubject.asObservable();
  }

  public pushSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  private getHeaders(accessToken): HttpHeaders {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });

    return headers;
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
