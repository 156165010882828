import { HttpHeaders } from '@angular/common/http';
import userAccessToken from '../user/access-token';

export default function getHeaders(withoutToken: boolean = false): HttpHeaders {
  let headers = {
    'Content-Type': 'application/json',
  };

  if (!withoutToken) {
    headers = {
      ...headers,
      ...{ Authorization: `Bearer ${userAccessToken()}` },
    };
  }

  return new HttpHeaders(headers);
}
