import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

@Injectable({
  providedIn: 'root',
})
export class SwalService {
  constructor() {}

  public initGeneralFailure(): void {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      width: '480px',
      buttonsStyling: false,
      confirmButtonColor: '#0d1e5b',
      customClass: {
        icon: 'sa-icon',
        title: 'sweet-alert-title',
        content: 'sweet-alert-content',
        confirmButton: 'btn btn-lg btn-primary',
      },
    });
  }

  public initSuccess(title: string): void {
    return Swal.fire({
      timer: 3000,
      title: title,
      width: '480px',
      type: 'success',
      icon: 'success',
      showCancelButton: false,
      showConfirmButton: false,
      customClass: {
        icon: 'sa-icon',
        title: 'sweet-alert-title',
      },
    });
  }

  public initSuccessToast(title: string): void {
    return Swal.fire({
      icon: 'success',
      title: title,
      position: 'top-end',
      showConfirmButton: false,
      width: '300px',
      background: '#d6edc7',
      timer: 3000,
      toast: true,
    });
  }

  public initFailToast(title: string): void {
    return Swal.fire({
      icon: 'error',
      title: title,
      position: 'top-end',
      showConfirmButton: false,
      width: '300px',
      background: '#f2d7d3',
      timer: 3000,
      toast: true,
    });
  }
}
