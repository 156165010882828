import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiplomaDesign } from 'app/core/models/diploma';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.css'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() public diplomaDesign: DiplomaDesign;
  @Input() public customSuportLink: string = '';

  private params: any = this.activatedRoute.snapshot.params;

  constructor(
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() { }

  get language() {
    if (this.cookieService.get('langVal')) {
      return this.cookieService.get('langVal');
    } else {
      return this.params.lang;
    }
  }
}
