<div class="modal-header">
  <h2 class="modal-title">
    <i class="fa fa-linkedin-square" aria-hidden="true"></i>
    {{ 'Add to LinkedIn profile' | translate }}
  </h2>
  <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert" role="alert">
    {{ 'You can add your diploma to the certifications section of your LinkedIn profile.' | translate }}
    {{ 'This makes it easy for people viewing your profile to find and verify the documentation of your obtained skills.' | translate }}
  </div>
  <div class="form-group">
    <label>{{ 'Certification name' | translate }}</label>
    <div class="row">
      <div class="col-9">
        <div class="form-control">
          {{ data?.diploma_name }}
        </div>
      </div>
      <div class="col-3">
        <a href="javascript:void(0)" (click)="this.diplomaService.copyToClipboard(data?.diploma_name, this.successful, this.unsuccessful)">
          <div class="button">
            <i class="fa fa-clipboard" aria-hidden="true"></i>
            {{ 'copy text' | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>{{ 'Certification authority' | translate }}</label>
    <div class="row">
      <div class="col-9">
        <div class="form-control">
          {{ data?.organization_name }}
        </div>
      </div>
      <div class="col-3">
        <a href="javascript:void(0)" (click)="this.diplomaService.copyToClipboard(data?.organization_name, this.successful, this.unsuccessful)">
          <div class="button">
            <i class="fa fa-clipboard" aria-hidden="true"></i>
            {{ 'copy text' | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>{{ 'License number' | translate }}</label>
    <div class="row">
      <div class="col-9">
        <div class="form-control disabled">
          {{ 'This field can be left blank' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-6">
        <label>{{ 'From' | translate }}</label>
        <div class="form-control margin-bottom-5">{{ data?.issue_date | amLocale:language | amDateFormat:'MMMM' }}</div>
        <div class="form-control">{{ data?.issue_date | amLocale:language | amDateFormat:'YYYY' }}</div>
      </div>
      <div class="col-6">
        <label>{{ 'To' | translate }}</label>
        <div class="form-control margin-bottom-5">{{ (data?.expiry_date) ? (data?.expiry_date | amLocale:language | amDateFormat:'MMMM') : 'Month' }}</div>
        <div class="form-control">{{ (data?.expiry_date) ? (data?.expiry_date | amLocale:language | amDateFormat:'YYYY') : 'Year' }}</div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>
      <input type="checkbox" *ngIf="data?.expiry_date">
      <input type="checkbox" checked *ngIf="!data?.expiry_date">
      {{ 'This certification does not expire' | translate }}
    </label>
  </div>
  <div class="form-group">
    <label>{{ 'Certification URL' | translate }}</label>
    <div class="row">
      <div class="col-9">
        <div class="form-control">
          {{ currentURL }}/linkedin_cert
        </div>
      </div>
      <div class="col-3">
        <a href="javascript:void(0)" (click)="this.diplomaService.copyToClipboard(currentURL + '/linkedin_cert', this.successful, this.unsuccessful)">
          <div class="button">
            <i class="fa fa-clipboard" aria-hidden="true"></i>
            {{ 'copy text' | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="cancel('Close click')">{{ 'Close' | translate }}</button>
  <a class="btn" href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME" target="_blank">
    <i class="fa fa-linkedin-square" aria-hidden="true"></i>
    {{ 'Get Started' | translate }}
  </a>
</div>