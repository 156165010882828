<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 form">
        <h1 class="margin-bottom-30">{{ 'Change Password' | translate }}:</h1>
        <flash-messages></flash-messages>
        <form (ngSubmit)="changePassword(changePasswordForm)" #changePasswordForm="ngForm" autocomplete="off">
          <mat-form-field>
            <input matInput type="password" name="oldPassword" placeholder="{{ 'Type old password' | translate }}" [(ngModel)]="upass.oldPassword" [formControl]="oldPasswordFormControl">
          </mat-form-field>
          <mat-form-field>
            <input matInput type="password" name="newPassword" placeholder="{{ 'Type new password' | translate }}" [(ngModel)]="upass.newPassword" [formControl]="newPasswordFormControl">
          </mat-form-field>
          <mat-form-field>
            <input matInput type="password" name="confirmPassword" placeholder="{{ 'Type confirm password' | translate }}" [(ngModel)]="upass.confirmPassword" [formControl]="confirmPasswordFormControl">
          </mat-form-field>
          <div class="change-password button-action">
            <button mat-raised-button type="submit" class="submit pull-left">
              {{ 'Change Password' | translate }}
            </button>
            <div class="pull-left" *ngIf="!data?.error && data; else cancelBtn">
              <button class="reset" type="button" (click)="reset()" >
                {{ 'Back to overview' | translate }}
              </button>
            </div>
            <ng-template class="pull-left" #cancelBtn>
              <button class="reset" type="reset" (click)="reset()" >
                {{ 'Cancel Changes' | translate }}
              </button>
            </ng-template>
          </div>
          <div class="reset-div"></div>
        </form>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
