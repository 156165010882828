export { DiplomaField, DiplomaFieldTableSettings, DiplomaFieldTableColumn, DiplomaFieldType }

interface DiplomaField {
    id: number;
    name: string;
    value: string;
    type: DiplomaFieldType;
    weight: number;
    special: boolean;
    settings: DiplomaFieldTableSettings
  }

  interface DiplomaFieldTableSettings {
      cols: DiplomaFieldTableColumn[];
      settings: object
  }

  interface DiplomaFieldTableColumn {
      colName: string,
      visible: true,
      alignment: string,
      specialShare: boolean,
      replacementText: string,
  }

  enum DiplomaFieldType {
      text = 'text',
      table = 'table',
  }