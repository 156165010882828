import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ShareView } from "../../../core/models/ShareView";
import { ShareViewService } from "../../../core/services/share-view/share-view.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  providers: [ShareViewService],
  selector: "app-share-view-list",
  templateUrl: "./share-view-list.component.html",
  styleUrls: ["./share-view-list.component.css"],
})
export class ShareViewListComponent implements OnInit, OnDestroy {
  public shareViews: Observable<ShareView[]>;
  private params = this.activatedRoute.snapshot.params;

  constructor(
    private shareViewService: ShareViewService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.shareViewService.getShareViews(this.params.lang);
    this.shareViews = this.shareViewService.getViews();
  }

  public showView(uniqueHash: string) {
    this.router.navigate([this.params.lang + "/share/" + uniqueHash + "/specialShare"]);
  }

  public deleteView(uniqueHash) {
    this.shareViewService.deleteView(uniqueHash, this.params.lang);
  }

  public ngOnDestroy(): void {
    this.shareViewService.destroySubscriptions();
  }

  public convertToLocalDate(date) {
    return new Date(date);
  }

  public unblock(uHash: string) {
    this.shareViewService.unblock(uHash, this.params.lang);
  }
}
