import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable, throwError } from "rxjs";

import { environment } from "../../../../environments/environment";

import { TrackLinks } from "./tracklinks";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "app/core/services/login/login.service";

@Injectable({
  providedIn: "root",
})
export class TrackLinksService {
  private apiUrl = environment.API_START_URL + "api/trackOutgoingLinks";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public trackOutgoingLinks(body): Observable<TrackLinks[] | {}> {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .post(this.apiUrl, body, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
