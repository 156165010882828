<div class="modal-header">
    <div class="organization_image">
        <img [src]="organizationImage" alt="">
    </div>
    <h2 class="modal-title">
      {{ diplomaName }}
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="dismiss('Close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="description">
    </p>
    <a href="{{ templateLink }}" class="link text-semibold" *ngIf="templateLink" target="_blank" rel="noopener noreferrer" (click)="trackLink(templateLink, data?.template_id)">
        {{ 'Read more' | translate }}&nbsp;
        <i class="fa fa-external-link" aria-hidden="true"></i>
      </a>
  </div>