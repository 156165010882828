import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { LanguageUtilService } from 'app/core/services/util/language-util.service';

@Component({
  selector: 'app-no-storage',
  styleUrls: ['./no-storage.component.css'],
  templateUrl: './no-storage.component.html'
})
export class NoStorageComponent implements OnInit, OnDestroy {
  private params: any = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private languageUtilService: LanguageUtilService
  ) {
  }

  public ngOnInit(): void {
    const langVal = this.params.lang || environment.DEFAULT_LANGUAGE;

    this.languageUtilService.updateLanguage(langVal, false);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get language() {
    return this.cookieService.get('langVal') || this.params.lang;
  }
}
