import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UnsubscribeService } from "../../core/services/unsubscribe/unsubscribe.service";

import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";

@Component({
  providers: [UnsubscribeService],
  selector: "app-unsubscribeconfirm",
  styleUrls: ["./unsubscribeconfirm.component.css"],
  templateUrl: "./unsubscribeconfirm.component.html",
})
export class UnsubscribeconfirmComponent implements OnInit {
  public data: any;
  public error: any;
  private errorMessage: string;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;

  constructor(
    private unsubscribeService: UnsubscribeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService
  ) {
    if (this.params.lang) {
      this.langVal = this.params.lang;
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
  }

  public ngOnInit() {
    this.checkConfirmDiploma(this.params.lang, this.params.uh, this.params.id);
  }

  public checkConfirmDiploma(lang, uh, id) {
    this.unsubscribeService.checkConfirmUnubscription(lang, uh, id).subscribe(
      (data) => this.initConfirmData(data),
      (error) => (this.errorMessage = error)
    );
  }

  public unsubscribeDiploma() {
    this.unsubscribeService.unsubscribeDiploma(this.params.lang, this.params.uh, this.params.id).subscribe(
      (data) => this.initUnsubscribeData(data),
      (error) => (this.errorMessage = error)
    );
  }
  public initConfirmData(data) {
    if (data.incorrect) {
      this.router.navigate([this.params.lang + "/login/"]);
    } else if (data.error) {
      this.router.navigate([this.params.lang + "/diploma-error"]);
    } else {
      this.data = data;
    }
  }

  public initUnsubscribeData(data) {
    if (data.incorrect) {
      this.router.navigate([this.params.lang + "/login"]);
    } else {
      this.router.navigate([this.params.lang + "/dashboard"]);
    }
  }

  get userDetails() {
    if (localStorage.getItem("currentUser")) {
      const userDetail = JSON.parse(localStorage.getItem("currentUser"));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get language() {
    return this.params.lang;
  }

  get getClaimedFrom() {
    if (localStorage.getItem("claimedFrom")) {
      return JSON.parse(localStorage.getItem("claimedFrom"));
    } else {
      return "";
    }
  }
}
