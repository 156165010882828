import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {CookieService} from 'ngx-cookie-service';
import {UnsubscribeService} from '../../core/services/unsubscribe/unsubscribe.service';

@Component({
  providers: [ UnsubscribeService ],
  selector: 'app-unsubscribe',
  styleUrls: ['./unsubscribe.component.css'],
  templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {

  public data: any;
  public error: any;
  private errorMessage: string;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;
  private urlFrom: any;

  constructor(
    private unsubscribeService: UnsubscribeService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private router: Router,
  ) {}

  public ngOnInit() {
    // remove unwanted localstorage items
    localStorage.removeItem('returnUrl');
    localStorage.removeItem('userHash');
    localStorage.removeItem('diplomaDetails');
    localStorage.removeItem('tempUser');
    this.checkDiplomaSubscription(this.params.uh, this.params.id);
  }

  public checkDiplomaSubscription(uh, id) {
    return this.unsubscribeService.checkDiplomaUnsubscription(this.params.lang, uh, id)
      .subscribe(
        (data) => this.initData(data),
        (error) =>  this.initError(error));
  }

  public initData(data) {
    if (data.error) {
      this.data = data;
    } else {
      if (this.cookieService.get('langVal')) {
        this.langVal = this.cookieService.get('langVal');
      } else {
        this.langVal = this.params.lang;
      }
      this.router.navigate([
       this.langVal + '/unsubscribe/confirm/' + data.diploma_hash + '/' + data.diploma_encr,
        ],
      );
    }
  }

  public initError(error) {
    this.errorMessage = error;
  }

}
