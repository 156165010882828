<div *ngIf="!this.isEditProfile" class="col-md-12 col-lg-9 dialog-2fa">
    <div class="bottom-space">
      <img *ngIf="this.orgainzationImage else diplomasafeImage" [src]="this.orgainzationImage" class="organization-logo-2fa"/>
      <ng-template #diplomasafeImage>
        <img src="../../../../assets/img/logo_with_text_large.png" alt="{{ 'Diplomasafe logo' | translate }}" class="organization-logo-2fa" />
      </ng-template>
    </div>
    <div class="bottom-space">
      {{ 'This /template_type/ requires 2-factor authentication. Please open your Google Authenticator app on your mobile device and enter the 6-digit code displayed in the app for Diplomasafe.' | translate:{template_type: this.templateType} }}
    </div>
    <form (ngSubmit)="verifyTwoFactorAuthenticationCode()" autocomplete="off">
      <div class="center">
        <mat-form-field appearance="outline" class="min-width">
          <mat-label>{{ '6-digit code' | translate }}</mat-label>
          <input matInput type="text" placeholder="{{ 'Enter code' | translate }}" [formControl]="secret" required>
          <mat-error *ngIf="secret.invalid">{{ getErrorMessage() | translate: { attempts: this.tries } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="center">
        <button mat-raised-button type="submit" class="btn-success button-width">
          {{ 'Continue' | translate }}
        </button>
      </div>
    </form>
</div>

<!-- MODAL -->
<ng-template id="accept" #modalTwoFactor let-c="close" let-d="dismiss">
  <h2 mat-dialog-title>
    {{ 'Two factor authentication' | translate }}
  </h2>
  <form (ngSubmit)="verifyTwoFactorAuthenticationCode()" autocomplete="off">
    <mat-dialog-content>
      <mat-form-field>
        <mat-label>{{ 'Enter code' | translate }}</mat-label>
        <input matInput type="text" placeholder="{{ '6-digit code' | translate }}" [formControl]="secret" required>
        <mat-error *ngIf="secret.invalid">{{ getErrorMessage() | translate: { attempts: this.tries } }}</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button type="button" class="btn-success" (click)="closeModal(true)">{{ 'Cancel' | translate }}</button>
      <button mat-raised-button type="submit" class="btn-success">
        {{ 'Verify' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<!-- !MODAL -->