<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div *ngIf="!res else loadedPage">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
              {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
          </p>
        </div>
      </div>
      <ng-template #loadedPage>
        <div *ngIf="!res?.error else elseError">
          <div class="white-box margin-top-inverse-85 form">
            <h1 class="margin-bottom-30">{{ 'Reset Password' | translate }}:</h1>
            <flash-messages></flash-messages>
            <mat-form-field class="example-full-width">
              <input matInput disabled value="{{ urlParams.email }}">
            </mat-form-field>
            <form (ngSubmit)="onResetPassword()">
              <mat-form-field>
                <input matInput type="password" name="newPassword" placeholder="{{ 'Type new password' | translate }}" [(ngModel)]="resetPassword.newPassword" [formControl]="passwordFormControl">
                <mat-error *ngIf="passwordFormControl.hasError('required')">
                  {{ 'New password is required' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="password" name="confirmPassword" placeholder="{{ 'Type confirm password' | translate }}" [(ngModel)]="resetPassword.confirmPassword" [formControl]="confirmPasswordFormControl">
                <mat-error *ngIf="confirmPasswordFormControl.hasError('required')">
                  {{ 'Confirm password is required' | translate }}
                </mat-error>
              </mat-form-field>
              <div class="button-action">
                <button mat-raised-button type="submit" class="submit full-width">
                  <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                  {{ 'Reset Password' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ng-template #elseError>
          <div class="white-box margin-top-inverse-85 error-page text-center">
            <h1 class="margin-bottom-30 red-color">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'Error' | translate }}
            </h1>
            <p class="margin-bottom-30">
              {{ res?.message }}
            </p>
            <a class="btn btn-success" routerLink="/">{{ 'Go Back to the Homepage' | translate }}</a>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</main>
<app-footer></app-footer>
