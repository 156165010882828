import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  isInMaintenanceMode() {
    return environment.MAINTENANCE_MODE ? true : false;
  }
}
