<div class='modal-header'>
  <h2 class='modal-title'>
    {{ 'Verified' | translate }}
    <br>
    <small>
      <!--      {{ 'Date' | translate }}: {{ data?.organization.verified_date | amLocale:language | amDateFormat:'LL' }}-->
      {{ 'ISSUER:' | translate }} {{ data?.organization.name }}
      <div *ngIf='data?.organization.vat'>
        {{ 'ISSUER REG. NO.:' | translate }} {{ data?.organization.vat }}
      </div>
    </small>
  </h2>
  <i class='fa fa-check' aria-hidden='true'
     *ngIf='data?.diploma.diploma_status==1 && !data?.diploma.expired; else notValid'></i>
  <ng-template #notValid>
    <i class='fa fa-exclamation' aria-hidden='true'></i>
  </ng-template>
</div>
<div class='modal-body'>
  <h4>{{ '/Diplomasafe/ has checked' | translate:{ Diplomasafe: 'Diplomasafe' } }}:</h4>
  <ul class='fa-ul'>
    <li>
      <i class='fa-li fa fa-check'></i>
      {{ 'The issuer is an existing organization (last organization verification /date/)' | translate:{ date: data?.organization.verified_date | amLocale:language | amDateFormat:'LL' } }}
    </li>
    <li>
      <i class='fa-li fa fa-check'></i>
      {{ 'This /template_type/ was issued by /issuer/' | translate:{
      template_type: data?.diploma.template_communication_term || data?.diploma.template_term,
      issuer: data?.organization.name
    } }}
    </li>
    <li>
      <i class='fa-li fa fa-check'></i>
      {{ 'This /template_type/ was issued to /receiver/' | translate:{
      template_type: data?.diploma.template_communication_term || data?.diploma.template_term,
      receiver: data?.recipient.recipient_name
    } }}
    </li>

    <li *ngIf='data?.diploma.diploma_status==1 && !data?.diploma.expired'>
      <i class='fa-li fa fa-check'></i>
      {{ 'This /template_type/ is valid' | translate:{ template_type: data?.diploma.template_communication_term || data?.diploma.template_term } }}
    </li>

    <li *ngIf='data?.diploma.diploma_status==1 && data?.diploma.expired'>
      <i class='fa-li fa fa-exclamation'></i>
      {{ 'This /template_type/ is expired' | translate:{ template_type: data?.diploma.template_communication_term || data?.diploma.template_term } }}
    </li>

  </ul>
  <a href='' class='link text-semibold hidden'>
    {{ 'Read more' | translate }}
    <i class='fa fa-external-link' aria-hidden='true'></i>
  </a>
</div>
<div class="modal-footer">
  <a class="link green-color text-semibold" data-dismiss="modal" (click)="dismiss('ok')"> {{ 'OK' | translate }}</a>
</div>
