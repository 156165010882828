export class Profile {
  constructor(
      public name: any,
      public isPublic: any,
      public email: any,
      public phone: any,
      public country: any,
      public description: any,
      public facebook: any,
      public linkedin: any,
      public googleplus: any,
      public twitter: any,
      // tslint:disable-next-line:variable-name
      public pi_validation: any,
      public enabled_2fa: any,
      public has_2fa_code: any,
  ) {}
}
