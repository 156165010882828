<app-header></app-header>
<div class="container">
  <div class="row flex-lg-row">
    <div class="pl-0 pr-0 middle-col">
      <div class="transfer-diploma-request mx-auto">
        <div class="info-header"></div>
        <div class="request-wrap-content shadow form">
          <h1 class="margin-bottom-30">{{ 'Init /template_type/ transfer' | translate: { template_type: data.diploma_template_type } }}</h1>
          <flash-messages></flash-messages>
          <form (submit)="saveRequestChange()">
            <mat-form-field>
              <input
                matInput
                type="text"
                name="name"
                placeholder="{{ 'Name' | translate }}"
                [formControl]="form.get('name')"
              />

            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="text"
                name="email"
                placeholder="{{ 'Email' | translate }}"
                [formControl]="form.get('email')"
              />

              <mat-error *ngIf="form.get('email').errors?.serverError">
                {{ form.get('email').errors?.serverError }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="text"
                name="email_confirmation"
                placeholder="{{ 'Confirm email' | translate }}"
                [formControl]="form.get('email_confirmation')"
              />
              <!--                [formControl]="form.get('correctName')"-->
            </mat-form-field>

            <div class="button-action">
              <button mat-raised-button type="submit" class="submit pull-left">
                {{ 'OK' | translate }}
              </button>
              <div class="pull-left">
                <button class="cancelButton" (click)="back()">
                  {{ 'Cancel' | translate }}
                </button>
              </div>
              <div class="reset-div"></div>
            </div>

          </form>
        </div>
      </div>
      <app-design-view-footer class="show-above-1000"></app-design-view-footer>
    </div>
  </div>
</div>
