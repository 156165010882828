<div id='content' *ngIf='this.sameUser else warningButton'>
  <div class="claim-button-wrap">
    <button
      (click)="this.onClaim()"
      type="button"
      class="btn claim-button btn-primary">
      {{ 'Claim /type/' | translate:{type: this.diplomaTemplateType} }}
    </button>
  </div>
</div>
<ng-template #warningButton>
  <div id='content'>
    <div class='warning-text flex-row'>
      <div class='flex-column'>
        <i class='fa fa-exclamation-circle'></i>
      </div>
      <div class='flex-column'>
        {{'Transferred to e-mail'| translate}}: {{this.receiverMail}},
        {{'but you are logged in with' | translate}}: {{ this.userMail }}
      </div>
    </div>
    <div>
      <button
        (click)='this.onClaim()'
        type='button'
        class='btn claim-button btn-warning'>
        {{ "That's OK, claim /type/ anyway" | translate:{ type: this.diplomaTemplateType } }}
      </button>
    </div>
    <div class='warning-link'>
      <a (click)='this.onLogout()'>{{'Not correct! Log out and start over' | translate}} >></a>
    </div>
  </div>
</ng-template>
