import { Component, Input, OnInit } from '@angular/core';
import { CustomButtonService } from '../../../services/custom-buton.service';
import { CustomButtonResponseDto } from '../../../dtos/CustomButtonResponseDto';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SwalService } from 'app/core/services/util/swal.service';

@Component({
  selector: 'app-custom-button-group',
  templateUrl: './custom-button-group.component.html',
  styleUrls: ['./custom-button-group.component.css'],
})
export class CustomButtonGroupComponent implements OnInit {
  @Input() private templateId: number;
  @Input() public diplomaHash: string;

  public customButtons$: Observable<CustomButtonResponseDto[]>;
  private params: Params = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private customButtonService: CustomButtonService,
    private activatedRoute: ActivatedRoute,
    private swalService: SwalService
  ) {}

  ngOnInit() {
    this.fetchCustomButtons();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private fetchCustomButtons(): void {
    this.customButtons$ = this.customButtonService.fetchCustomButtons(this.templateId);
  }

  public actionClick(customButton: CustomButtonResponseDto): void {
    this[customButton.method](customButton);
  }

  private get(customButton: CustomButtonResponseDto): void {
    const url = this.urlWithParams(customButton.url);

    window.open(url, '_blank');
  }

  private post(customButton: CustomButtonResponseDto): void {
    const url = this.urlWithParams(customButton.url);

    this.customButtonService
      .customButtonPost(url, { diploma_id: this.diplomaHash })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.swalService.initSuccessToast('Success');
        },
        (error) => {
          this.swalService.initFailToast('Error ' + error.message || error.error.message );
        }
      );
  }

  private urlWithParams(url: string): string {
    const replacements = { ':diploma_id': this.diplomaHash, ':language': this.params.lang };

    return url.replace(/:diploma_id|:language/g, (match) => replacements[match]);
  }
}
