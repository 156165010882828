<button *ngIf="!diploma.diploma_share_choice; else inChoice" (click)="shareData()" class="social-login-btn {{ type }}">
  <img src="{{ icon }}" aria-hidden="true" />
  {{ 'Share on /social/' | translate : { social: social } }}
</button>
<ng-template #inChoice>
  <button class="social-login-btn {{ type }}" (click)="openImageSelect()">
    <img src="{{ icon }}" aria-hidden="true" />
    {{ 'Share on /social/' | translate : { social: social } }}
  </button>
</ng-template>
