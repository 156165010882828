import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-diploma-expire-delete-warning-modal',
  templateUrl: './diploma-expire-delete-warning-modal.component.html',
  styleUrls: ['./diploma-expire-delete-warning-modal.component.css'],
})
export class DiplomaExpireDeleteWarningComponent implements OnInit {
  @Input() public data: any;
  @Input() public language: string;

  @Output() public changeAutoDeleteExpiredModal = new EventEmitter<boolean>();
  
  constructor() {  }
  
  ngOnInit(){ }
  
  public changeAutoDeleteExpiredStatusEvent($event: boolean): void {    
    this.changeAutoDeleteExpiredModal.emit($event);
  }
}
