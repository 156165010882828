<div id="content">
  <flash-messages></flash-messages>
  <form [formGroup]="form" (submit)="onLogin()">
    <div class="inputs">
      <div class="form-group input-group">
        <input formControlName="username" class="form-control" placeholder="Email" [(ngModel)]="loginData.username" />
      </div>
      <div class="alert alert-danger" *ngIf="username?.touched && username?.errors?.required">Email is required</div>
      <div class="alert alert-danger" *ngIf="username?.dirty && username?.errors?.pattern">Invalid Email format</div>
      <div class="form-group input-group">
        <input
          formControlName="password"
          class="form-control"
          placeholder="Enter password"
          type="password"
          [(ngModel)]="loginData.password"
        />
      </div>
      <div class="alert alert-danger" *ngIf="password?.touched && password?.errors?.required">Password is required</div>
      <div class="forgot-password">
        <a routerLink="/{{ lang }}/forgot-password">{{ 'Reset my password' | translate }}</a>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" [disabled]="form.invalid" class="btn claim-button btn-primary">
        {{ 'Continue' | translate }}
      </button>
    </div>


  </form>
</div>
