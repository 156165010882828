import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable, throwError } from "rxjs";

import { Upass } from "../../models/dashboard/upass";

import { environment } from "../../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class UpassService {
  private apiUpdateProfileUrl =
    environment.API_START_URL + "api/changePassword";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public changePassword(body: any, accessToken): Observable<Upass[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.http
      .post(this.apiUpdateProfileUrl, body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
