<ng-template id="accept" #content let-c="close" let-d="dismiss">
  <h1 mat-dialog-title class="green-color">
    {{ 'Enter Password' | translate}}
  </h1>
  <form [formGroup]="form" (ngSubmit)="submitPassword()">
    <mat-dialog-content>
      <mat-form-field class="full-width">
        <input matInput type="password" required placeholder="{{ 'Password' | translate }}"
               formControlName="password">
        <mat-error *ngIf="password().hasError('required')">{{ 'Password required' | translate }}
        </mat-error>
        <mat-error *ngIf="password().hasError('wrongPassword')">{{ 'Wrong password. /attempts/ of 3 attempts.' | translate: { attempts: this.tries} }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="button-action">
        <button mat-raised-button mat-dialog-close (click)="back()" type="button" class="cancel">{{ 'Close' | translate }}</button>
        <button mat-raised-button class="submit" type="submit">{{ 'Submit' | translate}}</button>
      </div>
    </mat-dialog-actions>
  </form>
</ng-template>