import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import LoginResponse from '../../../../../core/models/auth/login-response';
import { LoginService } from '../../../../../core/services/login/login.service';
import { LinkedinService } from '../../../../../core/services/socials/linkedin.service';
import { SocialLoginService } from '../../../../../core/services/social-login/social-login.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-linkedin-login-responce',
  templateUrl: './linkedin-login-responce.component.html',
  styleUrls: ['./linkedin-login-responce.component.css'],
})
export class LinkedinLoginResponceComponent implements OnInit, OnDestroy {
  private linkedInToken: string;
  private clamingFlow: boolean = Boolean(sessionStorage.getItem('clamingFlow'));
  private linkingFlow: boolean = Boolean(sessionStorage.getItem('linkingFlow'));
  private directShareFlow: boolean = Boolean(sessionStorage.getItem('directShareFlow'));

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private linkedinService: LinkedinService,
    private socialLoginService: SocialLoginService
  ) {}

  public ngOnInit(): void {
    this.linkedInToken = this.activatedRoute.snapshot.queryParams['code'];

    this.linkedinService
      .getLinkedinUserData(this.linkedInToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (this.directShareFlow) {
            sessionStorage.removeItem('directShareFlow');
            window.close();
            
            return;
          }
              
          if (this.linkingFlow) {
            sessionStorage.setItem('linkedinUserId', res['userID']);
            return this.router.navigate(
              [this.cookieService.get('langVal') + '/editProfile'],
              { queryParams: { tab: 1 } }
            );
          }

          this.clamingFlow
            ? this.socialSignIn('linkedin', res['userID'])
            : this.checkSocialLogin('linkedin', res['userID'], res['userEmail']);
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private checkSocialLogin(socialType: string, socialId: string, socialEmail: string) {
    this.socialLoginService
      .checkSocialLogin(socialType, socialId, socialEmail)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res.redirect) {
            this.loginService.clearSessionRedirectToLogin(res.uhash, socialType);
          } else {
            this.socialSignIn(socialType, socialId);
          }
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  // social login
  private socialSignIn(socialType: string, socialId: string) {
    this.socialLoginService
      .socialLogin(socialType, socialId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res['user']) {
            this.initData(res['user']);
          }
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  private initError(errorMessage: string) {
    this.loginService.clearSessionRedirectToLogin();
  }

  private initData(data: LoginResponse) {
    this.loginService.login(data, !this.clamingFlow);
  }
}
