import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UserSocialLogin } from "app/core/models/UserSocialLogin";
import { SocialLoginService } from "app/core/services/social-login/social-login.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil, finalize } from "rxjs/operators";
import { FlashMessagesUtilService } from "app/core/services/util/flash-messages-util.service";
import capitalizeFirstLetter from "app/core/helpers/util/capitalize-first-letter";

@Component({
  selector: "app-alternative-logins-list",
  templateUrl: "./alternative-logins-list.component.html",
  styleUrls: ["./alternative-logins-list.component.css"],
})
export class AlternativeLoginsListComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild("googleButton") googleButton: any;

  public alternativeLogins: UserSocialLogin;
  
  private loadSocialLoginsAfterLink: Boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private socialLoginService: SocialLoginService, private flashMessagesUtilService: FlashMessagesUtilService) {}

  public ngOnInit(): void {
    this.socialLoginService.socialLoginInit(this.googleCallback.bind(this));
  }

  public ngAfterContentInit(): void {    
    if (sessionStorage.getItem('linkingFlow') && sessionStorage.getItem('linkedinUserId')) {      
      this.loadSocialLoginsAfterLink = true;
      this.enableLink('linkedin', sessionStorage.getItem('linkedinUserId'))

      // remove linking sessions
      sessionStorage.removeItem('linkingFlow');
      sessionStorage.removeItem('linkedinUserId');
    } else {
      this.getSocialLogins();
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public changeGoogleLink($event: boolean) {
    if ($event) {
      this.socialLoginService.googleLink(this.googleButton);
    } else {
      this.disableLink("google");
    }
  }

  public changeFacebookLink($event: boolean): void {
    if ($event) {
      this.socialLoginService
        .facebookLink()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((userId: string) => {
          this.handleEnableLink("facebook", userId);
        });
    } else {
      this.disableLink("facebook");
    }
  }

  public changeLinkedinLink($event: boolean): void {
    if ($event) {
      sessionStorage.setItem('linkingFlow', 'true');    
      this.socialLoginService.linkedinLogin();
    } else {
      this.disableLink("linkedin");
    }
  }

  private googleCallback(userId: any) {    
    this.handleEnableLink("google", userId);
  }

  private getSocialLogins(): void {
    this.socialLoginService
      .getSocialLogins()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alternativeLogins = res;
      });
  }

  private handleEnableLink(socialType: string, userId: any) {
    userId ? this.enableLink(socialType, userId) : (this.alternativeLogins[socialType] = false);
  }

  private enableLink(socialType: string, socialId: string): Subscription {    
    return this.socialLoginService
      .enableLink(socialType, socialId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          // this is called on both success and error
          if (this.loadSocialLoginsAfterLink) {
            this.getSocialLogins();
            this.loadSocialLoginsAfterLink = false;
          }
        })
      )
      .subscribe((res: any) => {
        this.flashMessagesUtilService.flashMessages(
          res.message,
          res.status === 'success' ? 'success': 'danger',
          2000,
          { socialType: capitalizeFirstLetter(socialType) }
        );
      },
        (err) => {
          this.alternativeLogins[socialType] = false;

          this.flashMessagesUtilService.flashMessages(
            err.error.message || '',
            'danger',
            5000,
            { socialType: capitalizeFirstLetter(socialType) }
          );
        }
      );
  }

  private disableLink(socialType: string): Subscription {
    return this.socialLoginService
      .disableLink(socialType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.flashMessagesUtilService.flashMessages(
          res.status === 'success'
            ? '/socialType/ unlinked successfully'
            : '/socialype/ unlinked failed',
          res.status === 'success' ? 'warning' : 'danger',
          2000,
          { socialType: capitalizeFirstLetter(socialType) }
        );
      });
  }
}
