import { Component, OnInit, Input } from '@angular/core';
import { DiplomaDesign } from '../../../core/models/diploma';

@Component({
  selector: 'app-template-information',
  templateUrl: './template-information.component.html',
  styleUrls: ['./template-information.component.css']
})
export class TemplateInformationComponent implements OnInit {

  @Input() templateId: number;
  @Input() viewType: number;
  @Input() trackLink: any;
  @Input() description: any;
  @Input() templateLink: string;
  @Input() diplomaName: string;
  @Input() diplomaDesign: DiplomaDesign;
  @Input() learnMoreFunction: number;
  @Input() organizationImage: string;
  @Input() openModel: any;

  constructor() { }

  ngOnInit() {
  }
}
