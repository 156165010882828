<h1 mat-dialog-title class='green-color'>
  {{ 'Share Link Created' | translate}}
</h1>
<div>
  <mat-dialog-content>
    <div class='form-horizontal claim-url-form'>
      <div class='input-group' *ngIf='isShareViewLoaded'>
        <input class='form-control claim-url' readonly type='text' value='{{ data.linkResult }}'>
        <div class='input-group-btn'>
          <button class='btn btn-info' type='button' title="{{'Copy to clipboard' | translate }}"
                  (click)='this.shareViewService.copyToClipBoard(data.linkResult)'>{{ 'Copy' | translate }}</button>
        </div>
      </div>
      <div *ngIf='!isShareViewLoaded'>
        <div class='loader text-center'>
          <div class='green-color'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw green-cl'></i>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close type='button' class='cancel'
            *ngIf='isShareViewLoaded'>{{ 'Close' | translate }}</button>
    <a href='{{ data.linkResult }}' target='_blank'>
      <button mat-raised-button type='button' class='submit'
              *ngIf='isShareViewLoaded'>{{ 'Try it out' | translate }}</button>
    </a>
  </mat-dialog-actions>
</div>
