/* tslint:disable:variable-name */
export class Login {
  constructor(
    public client_id: any,
    public client_secret: any,
    public grant_type: any,
    public username: any,
    public password: any,
    public scope: any,
    public uhash_social_link: any,
  ) {}
}
/* tslint:enable:variable-name */
