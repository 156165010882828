import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import { Observable } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SlideInOutAnimation } from '../../../../core/animations/slide-in-out.animation';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pathway-widget-content',
  templateUrl: './pathway-widget-content.component.html',
  styleUrls: ['./pathway-widget-content.component.css'],
  animations: [SlideInOutAnimation]
})
export class PathwayWidgetContentComponent implements OnInit {

  @Input() pathway: PathwayInterface;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  public data: Observable<any>;
  public config: SwiperOptions = {
    rewind: true,
    spaceBetween: 0,
    slidesPerView: 1,
    updateOnWindowResize: true,
    breakpoints: {
      '768': {
        slidesPerView: 2
      },
      '1024': {
        slidesPerView: 3
      },
      '1200': {
        slidesPerView: 4
      },
      '1400': {
        slidesPerView: 5
      }
    }
  };

  public displayArrowButtons: boolean = false;

  public animateGoalDetails: string = 'out';
  public animateSubGoalDetails: string = 'out';
  public selectedSubGoal: SubGoalWidgetInterface = null;

  private params: any = this.activatedRoute.snapshot.params;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  /**
   * Manual slide to the next slide
   */
  public slideNext(): void {
    this.swiper.swiperRef.slideNext(200);
  }

  /**
   * Manual slide to the previous slide
   */
  public slidePrev(): void {
    this.swiper.swiperRef.slidePrev(200);
  }

  /**
   * Set display arrows to false
   * @param swiper
   */
  onLock([swiper]) {
    this.displayArrowButtons = false;
  }

  /**
   * Set display arrows to true
   * @param swiper
   */
  onUnlock([swiper]) {
    this.displayArrowButtons = true;
  }

  /**
   * Toggle displaying goal details
   */
  toggleGoalDetails() {
    this.animateGoalDetails = this.animateGoalDetails === 'out' ? 'in' : 'out';
  }

  /**
   * Toggle displaying sub-goal details
   *
   * @param subGoal
   */
  toggleSubGoalDetails(subGoal: SubGoalWidgetInterface): void {

    // if sub-goal details is opened and user clicked different sub-goal
    if (this.animateSubGoalDetails === 'in' && this.selectedSubGoal.id !== subGoal.id) {
      this.toggleAnotherSubGoalDetails(subGoal);

      return;
    }

    if (this.animateSubGoalDetails === 'out') {
      this.selectedSubGoal = subGoal;
    }

    setTimeout(() => {
      this.animateSubGoalDetails = this.animateSubGoalDetails === 'out' ? 'in' : 'out';
    }, 100);
  }

  /**
   * Close currently opened sub-goal details and open clicked
   *
   * @param subGoal
   * @private
   */
  private toggleAnotherSubGoalDetails(subGoal: SubGoalWidgetInterface): void {
    this.animateSubGoalDetails = 'out';

    setTimeout(() => {
      this.selectedSubGoal = subGoal;
      this.animateSubGoalDetails = 'in';

    }, 750);
  }

  /**
   * Generate sub-goal diploma url
   * @param uhash
   * @param niceName
   */
  public generateDiplomaUrl(uhash: string, niceName: string): string {
    return `${this.params.lang}/diploma/${uhash}/${niceName}`;
  }

}
