import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FlashMessagesService } from "angular2-flash-messages";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FlashMessagesUtilService {
  constructor(private translateService: TranslateService, private flashMessagesService: FlashMessagesService) {}

  public flashMessages(message: string, type: string, timeout: number = 10000, params: Object = {}): void {
    this.translateService
      .get(message, params)
      .pipe(take(1))
      .subscribe((value: any) => {
        this.flashMessagesService.show(value, {
          cssClass: 'alert-' + type,
          timeout: timeout,
        });
      });
  }
}
