<div class="full-width-height">
    <div style="height: 100%;">
        <div *ngIf="data && data.error else pdfPart">
            <app-header></app-header>
            <main class="container margin-bottom-70">
                <div class="row justify-content-md-center">
                    <div class="col-md-8 col-lg-6">
                        <div class="white-box margin-top-inverse-85 error-page text-center">
                            <h1 class="margin-bottom-30 red-color">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'Error' | translate }}
                            </h1>
                            <p class="margin-bottom-30">
                                {{ data?.message | translate }}
                            </p>
                            <a class="btn btn-success" routerLink="/">{{ 'Go Back to the Homepage' | translate }}</a>
                        </div>
                    </div>
                </div>
            </main>
            <app-footer></app-footer>
        </div>
        <ng-template #pdfPart >
          <main class="container">
            <div class="row justify-content-md-center align-items-center">
              <div class="col-md-8 col-lg-6">
                <div class="white-box loading-page text-center">
                  <h1 class="margin-bottom-30 green-color">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
                    <span class="sr-only">{{ 'Generating PDF' | translate }}...</span>
                  </h1>
                  <p>
                    {{ 'Generating PDF' | translate }}... {{ 'Please Wait' | translate }}...
                  </p>
                </div>
              </div>
            </div>
          </main>
        </ng-template>
    </div>
</div>
