import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CustomButtonResponseDto } from '../dtos/CustomButtonResponseDto';
import getHeaders from '../../../shared/helpers/http/http-headers';

const apiUrl = environment.API_START_URL;

const GET_TEMPLATE_CUSTOM_BUTTONS = (templateId: number, language: string): string =>
  `${apiUrl}api/${language}/template/${templateId}/custom-buttons`;

@Injectable()
export class CustomButtonService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {}

  public fetchCustomButtons(templateId: number): Observable<CustomButtonResponseDto[]> {
    return this.httpClient.get<CustomButtonResponseDto[]>(GET_TEMPLATE_CUSTOM_BUTTONS(templateId, this.cookieService.get('langVal')), {
      headers: getHeaders(),
    });
  }

  public customButtonPost(url: string, body: any) {
      return this.httpClient.post(url, body);
  }
}
