<div class="container main-container" [ngClass]="{ 'small-resolution': smallResolution }">
  <div class="actions gap-5" [ngClass]="{ 'other-format': otherFormats }">
    <div class="menu dropdown responsive-header user-header" *ngIf="userMenu">
      <div *ngIf="mainData?.name; else elseLoginBtn">
        <span
          class="dropdown-toggle noselect"
          data-toggle="dropdown"
          [style.color]="
            diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''
          "
        >
          {{ 'Profile' | translate }}
          <span class="caret"></span>
        </span>
        <ul class="dropdown-menu">
          <li>
            <a routerLink="/{{ selectedLanguage }}/dashboard" [appOnHoverBackground]="diplomaDesign?.header_color">
              <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp;
              {{ 'Overview' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/{{ selectedLanguage }}/editProfile" [appOnHoverBackground]="diplomaDesign?.header_color">
              <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
              {{ 'Edit Profile' | translate }}
            </a>
          </li>
          <li>
            <a
              routerLink="/{{ selectedLanguage }}/changePassword"
              *ngIf="!mainData?.social"
              [appOnHoverBackground]="diplomaDesign?.header_color"
            >
              <i class="fa fa-key" aria-hidden="true"></i>&nbsp;
              {{ 'Change Password' | translate }}
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="onlogout()" [appOnHoverBackground]="diplomaDesign?.header_color">
              <i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;
              {{ 'Logout' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="responsive-header help" [ngClass]="{ 'no-grow': !this.helpGrow }">
      <a
        *ngIf="customSuportLink && customSuportLink.length > 0; else defaultLink"
        href="{{ customSuportLink ? customSuportLink : '#' }}"
        class="noselect"
        [style.color]="
          diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''
        "
      >
        {{ 'Help?' | translate }}
      </a>
      <ng-template #defaultLink>
        <a
          routerLink="/{{ selectedLanguage }}/help"
          class="noselect"
          [style.color]="
            diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''
          "
        >
          {{ 'Help?' | translate }}
        </a>
      </ng-template>
    </div>
    <div
      class="menu language dropdown responsive-header"
      [style.color]="
        diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''
      "
    >
      <span class="dropdown-toggle noselect" data-toggle="dropdown">
        <ng-template *ngFor="let language of languages" [ngIf]="selectedLanguage == language['langCode']">
          <figure [ngClass]="language['flag']"></figure>
          {{ language['native'] }}
        </ng-template>
        <span class="caret"></span>
      </span>
      <ul class="dropdown-menu">
        <li
          *ngFor="let language of languages"
          [ngClass]="selectedLanguage == language['langCode'] ? 'active' : ''"
          (click)="updateLanguage(language['langCode'])"
        >
          <a
            [routerLink]="'/' + language['langCode'] + currentUrl"
            [queryParams]="currentUrlParams"
            [appOnHoverBackground]="diplomaDesign?.header_color"
          >
            <figure [ngClass]="language['flag']"></figure>
            {{ language['native'] }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #elseLoginBtn>
  <a
    routerLink="/{{ selectedLanguage }}/login{{ checkRegisterPage ? checkRegisterPage : '' }}"
    class="login noselect"
    *ngIf="!checkLoginPage && !otherFormats"
    [style.color]="
      diplomaDesign?.custom_colors && diplomaDesign?.header_text_color ? diplomaDesign?.header_text_color : ''
    "
  >
    {{ 'Login' | translate }}
  </a>
</ng-template>
