<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 form">
        <h1 class="margin-bottom-30">{{ "Log in" | translate }}:</h1>
        <flash-messages></flash-messages>
        <form class="login-form" (ngSubmit)="onLogin()">
          <mat-form-field style="width: 100%">
            <input
              matInput
              type="email"
              name="username"
              placeholder="{{ 'Type your e-mail address' | translate }}"
              [(ngModel)]="login.username"
              [formControl]="emailFormControl"
            />
            <mat-error *ngIf="emailFormControl.hasError('pattern')">
              {{ "Please enter a valid email address" | translate }}
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              {{ "Email is required" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field style="width: 100%">
            <input
              matInput
              type="password"
              name="password"
              placeholder="{{ 'Type your password' | translate }}"
              [(ngModel)]="login.password"
              [formControl]="passwordFormControl"
            />
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              {{ "Password is required" | translate }}
            </mat-error>
          </mat-form-field>
          <div class="button-action">
            <button mat-raised-button type="submit" class="submit">
              <i class="fa fa-user" aria-hidden="true"></i>&nbsp;
              {{ "Log in" | translate }}
            </button>
            <a routerLink="/{{ language }}/forgot-password" class="text-semibold">
              {{ "Reset my password" | translate }}
            </a>
          </div>
        </form>

        <app-social-logins
          (onLoginProcessing)="onLoginProcessing($event)"
        ></app-social-logins>
      </div>
    </div>
  </div>
</main>
<app-about-diplomasafe></app-about-diplomasafe>
<app-footer></app-footer>
<div *ngIf="processing" class="processing noselect">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
  <div>{{ "Logging in" | translate }}... {{ "Please Wait" | translate }}...</div>
</div>
