<div class="diploma-image" [ngClass]="{'shadow' : expandModal }">
  <div class="picsum-img-wrapper">
    <img [src]="sanitizer.bypassSecurityTrustResourceUrl(views[pageNumber])" alt="">
  </div>
  <button class="btn btn-lg expand" (click)="openModel(expandModal, 'expandModal')" *ngIf="expandModal">
    <span class="iconify" data-icon="mdi-fullscreen" data-inline="false" data-width="40"></span>
  </button>
</div>
<div class="paginator" *ngIf="views.length > 1">
  <div class="text-center mt-3">
    <button class="btn btn-sm" (click)="prevPage()"><i class="fa fa-arrow-circle-left"></i></button>
    {{'page' | translate}} {{pageNumber + 1}}/{{views.length}}
    <button class="btn btn-sm" (click)="nextPage()"><i class="fa fa-arrow-circle-right"></i></button>
  </div>
</div>
<ng-template #expandModal>
  <div class="modal-body diploma-expanded">
    <div class="diploma-image">
      <div class="picsum-img-wrapper">
        <img [src]="sanitizer.bypassSecurityTrustResourceUrl(views[pageNumber])" alt="">
      </div>
    </div>
    <div class="paginator" *ngIf="views.length > 1">
      <div class="text-center mt-3">
        <button class="btn btn-sm" (click)="prevPage()"><i class="fa fa-arrow-circle-left"></i></button>
        {{'page' | translate}} {{pageNumber + 1}}/{{views.length}}
        <button class="btn btn-sm" (click)="nextPage()"><i class="fa fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
</ng-template>

