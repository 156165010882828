import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DiplomaFieldType } from 'app/core/models/diploma-field';
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

//Online view (top prio)

@Component({
  selector: 'app-diploma-online-view',
  templateUrl: './diploma-online-view.component.html',
  styleUrls: ['./diploma-online-view.component.css']
})
export class DiplomaOnlineViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public data: any;
  @Input() public language: any;
  @Input() public seeMore: boolean | undefined;
  @Input() public setSeeMore: (value: boolean) => {};

  public largeDiplomaMain: boolean | null = null;
  private imageLoaded: boolean = false;
  private viewInitialized: boolean = false;

  constructor() { }
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  ngOnInit() {
    // On window resize, recalculate to see if
    // "See more" should be visible.
    this.resizeObservable$ = fromEvent(window, 'resize');
    // We have a debounce here, so we wait a second before triggering the event.
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(1000)).subscribe(
      () => {
        let seeMoreIsFalse = false;
        if (this.seeMore === false) {
          seeMoreIsFalse = true;
        }

        // For the recalculation, we need to set seeMore to null
        // so that the full height of the diploma is visible.
        // And if it was expanded before resizing,
        // we set it to expanded again (if possible).
        this.setSeeMore(null);
        this.largeDiplomaMain = null;
        setTimeout(() => {
          this.initSeeMore();
          setTimeout(() => {
            if(seeMoreIsFalse && this.seeMore) {
              this.setSeeMore(false);
            }
          }, 0);
        }, 0);
      });
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
    if (this.imageLoaded) {
      this.initSeeMore();
    }
  }

  imageLoad() {
    this.imageLoaded = true;
    if (this.viewInitialized) {
      this.initSeeMore();
    }
  }

  initSeeMore() {
    // Call this function only when the view has initialized and the image has loaded.
    const presentationElement = document.querySelector('.diploma-presentation');
    // We have "+ 40", because we don't want to show "See more"
    // if just the bottom padding is overflowing.
    if (presentationElement && presentationElement.clientHeight + 40 < presentationElement.scrollHeight) {
      this.setSeeMore(true);

      // Check the height of the ".diploma-main" element
      // after the image has finished loading.
      // If the element is too big, we will initially hide the description.
      const approximateMaxDiplomaMain = window.screen.width > 600 ? 475 : 440; // Check if we are on a mobile screen.
      const diplomaMain = document.querySelector(".diploma-main");
      if (diplomaMain && diplomaMain.clientHeight > approximateMaxDiplomaMain) {
        this.largeDiplomaMain = true;
      } else {
        this.largeDiplomaMain = false;
      }
    } else {
      this.setSeeMore(null);
    }
  }

  /**
   * Sorting function used from angular pipe keyvalue
   * @param a
   * @param b
   */
  public fieldsOrder(a,b) {
    const indexA = parseInt(a.key.replace('row', ''));
    const indexB = parseInt(b.key.replace('row', ''));

    return indexA - indexB;
  }

  public showTable(field) {
    return field.type === DiplomaFieldType.table && typeof field.value === 'object';
  }

  public showTableReplacement(field) {
    return field.type === DiplomaFieldType.table && typeof field.value === 'string' && field.value.length > 0;
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
}
