import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmClaimedDialogComponent } from './confirm-claimed-dialog/confirm-claimed-dialog.component';

@Component({
  selector: 'app-wrong-name-request-button',
  templateUrl: './wrong-name-request-button.component.html',
  styleUrls: ['./wrong-name-request-button.component.css'],
})
export class WrongNameRequestButtonComponent {
  @Input() public lang: string;
  @Input() public diplomaIsClaimed?: boolean | null;
  @Input() public templateType: string;

  public params: Params = this.activatedRoute.snapshot.params;

  constructor(private activatedRoute: ActivatedRoute, private readonly router: Router, public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmClaimedDialogComponent, {
      hasBackdrop: true,
      width: '500px',
      data: {
        templateType: this.templateType
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate([`/${this.params.lang}/reportWrongName/${this.params.uh}`]);
      }
    });
  }
}
