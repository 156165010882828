<div class="col-md-12 col-lg-9 dialog-2fa">
  <img *ngIf="this.orgainzationImage else diplomasafeImage" [src]="this.orgainzationImage" class="organization-logo-2fa"/>
  <ng-template #diplomasafeImage>
    <img src="../../../../assets/img/logo_with_text_large.png" alt="{{ 'Diplomasafe logo' | translate }}" class="organization-logo-2fa" />
  </ng-template>
  <div *ngIf="this.isFirstStep else secondStep">
    <div>
      {{ 'This /template_type/ requires extra protection.' | translate:{template_type: this.templateType} }}
    </div>      
    <div>
      {{ 'You must configure so called 2-factor authentication using the Google Authenticator app. If you do not already have the app installed on your mobile device, please install it before you proceed:' | translate }}
    </div>
    <dir class="badges">
      <div class="badge-qr-code">
        <img  [src]="this.otpAppQrCodes?.googlePlayQrBase64" alt="{{ 'Google play qr code' | translate }}">
        <a
          target="_blank"
          class="download-button"
          href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        >
          <img
            class="download-img"
            alt='Get it on Google Play'
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </a>
      </div>
      <div class="badge-qr-code">
        <img  [src]="this.otpAppQrCodes?.appStoreQrBase64 " alt="{{ 'App store qr code' | translate }}">
        <a
          target="_blank"
          class="download-button apple-a"
          href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&h=7fc6b39acc8ae5a42ad4b87ff8c7f88d"
            alt="Download on the App Store"
            class="download-img apple-img"
            />
        </a>
      </div>
    </dir>
    <div class="center">
      <button mat-raised-button type="button" class="btn-success button-width" (click)="toSecondStep()">
        {{ 'Continue' | translate }}
      </button>
    </div>
  </div>
  <ng-template #secondStep>
    <div> 1. {{ 'Open the Google Authenticator app (if you have not installed the app' | translate }} </div>
    <div> 2. {{ 'Click on the “+” in the Google Authenticator app' | translate }} </div>
    <div> 3. {{ 'Scan the following QR code with the app' | translate }} </div>
    <div class="center">
      <img  [src]="this.qr" alt="{{ 'Diplomasafe logo' | translate }}" class="user-qr">
    </div>
    <div> 4. {{ 'Print this page as a backup and save it on a secure location' | translate }} </div>
    <div> 5. {{ 'Enter the 6-digit code presented in the Google Authenticator app' | translate }} </div>
    <form (ngSubmit)="verifyTwoFactorAuthenticationCode()" autocomplete="off">
      <div class="center">
        <mat-form-field appearance="outline" class="min-width">
          <mat-label>{{ '6-digit code' | translate }}</mat-label>
          <input matInput type="text" placeholder="{{ 'Enter code' | translate }}" [formControl]="secret" required>
          <mat-error *ngIf="secret.invalid">{{ getErrorMessage() | translate: { attempts: this.tries } }}</mat-error>
        </mat-form-field>
      </div>
      <div> 6. {{ 'Press \"Finish\"' | translate }}</div>
      <div class="center">
        <button mat-raised-button type="submit" class="btn-success button-width">
          {{ 'Finish' | translate }}
        </button>
      </div>
    </form>
  </ng-template>
</div>