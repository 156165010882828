import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CheckClaimGoalSelection } from 'app/core/models/goals/check-claim-goal-selection';
import { TwoFactorAuthentication } from 'app/core/models/two-factor-authentication';
import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { forkJoin, Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DiplomaAccessLevel, DiplomaDesign, DiplomaPresentationData } from '../../../core/models/diploma';
import { Share } from '../../../core/models/share';
import { TrackLinks } from '../../../core/services/common-service/tracklinks';
import { TrackLinksService } from '../../../core/services/common-service/tracklinks.service';
import { DiplomaService } from '../../../core/services/diploma/diploma.service';
import {
  TwoFactorAuthenticationServise
} from '../../../core/services/two-factor-authentication/two-factor-authentication.service';
import { UserService } from '../../../core/services/user/user.service';
import { SeoAndMetaTagsInterface } from '../../../core/types/interfaces/seo/seo-and-meta-tags.interface';
import { MetaDataService } from '../../../core/services/seo/meta-data.service';

// Wrapper component for all views

@Component({
  providers: [TrackLinksService],
  selector: 'app-diploma-landing',
  templateUrl: './diploma-landing.component.html',
  styleUrls: ['./diploma-landing.component.css']
})
export class DiplomaLandingComponent implements OnInit, OnDestroy {
  public value: any;
  public datas: any;
  public identifierValue: any;
  public designView: Observable<DiplomaDesign>;
  public withAccessLevel: boolean = false;
  public withGoalSelection: boolean = false;
  public diplomaAccessLevel: DiplomaAccessLevel;

  private errorMessage: string;
  private result: any;
  private error: any;
  private res: any;
  private params: any = this.activatedRoute.snapshot.params;
  private share = new Share('', '');
  private trackLinks = new TrackLinks('', '', '', '');
  private shareUrl: any;
  public successful: any;
  public unsuccessful: any;
  private fbParams: UIParams;
  private fbInitParams: InitParams;
  public specialShare: boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private twoFactorAuthenticationServise: TwoFactorAuthenticationServise,
    private diplomaService: DiplomaService,
    private trackLinksService: TrackLinksService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FacebookService,
    private metaDataService: MetaDataService
  ) {
    // Facebook Share dialog init

    // this.fbInitParams = {
    //   appId: environment.FB_APP_ID,
    //   version: environment.FB_API_VERSION,
    //   xfbml: environment.FB_XFBML,
    // };
    // this.fb.init(this.fbInitParams);

    this.translateService.get('Copying to clipboard was successful').subscribe((value) => {
      this.successful = value;
    });
    this.translateService.get('Copying to clipboard was unsuccessful').subscribe((value) => {
      this.unsuccessful = value;
    });

    this.designView = this.diplomaService.getDiplomaDesign();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    
    if (this.params.view === 'adminView') {
      this.adminView();

      return;
    }

    if (this.params.social === 'specialShare' || this.openIdSession) {
      this.checkViewType(this.params.uh, this.userDetails.accessToken);

      return;
    }

    if (!localStorage.getItem('currentUser')) {
      this.diplomaUserNotLogged();

      return;
    }

    this.diplomaUserLogged();

    // if diploma is part of bundle
    this.diplomaService.getDesignViewObservable()
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data.bundle) {
          const metadata: SeoAndMetaTagsInterface = this.prepareMetadata(data);
          // og:lang language code format is language_TERRITORY - we replace - with _ to match the format
          const currentLang: string = this.params.lang.replace('-', '_');

          this.metaDataService.setMetaData(window.location.href, currentLang, metadata);
        }

      });
  }

  private twoFactorError(message: string): void {
    this.twoFactorAuthenticationServise.handleError(this.params.lang, message, true);
  }

  private checkAccessLevelAndGetDiplomaInfo(
    diplomaHash: string,
    userHash: string,
    langCode: string,
    accessToken: string
  ): Observable<DiplomaAccessLevel> {
    return this.diplomaService.checkAccessLevelAndGetDiplomaInfo(diplomaHash, userHash, langCode, accessToken);
  }

  private withGoalSelectionPage(diplomaHash: string, accessToken: string): Observable<CheckClaimGoalSelection> {
    return this.diplomaService.withGoalSelectionPage(diplomaHash, accessToken);
  }

  private validate2faToken(uh: string, token: string, accessToken: string): Observable<TwoFactorAuthentication> {
    return this.twoFactorAuthenticationServise.validateToken(uh, token, accessToken);
  }

  public checkViewType(uh: string, accessToken: string): void {
    this.specialShare = this.params.social === 'specialShare';
    this.diplomaService.checkDesignView(uh, accessToken, this.specialShare);
  }

  public shareData = (media, name, description, tags, customDomain: string | null = null) => {
    if (media === 'Facebook') {
      this.shareUrl = this.shareLinkFB(name, description, tags, customDomain);
    } else if (media === 'LinkedIn') {
      this.shareUrl = this.shareLinkLinkedIn(name, description, customDomain);
      window.open(
        this.shareUrl + '&source=Diplomasafe',
        'sharer',
        'toolbar = no, status = 0, width = 548, height = 325'
      );
    } else {
      this.shareUrl = this.shareLinkTwitter(name, description, tags, customDomain);
      window.open(
        this.shareUrl + '&source=Diplomasafe',
        'sharer',
        'toolbar = no, status = 0, width = 548, height = 400'
      );
    }

    this.trackShareData(media);
  };

  public shareLinkFB(name, description, tags, customDomain: string | null) {
    // https://developers.facebook.com/docs/sharing/reference/share-dialog
    const fbShareLink = this.shareLink(this.params.lang, this.params.uh, this.params.nicename, 'facebook', customDomain);
    if (tags) {
      this.fbParams = {
        hashtag: '#' + tags,
        href: fbShareLink,
        method: 'share'
      };
    } else {
      this.fbParams = {
        href: fbShareLink,
        method: 'share'
      };
    }

    // Facebook ui init
    this.fb
      .ui(this.fbParams)
      .then((res: UIResponse) => (this.res = res))
      .catch((e: any) => (this.error = e));
  }

  public shareLinkLinkedIn(name, description, customDomain: string | null) {
    const link = this.shareLink(this.params.lang, this.params.uh, this.params.nicename, 'linkedin', customDomain);
    // see https://developer.linkedin.com/docs/share-on-linkedin
    return (
      'https://www.linkedin.com/shareArticle?mini=true' +
      '&url=' +
      encodeURIComponent(link) +
      '&title=' +
      encodeURIComponent(name.substring(0, 190)) +
      '&summary=' +
      encodeURIComponent(description.substring(0, 246))
    );
  }

  public shareLinkTwitter(name, description, tags, customDomain: string | null) {
    const link = this.shareLink(this.params.lang, this.params.uh, this.params.nicename, 'twitter', customDomain);
    // see https://dev.twitter.com/web/tweet-button/web-intent
    const lineBreak = '%0a';
    let data =
      'https://twitter.com/intent/tweet?' +
      '&text=' +
      encodeURIComponent(name) +
      lineBreak +
      description.substring(0, 85) +
      lineBreak +
      '&url=' +
      encodeURIComponent(link);
    if (environment.TWITTER_USER_NAME) {
      data += '&via=' + encodeURIComponent(environment.TWITTER_USER_NAME);
    }
    if (tags) {
      data += '&hashtags=' + encodeURIComponent(tags);
    }
    return data;
  }

  public shareLink(lang, uHash, niceName, social, customDomain: string | null = null) {
    if (customDomain) {
      return `${customDomain}/${lang}/diploma/${uHash}`;
    }

    return `${environment.APP_URL}/${lang}/diploma/${uHash}/${niceName}/${social}`;
  }

  public trackLink = (link, templateId) => {
    this.trackLinks = new TrackLinks(
      environment.APP_URL + this.router.url,
      link,
      localStorage.getItem('browserSession'),
      templateId
    );
    this.trackLinksService.trackOutgoingLinks(this.trackLinks).subscribe(
      (data) => (this.datas = data),
      (error) => (this.errorMessage = error)
    );
  };

  public htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  public get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  public get userHash(): string {
    if (localStorage.getItem('userHash')) {
      return localStorage.getItem('userHash');
    }
    return '';
  }

  get user2faToken(): string | null {
    if (localStorage.getItem('currentUser2FA')) {
      const user2faDetails = JSON.parse(localStorage.getItem('currentUser2FA'));
      if (user2faDetails.token) {
        return user2faDetails.token;
      }
    }
    return null;
  }

  get openIdSession() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail.scope === 'openid';
      }
    }
    return false;
  }

  public openModel = (content, css, data = {}) => {
    if (css) {
      const modalRef = this.modalService.open(content, { windowClass: css });
      modalRef.componentInstance.data = data;
    } else {
      const modalRef = this.modalService.open(content, {});
      modalRef.componentInstance.data = data;
    }
  };

  /**
   * On click add to profile button
   *
   * @param mediaKey
   * @param data
   */
  public addToSocialProfile = (mediaKey: string, data: any) => {
    let url = '';

    if (mediaKey === 'LinkedinProfile') {
      url = this.diplomaService.linkedinAddToProfile(
        data?.diploma.diploma_name,
        data.organization.name,
        data?.diploma.issue_date,
        data?.diploma.expiry_date,
        data.organization?.linkedinId
      );

      this.trackShareData(mediaKey);
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  /**
   * Track add to profile click
   *
   * @param media
   */
  private trackShareData(media) {
    const accessToken = this.userDetails.accessToken;
    this.share = new Share(this.params.uh, media);
    this.diplomaService
      .shareData(this.share, accessToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => (this.result = result),
        (error) => (this.errorMessage = error)
      );
  }

  private adminView(): void {
    this.userService
      .adminHasDiplomaAccess(this.params.userHash, this.params.uh)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (res.data.hasAccess) {
            this.checkViewType(this.params.uh, this.userDetails.accessToken);
          } else {
            this.twoFactorError('Permissions denied to view diploma');
          }
        },
        () => {
          this.twoFactorError('Permissions denied to view diploma');
        }
      );
  }

  private diplomaUserNotLogged(): void {
    this.checkAccessLevelAndGetDiplomaInfo(
      this.params.uh,
      this.userHash,
      this.params.lang,
      this.userDetails.accessToken
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (diplomaAccessLevel: DiplomaAccessLevel) => this.handdleAccessLevelAndGoalPage(diplomaAccessLevel),
        () => this.twoFactorError('Diploma not found!')
      );
  }

  private diplomaUserLogged(): void {
    forkJoin([
      this.checkAccessLevelAndGetDiplomaInfo(
        this.params.uh,
        this.userHash,
        this.params.lang,
        this.userDetails.accessToken
      ),
      this.withGoalSelectionPage(this.params.uh, this.userDetails.accessToken)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([diplomaAccessLevel, checkClaimGoalSelection]) => {
        this.handdleAccessLevelAndGoalPage(diplomaAccessLevel, checkClaimGoalSelection);
      });
  }

  private handdleAccessLevelAndGoalPage(
    diplomaAccessLevel: DiplomaAccessLevel,
    checkClaimGoalSelection: CheckClaimGoalSelection | null = null
  ): void {
    this.diplomaAccessLevel = diplomaAccessLevel;
    this.withGoalSelection =
      checkClaimGoalSelection &&
      checkClaimGoalSelection.data.displayGoalSelectPage &&
      checkClaimGoalSelection.data.hasGoals;

    if (diplomaAccessLevel.access_level === 0) {
      this.checkViewType(this.params.uh, this.userDetails.accessToken);

      return;
    }

    this.withAccessLevel = true;
    if (diplomaAccessLevel.same_user && this.user2faToken) {
      this.validate2faToken(this.userHash, this.user2faToken, this.userDetails.accessToken)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.withAccessLevel = !res.data.isValid;
        });
    }
    this.checkViewType(this.params.uh, this.userDetails.accessToken);
  }

  /**
   *  Prepare change bundle  diploma metadata
   * @param data
   * @private
   */
  private prepareMetadata(data: DiplomaPresentationData): SeoAndMetaTagsInterface {
    return {
      title: data.diploma_name,
      description: data.diploma_description_plain ? data.diploma_description_plain : environment.SEO_DESCRIPTION,
      image: data.diploma_image
    } as SeoAndMetaTagsInterface;
  }
}
