<app-design-view-header-background
  [diplomaDesign]='design'
  *ngIf='design; else genericBackground'
>
</app-design-view-header-background>
<ng-template #genericBackground>
  <app-design-view-header-background></app-design-view-header-background>
</ng-template>
<ng-container>
  <ng-container *ngIf='data'>
    <app-design-view-header [smallResolution]='true' [userMenu]='false'
                            [customSuportLink]='data.custom_support_page_link'></app-design-view-header>
  </ng-container>
</ng-container>

<div class='container'>
  <div class='row flex-lg-row'>
    <div class='pl-0 pr-0 middle-col'>
      <div class='diploma-information mx-auto'>
        <div class='info-header'></div>
        <div *ngIf='errorMessage; else diplomaPart'>
          <div class='diploma-online-presentation shadow'>
            <div class='diploma-main'>
              <div class='alert alert-danger'>
                {{ errorMessage}}
              </div>

            </div>
          </div>
        </div>
        <ng-template #diplomaPart>
          <div class='diploma-presentation shadow' *ngIf='data as diploma; else loadingPart'>
            <div class='diploma-online-presentation shadow'>
              <flash-messages></flash-messages>
              <div class='diploma-main'>
                <div *ngIf='diploma.org_image' class='organization-image'>
                  <img [src]='diploma.org_image' alt='' />
                </div>
                <div class='diploma-info'>
                  <div class='title'>
                    {{ diploma.receiver_name }}
                  </div>
                  <div class='subtitle'>
                    {{
                    "/sender_name/ transferred a /template_type/ issued by /organization_name/ to you"
                      | translate: {
                      sender_name: diploma.transferring_user_name,
                      organization_name: diploma.issuer_name,
                      template_type: diploma.diploma_template_type
                    }
                    }}
                  </div>
                  <div class='course_title'>
                    {{ diploma.course_title }}
                  </div>

                  <div *ngIf='diploma.login; else claim'>
                  <app-transfer-login [lang]='langVal'></app-transfer-login>
                  </div>
                  <ng-template #claim>
                    <div *ngIf='!diploma.registered; else registered'>
                      <app-transfer-unregistered
                        [diplomaTemplateType]='diploma.diploma_template_type'
                        (claimRegister)='processClaim(true)'
                      ></app-transfer-unregistered>
                    </div>
                    <ng-template #registered>
                      <app-transfer-registered
                        [diplomaTemplateType]='diploma.diploma_template_type'
                        [sameUser]='diploma.same_user'
                        [receiverMail]='diploma.receiver_email'
                        [userMail]='diploma.user_email'
                        (claim)='processClaim(false)'
                        (logout)='logout()'
                      ></app-transfer-registered>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <ng-template #loadingPart>
            <div class='row justify-content-md-center loader'>
              <div class='col-md-8 col-lg-6'>
                <div class='white-box margin-top-inverse-85 loading-page text-center'>
                  <h1 class='margin-bottom-30 green-color'>
                    <i class='fa fa-spinner fa-pulse fa-3x fa-fw green-cl'></i>
                    <span class='sr-only'>{{ "Loading" | translate }}...</span>
                  </h1>
                  <p>{{ "Loading" | translate }}... {{ "Please Wait" | translate }}...</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <app-design-view-footer class='show-above-1000'></app-design-view-footer>
    </div>
    <div class='header-wrapper col-xl-2 col-lg-3 col-md-12 col-sm-12'>
      <ng-container *ngIf='data else null'>
        <app-design-view-header [userMenu]='false' [helpGrow]='false'
                                [customSuportLink]='data?.custom_support_page_link'></app-design-view-header>
      </ng-container>
    </div>
  </div>
</div>
<app-design-view-footer class='hide-below-1000'></app-design-view-footer>


