import { Injectable } from '@angular/core';
import { DiplomaPresentationData } from 'app/core/models/diploma';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { FacebookService } from '../../socials/facebook.service';
import { LinkedinService } from '../../socials/linkedin.service';
import { TwitterService } from '../../socials/twitter.service';
import { ShareService } from '../../util/share.service';

@Injectable({
  providedIn: 'root',
})
export class SocialShareService extends ShareService {
  constructor(
    private facebookService: FacebookService,
    private linkedinService: LinkedinService,
    private twitterService: TwitterService,
    private cookieService: CookieService
  ) {
    super();
  }

  public socialShareInit(): void {
    this.facebookService.initFacebook();
  }

  public checkAccessToken(socialType: string): Observable<any> {
    if (socialType == 'linkedin') {
      return this.linkedinService.checkAccessToken();
    }
  }

  public socialShare(socialType: string, lang: string, diploma: DiplomaPresentationData): void {
    const { diploma_name, diploma_description_plain, diploma_tags } = diploma;

    const link = this.generateShareLink(socialType, diploma, lang);

    if (socialType == 'facebook') {
      this.facebookShare(link, diploma_tags);

      return;
    }

    if (socialType == 'linkedin') {
      this.linkedinShare(link, diploma_name, diploma_description_plain);

      return;
    }

    this.twitterShare(link, diploma_name, diploma_description_plain, diploma_tags);
  }

  public directShare(
    socialType: string,
    lang: string,
    diploma: DiplomaPresentationData,
    userSocialId: string
  ): Observable<any> {
    const link = this.generateShareLink(socialType, diploma, lang);

    return this.linkedinService.directShare(userSocialId, link, diploma);
  }

  private facebookShare(link: string, tags: string | null): void {
    this.facebookService.share(link, tags);
  }

  private linkedinShare(link: string, name: string, description: string): void {
    this.linkedinService.share(link, name, description);
  }

  private twitterShare(link: string, name: string, description: string, tags: string | null): void {
    this.twitterService.share(link, name, description, tags);
  }

  public async linkedinAccessToken(): Promise<void> {
    await this.linkedinService.getAccessToken();
  }

  private generateShareLink(socialType: string, diploma: DiplomaPresentationData, lang: string): string {
    const { diploma_hash, diploma_nice_name, diploma_custom_domain } = diploma;

    return this.shareLink(lang, diploma_hash, diploma_nice_name, socialType, diploma_custom_domain);
  }
}
