import { ActivatedRoute, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlUtilService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public getCurrentUrl(): string {
    const currentUrl = this.router.url.split('?')[0];
    return currentUrl.slice(6, 250);
  }

  public customDomainRedirectToLogin(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (this.document.location.origin !== environment.APP_URL) {
        const queryParams = new URLSearchParams(params).toString();

        this.document.location.href = `${environment.APP_URL}${this.document.location.pathname}${
          queryParams ? `?${queryParams}` : ''
        }`;
      }
    });
  }

  public getCurrentUrlForDiploma(diplomaCustomDomain: string | null): string {
    return diplomaCustomDomain || environment.APP_URL;
  }

  public checkLoginPage(): boolean {
    return this.router.url.slice(7, 12) === 'login' ? true : false;
  }

  public checkHelpPage(): boolean {
    return this.router.url.slice(7, 12) === 'help' ? true : false;
  }

  public checkRegisterPage(uhash: string | null, id: number | null): string | boolean {
    return this.router.url.slice(7, 15) === 'register' && uhash && id ? `/${uhash}/${id}` : false;
  }

  public checkLocalStorageSupports(langVal: string) {
    try {
      const mod = 'modernizr';
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);

      return typeof Storage !== 'undefined' ? 'supported' : this.router.navigate([langVal + '/storage-not-supported']);
    } catch (error) {
      return this.router.navigate([langVal + '/storage-not-supported']);
    }
  }
}
