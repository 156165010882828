import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShareViewService} from "../../../core/services/share-view/share-view.service";
import { Location } from '@angular/common';
import {ActivatedRoute} from "@angular/router";

@Component({
    providers: [ShareViewService],
    selector: 'app-share-view-password-prompt',
    templateUrl: './share-view-password-prompt.component.html',
    styleUrls: ['./share-view-password-prompt.component.css'],
})
export class ShareViewPasswordPromptComponent implements OnInit, OnDestroy {

    @ViewChild('content',{static: false}) content: any;
    @Output() passwordCheck: EventEmitter<boolean>;
    public form: FormGroup;
    private data: string;
    private modalRef: MatDialogRef<any>;
    private tries: number;
    private params = this.activatedRoute.snapshot.params;

    constructor(private modal: MatDialog,
                private formBuilder: FormBuilder,
                private shareViewService: ShareViewService,
                private location: Location,
                private activatedRoute: ActivatedRoute) {
        this.form = this.formBuilder.group({
            password: ['', [Validators.required]],
        });
        this.passwordCheck = new EventEmitter<boolean>();
        this.tries = 0;
    }

    ngOnInit() {
    }

    public submitPassword() {
        const session = localStorage.getItem('browserSession');
        const subscription = this.shareViewService.sendPassword(this.password().value, this.data, session)
            .subscribe((res) => {
                this.tries = res.count;
                if (res.result) {
                    this.emitPasswordCheck(res.result);
                    this.modalRef.close();
                } else if (this.tries < 3) {
                    this.password().setErrors({wrongPassword: true});
                } else {
                    this.emitPasswordCheck(res.result);
                    this.modalRef.close();
                }
                this.shareViewService.addSubscription(subscription);
            },
            () => {
                this.shareViewService.addSubscription(subscription);
                this.shareViewService.handleError(this.params.lang);
            });
    }
    public emitPasswordCheck(result) {
      this.passwordCheck.emit(result);
    }

    public password() {
        return this.form.controls.password;
    }

    public back() {
        this.location.back();
    }

    public open(uHash) {
        this.data = uHash;
        this.modalRef = this.modal.open(this.content, {
            hasBackdrop: true,
            disableClose: true,
            height: '250px',
            width: '400px',
        });
    }

    public ngOnDestroy(): void {
        this.shareViewService.destroySubscriptions();
    }
}
