<div id='content' *ngIf="this.sameUser else warningButton">
    <div *ngIf="!this.isBundle else bundleClaimButton">
        <div class="claim-button-wrap">
       <button
        (click)="this.claimDiploma()"
        type="button"
        class="btn claim-button btn-primary">
        {{ 'Claim /type/' | translate:{type: this.diplomaType} }}
    </button>
        </div>
        <app-wrong-name-request-button [lang]="lang" [templateType]="this.diplomaType" *ngIf="hasRegisteredSupportEmail"></app-wrong-name-request-button>
    </div>
    <ng-template #bundleClaimButton>
        <button
            (click)="this.claimBundleEvent()"
            type="button"
            class="btn claim-button btn-primary">
            {{ 'Claim /type/' | translate:{type: this.bundleData.type} }}
        </button>
    </ng-template>
</div>
<ng-template #warningButton>
    <div id='content'>
        <div class="warning-text flex-row">
            <div class="flex-column">
                <i class="fa fa-exclamation-circle"></i>
            </div>
            <div class="flex-column">
                {{'Issued to e-mail'| translate}}: {{this.receiverMail}},
                {{'but you are logged in with' | translate}}: {{ this.userMail }}
            </div>
        </div>
        <div>
            <button
                *ngIf="!this.isBundle else bundleWarningButton"
                (click)="this.claimDiploma()"
                type="button"
                class="btn claim-button btn-warning">
                {{ "That's OK, claim /type/ anyway" | translate:{type: this.diplomaType} }}
            </button>
            <ng-template #bundleWarningButton>
                <button
                (click)="this.claimBundleEvent()"
                type="button"
                class="btn claim-button btn-warning">
                {{ "That's OK, claim /type/ anyway" | translate:{type: this.bundleData.type} }}
                </button>
            </ng-template>
        </div>
        <div class="warning-link">
            <a (click)="this.onLogout()">{{'Not correct! Log out and start over' | translate}} >></a>
        </div>
    </div>
</ng-template>
