<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row flex-lg-row">
    <div class="pl-0 pr-0 middle-col">
      <div class="white-box margin-top-inverse-85">
          <h1 class="margin-bottom-30">{{ 'Select Goal' | translate }}</h1>
          <flash-messages></flash-messages>
          <app-pathway-settings-base
            [isEdit]="true"
            [diplomaHash]="diplomaHash"
            (onSave)="handlePathwaySettingsUpdate($event)"
          ></app-pathway-settings-base>
      </div>
      <app-design-view-footer class="show-above-1000"></app-design-view-footer>
    </div>
  </div>
</main>
