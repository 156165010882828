import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FrequentQuestion } from 'app/core/models/FrequentQuestion';
import { FrequentQuestionsService } from 'app/core/services/help/frequent-questions.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.css']
})
export class FrequentQuestionsComponent implements OnInit, OnDestroy {

  public questions: Observable<FrequentQuestion[]>;
  private lang: string;
  private browserSession: string;

  constructor(private frequentQuestionService: FrequentQuestionsService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.questions = this.frequentQuestionService.getQuestions();
    this.lang = this.route.snapshot.params.lang;
    this.browserSession = localStorage.getItem('browserSession');
  }

  ngOnInit() {
    this.fetchQuestions();
  }

  ngOnDestroy(): void {
    this.frequentQuestionService.destroySubscriptions();
  }

  public fetchQuestions() {
    this.frequentQuestionService.fetchQuestions(this.lang)
  }

  public hitQuestion(event, questionId: number) {
    if (event.currentTarget.attributes['aria-expanded'].value === 'false') {
      this.frequentQuestionService.generateQuestionHit(this.lang, questionId, this.browserSession);
    }
  }
}
