import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BundleListing } from 'app/core/models/bundle';
import { FormValidationService } from 'app/core/services/common-service/fromvalidation.service';

@Component({
  providers: [FormValidationService],
  selector: 'app-claim-unregistered-email-prompt',
  templateUrl: './claim-unregistered-email-prompt.component.html',
  styleUrls: ['./claim-unregistered-email-prompt.component.css'],
})
export class ClaimUnregisteredEmailPromptComponent implements OnInit {
  public data = {
    email: null,
    reEmail: null,
  };

  public emailFormControl = this.formValidationService.emailValidation();

  termsAccepted: boolean;
  warningHidden: boolean;

  @Input() public claimFlowEmailPromptDescription: String;
  @Input() public diplomaType: String;
  @Input() public bundleData: BundleListing | null;
  @Input() public isBundle: boolean;
  @Input() public lang: string;
  @Input() public hasRegisteredSupportEmail: string;

  @Output() public claimRegisterWithEmail: EventEmitter<string> = new EventEmitter<string>();

  constructor(private formValidationService: FormValidationService) {}

  ngOnInit() {}

  public claimRegister(): void {
    if (!this.emailIsValid()) {
      return;
    }

    this.claimRegisterWithEmail.emit(this.data.email);
  }

  private emailIsValid(): boolean {
    if (
      this.emailFormControl.hasError('required') ||
      this.emailFormControl.hasError('pattern') ||
      this.data.email !== this.data.reEmail
    ) {
      return false;
    }

    return true;
  }
}
