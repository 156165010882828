<app-header [inheritedLanguage]="this.language"></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 text-center">
        <div class="margin-bottom-30 red-color" id="not-found-title">
          {{ 'Different Session Present' | translate }}
        </div>
        <div class="margin-bottom-30" id="not-found-information">
          {{ 'Current session does not give access to the requested documents. Possible acctions:' | translate }}
          <ul>
            <li>
              {{ 'Switch to new session, which gives access to requested documents' | translate }}
            </li>
            <li>
              {{ 'Proceed with the current session' | translate}}
            </li>
          </ul>
        </div>
            <button class="btn btn-success text-uppercase" id="switch-session" (click)="this.confirmLogin()">{{ 'Switch sessions' | translate }}</button> <br>
            <button class="btn btn-warning text-uppercase" id="current-session" (click)="this.navigateToDashboard()">{{ 'Proceed with current session' | translate }}</button>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer> 
