<div *ngIf="this.questions | async as questions">
  <span *ngIf="questions.length > 0">{{ 'Check if your question is already listed with an answer here:' | translate }}</span>
  <div class="item-wrapper" *ngIf="questions.length > 0">
    <div class="content-box" *ngFor="let question of questions">
      <a data-toggle="collapse" [href]="'#answerBody_' + question.id" [attr.data-target]="'#answerBody_' + question.id" role="button" aria-expanded="false" [attr.aria-controls]="'answerBody_' + question.id" (click)="this.hitQuestion($event, question.id)">
        <div class="box-header d-flex align-items-center justify-content-between">
          <h4 [innerHTML]="question.question"></h4>
          <i class="fa fa-minus"></i>
          <i class="fa fa-plus"></i>
        </div>
      </a>
      <div class="collapse" [id]="'answerBody_' + question.id">
        <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(question.answer)">
        </p>
      </div>
    </div>
  </div>
</div>
