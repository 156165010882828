<ng-container *ngIf='this.data | async as data;else loadingComponent'>
  <app-pathway-widget-content *ngFor='let pathway of data?.pathways' [pathway]='pathway'></app-pathway-widget-content>
</ng-container>

<ng-template #loadingComponent>
  <article class='pathway-container'>
    <div class='loading'>
      <i class='fa fa-spinner fa-pulse fa-3x fa-fw green-cl'></i>
    </div>
  </article>
</ng-template>

