import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.css']
})
export class MaintenancePageComponent {

  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

}
