import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable, throwError } from "rxjs";

import { FileData } from "../../models/dashboard/filedata";
import { Profile } from "../../models/dashboard/profile";

import { environment } from "../../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class ProfileService {
  private apiGetUrl = environment.API_START_URL + "api/getUserDetails";
  private apiGetProfileDetailUrl =
    environment.API_START_URL + "api/getUserDetail";

  private apiUpdateEmailUrl = environment.API_START_URL + "api/updateEmail";
  private apiPostImageUrl = environment.API_START_URL + "api/uploadProfilePic";
  private apiGetCountries = environment.API_START_URL + "api/getCountries";
  private apiGetCountryById = environment.API_START_URL + "api/getCountryById";
  private apilinkFacebook = environment.API_START_URL + "api/linkFacebook";

  constructor(private http: HttpClient, private loginService: LoginService) {}

  public getCountries(accessToken): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.http
      .get(this.apiGetCountries, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public getCountryById(countryId, accessToken): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.http
      .get(this.apiGetCountryById + "/" + countryId, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public getUserDetails(accessToken): Observable<Profile[] | {}> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.http
      .get(this.apiGetUrl, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public getUserDetail(accessToken): Observable<Profile[] | {}> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.http
      .get(this.apiGetProfileDetailUrl, { headers: header })
      .map(this.extractData);
  }

  public linkFacebook(
    body: any,
    accessToken,
    lang
  ): Observable<Profile[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.http
      .post(this.apilinkFacebook + "/" + lang, body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public updateProfile(body: any, lang: string, accessToken): Observable<Profile[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });

    return this.http
      .post(`${environment.API_START_URL}api/${lang}/editProfile` , body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public updateEmail(body: any, accessToken, lang): Observable<Profile[] | {}> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.http
      .post(this.apiUpdateEmailUrl + "/" + lang, body, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public updateProfilePic(
    formData: any,
    accessToken
  ): Observable<FileData[] | {}> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.http
      .post(this.apiPostImageUrl, formData, { headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: HttpResponse<any>) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
