import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TwitterService {
  constructor() {}

  public share(link: string, name: string, description: string, tags: string | null): void {
    // see https://dev.twitter.com/web/tweet-button/web-intent
    const lineBreak = '%0a';
    let data =
      'https://twitter.com/intent/tweet?' +
      '&text=' +
      encodeURIComponent(name) +
      lineBreak +
      description.substring(0, 85) +
      lineBreak +
      '&url=' +
      encodeURIComponent(link);

    if (environment.TWITTER_USER_NAME) {
      data += '&via=' + encodeURIComponent(environment.TWITTER_USER_NAME);
    }
    if (tags) {
      data += '&hashtags=' + encodeURIComponent(tags);
    }

    window.open(data + '&source=Diplomasafe', 'sharer', 'toolbar = no, status = 0, width = 548, height = 400');
  }
}
