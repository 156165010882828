import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-view-loading',
  templateUrl: './design-view-loading.component.html',
  styleUrls: ['./design-view-loading.component.css']
})
export class DesignViewLoadingComponent implements OnInit {

  @Input() specialShareLoad: boolean;
  @Input() visibleText: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
