import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Subscription, Observable, Subject, throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Login } from "app/core/models/login";
import { env } from "process";
import { DiplomaService } from "../diploma/diploma.service";
import {
  HttpRequest,
  HttpHeaders,
  HttpClient,
  HttpResponse,
} from "@angular/common/http";
import { LoginService } from "app/core/services/login/login.service";

@Injectable({
  providedIn: "root",
})
export class OpenIdServiceService {
  private apiOpenIdLogin = environment.API_START_URL + "api/openidLogin";
  private apiOpenIdConfirmLogin =
    environment.API_START_URL + "api/openidConfirmLogin";
  private apiOpenIdEndpoint = environment.API_START_URL + "api/openid/";
  private apiCheckClaimSession =
    environment.API_START_URL + "api/checkClaimSession";
  private differentSessionHash: Subject<string>;

  public subcritions: Subscription[];
  public login = new Login(
    environment.CLIENT_ID,
    environment.CLIENT_SECRET,
    environment.GRANT_TYPE_PASSWORD,
    "",
    "",
    "",
    ""
  );

  constructor(
    private http: HttpClient,
    private diplomaService: DiplomaService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {
    this.differentSessionHash = new Subject<string>();
    this.subcritions = [];
  }

  public openIdInit(hash: string, lang: string) {
    const accessToken = this.userDetails.accessToken;
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });

    const $subscription = this.http
      .get(this.apiOpenIdEndpoint + hash, { headers: header })
      .subscribe(
        (data) => {
          this.subcritions.push($subscription);
        },
        (err) => {
          if (err.status == 401) {
            const $secondSubscription = this.http
              .get(this.apiOpenIdEndpoint + hash)
              .subscribe(
                () => {
                  this.subcritions.push($secondSubscription);
                },
                (err) => this.handleError(err, lang)
              );
          } else {
            this.handleError(err, lang);
          }
        }
      );
  }

  public openId(hash: string, lang: string) {
    const accessToken = this.userDetails.accessToken;
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    const $subscription = this.http
      .post(
        this.makeLoginUrl(),
        {
          hash: hash,
        },
        {
          headers: header,
        }
      )
      .map((res: HttpResponse<any>) => this.extractData(res))
      .subscribe(
        (data) => {
          this.subcritions.push($subscription);
          this.handleLoginResponse(data, lang);
        },
        (err) => {
          if (err.status == 401) {
            localStorage.clear();
            const $secondSubscription = this.http
              .post(this.makeLoginUrl(), {
                hash: hash,
              })
              .map((res: HttpResponse<any>) => this.extractData(res))
              .subscribe(
                (data) => {
                  this.subcritions.push($secondSubscription);
                  this.handleLoginResponse(data, lang);
                },
                (err) => this.handleError(err, lang)
              );
          } else {
            this.handleError(err, lang);
          }
        }
      );
  }

  public openIdLogin(data, lang) {
    try {
      localStorage.removeItem("currentUser");
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          accessToken: data.access_token,
          expiresIn: data.expires_in,
          refreshToken: data.refresh_token,
          tokenType: data.token_type,
          scope: "openid",
        })
      );
      this.getUserData(data.access_token, lang);
    } catch (error) {
      return throwError(error);
    }
  }

  public deleteSubscription() {
    for (let subscription of this.subcritions) {
      subscription.unsubscribe();
    }
  }

  public getUserData(accessToken, lang) {
    this.diplomaService.getUserData(accessToken).subscribe(
      (datas) => this.initUserData(datas, lang),
      (err) => this.handleError(err, lang)
    );
  }

  public sendConfirmLogin(lang: string, hash: string) {
    const accessToken = this.userDetails.accessToken;
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    const $subscription = this.http
      .post(
        this.apiOpenIdConfirmLogin,
        {
          hash: hash,
        },
        { headers: header }
      )
      .map((res: HttpResponse<any>) => this.extractData(res))
      .subscribe(
        (data) => {
          this.subcritions.push($subscription);
          this.openIdLogin(data, lang);
        },
        (err) => this.handleError(err, lang)
      );
  }

  public initUserData(datas, lang) {
    try {
      localStorage.removeItem("userHash");
      localStorage.setItem("userHash", datas.userHash);
      const accessToken = this.userDetails.accessToken;
      const header = new HttpHeaders({
        Authorization: "Bearer " + accessToken,
      });
      const session = localStorage.getItem("browserSession");
      const $subscription = this.http
        .post(
          this.apiCheckClaimSession,
          {
            session,
          },
          { headers: header }
        )
        .map((res: HttpResponse<any>) => this.extractData(res))
        .subscribe(
          (res) => {
            this.subcritions.push($subscription);
            if (res.success) {
              const prefix = res.bundle ? "bclaim" : "claim";
              this.router.navigate([lang, prefix, res.h, res.e]);
            } else {
              this.router.navigate([lang + "/dashboard"]);
            }
          },
          (err) => this.handleError(err, lang)
        );
    } catch (error) {
      return throwError(error);
    }
  }

  get userDetails() {
    if (localStorage.getItem("currentUser")) {
      const userDetail = JSON.parse(localStorage.getItem("currentUser"));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  public differentSessionHashObservable(): Observable<string> {
    return this.differentSessionHash.asObservable();
  }

  private handleLoginResponse(data, lang) {
    if (data.same_client) {
      this.router.navigate([lang + "/dashboard"]);
    } else if (!data.same_client && !data.access_token) {
      this.differentSessionHash.next(data.hash);
    } else {
      this.openIdLogin(data, lang);
    }
  }

  private extractData(res) {
    return res || {};
  }

  private makeLoginUrl(): string {
    let suffix = "";
    if (this.userDetails) {
      suffix = "/1";
    }

    return this.apiOpenIdLogin + suffix;
  }

  private handleError(error: HttpResponse<any> | any, lang) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      } else if (error.status === 404) {
        this.router.navigate([lang + "/not-found"]);
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
