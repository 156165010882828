import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-view-footer',
  templateUrl: './design-view-footer.component.html',
  styleUrls: ['./design-view-footer.component.css']
})
export class DesignViewFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
