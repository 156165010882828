import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FrequentQuestion } from 'app/core/models/FrequentQuestion';
import { environment } from 'environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';

const GET_QUESTIONS = (langCode: string) => environment.API_START_URL + `api/fetch-questions/${langCode}`;
const HIT_QUESTION = () => environment.API_START_URL + `api/question-hit`;

@Injectable({
  providedIn: 'root'
})
export class FrequentQuestionsService {

  private questions: Subject<FrequentQuestion[]>;
  private subscriptions: Subscription[];

  constructor(
    private httpClient: HttpClient
  ) { 
    this.questions = new Subject<FrequentQuestion[]>();
    this.subscriptions = [];
  }

  public fetchQuestions(lang: string) {
    const subscription = this.httpClient.get<{status: string, data: FrequentQuestion[]}>(GET_QUESTIONS(lang))
      .subscribe(
        res => {
          this.subscriptions.push(subscription);
          this.questions.next(res.data);
        },
        err => {
          this.subscriptions.push(subscription);
        }
      )
  }

  public generateQuestionHit(lang: string, questionId: number, browserSession: string) {
    const subscription = this.httpClient.post(HIT_QUESTION(), {
      question_id: questionId,
      lang_code: lang,
      session: browserSession,
    })
      .subscribe(res => {
        this.subscriptions.push(subscription);
      },
      err => {
        this.subscriptions.push(subscription);
      });
  }

  public getQuestions(): Observable<FrequentQuestion[]> {
    return this.questions.asObservable();
  }

  public destroySubscriptions() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions = [];
  }
}
