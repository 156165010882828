import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BundleListing } from 'app/core/models/bundle';

@Component({
  selector: 'app-claim-registered',
  templateUrl: './claim-registered.component.html',
  styleUrls: ['./claim-registered.component.css']
})
export class ClaimRegisteredComponent implements OnInit {

  @Input() public diplomaType: String;
  @Input() public sameUser: String;
  @Input() public receiverMail: String;
  @Input() public userMail: String;
  @Input() public isBundle: boolean = false;
  @Input() public bundleData: BundleListing|null = null;
  @Input() public accessLevel: boolean;
  @Input() public lang: string;
  @Input() public hasRegisteredSupportEmail:string;

  @Output() public claim: EventEmitter<boolean>;
  @Output() public claimBundle: EventEmitter<void>;
  @Output() public logout: EventEmitter<boolean>;
  @Output() public withAccessLevel: EventEmitter<boolean>;

  constructor() { 
    this.claim = new EventEmitter<boolean>();
    this.claimBundle = new EventEmitter<void>();
    this.logout = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  public claimDiploma() {
    this.accessLevel 
      ? this.withAccessLevel.emit(true)
      : this.claim.emit(true);
  }

  public claimBundleEvent() {
    this.accessLevel 
      ? this.withAccessLevel.emit(true)
      : this.claimBundle.emit();
  }

  public onLogout(event: Event) {
    this.logout.emit(true);
  }
}
