import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

//Design view (PDF)

@Component({
  selector: 'app-diploma-design-view-image',
  templateUrl: './diploma-design-view-image.component.html',
  styleUrls: ['./diploma-design-view-image.component.css']
})
export class DiplomaDesignViewImageComponent implements OnInit {

  @Input() public views: any;
  @Input() public initialPage: any;
  @Input() public openModel: any;
  @Input() public sameUser: any;
  @Input() public setSeeMore: any;
  public pageNumber: number;

  constructor(public sanitizer: DomSanitizer) {
  }

  public ngOnInit() {
    this.pageNumber = this.initialPage;
    this.setSeeMore(null);
  }

  public prevPage() {
    if (this.pageNumber <= 0) {
      return;
    }
    this.pageNumber--;
  }

  public nextPage() {
    if (this.pageNumber >= this.views.length - 1) {
      return;
    }
    this.pageNumber++;
  }
}
