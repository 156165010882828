import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../services/maintenance/maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class IsMaintenanceEnabledGuard implements CanActivate {
  constructor(private router: Router, private maintenanceService: MaintenanceService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (this.maintenanceService.isInMaintenanceMode()) {
      return true;
    }
    this.router.navigate(['/']);

    return false;
  }
}
