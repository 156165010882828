<div class="row justify-content-md-center">
    <div class="white-box margin-top-inverse-85 loading-page text-center">
        <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
        </h1>
        <p>
            {{ 'Loading... Please Wait' | translate }}...
        </p>
    </div>
</div>
