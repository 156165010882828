import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/services/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { TrackLinks } from '../../core/services/common-service/tracklinks';
import { TrackLinksService } from '../../core/services/common-service/tracklinks.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  providers: [TrackLinksService, UserService],
  selector: 'app-organization',
  styleUrls: ['./user.component.css'],
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit, OnDestroy {
  public data: any;
  public datas: any;
  public result: any;
  private errorMessage: string;
  private params: any = this.activatedRoute.snapshot.params;
  private trackLinks = new TrackLinks('', '', '', '');
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private trackLinksService: TrackLinksService
  ) {
  }

  public ngOnInit() {
    this.getUserData(this.params.id);
    this.getUserDiploma(this.params.id);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getUserData(id) {
    this.userService
      .getUserData(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => this.data = data,
        (error) => (this.errorMessage = error)
      );
  }

  public getUserDiploma(id) {
    this.userService
      .getUserDiploma(id, this.params.lang)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => this.initDiplomaData(result),
        (error) => (this.errorMessage = error)
      );
  }


  public initDiplomaData(result) {
    this.result = result;
  }

  public trackLink(link) {
    this.trackLinks = new TrackLinks(
      environment.APP_URL + this.router.url,
      link,
      localStorage.getItem('browserSession'),
      ''
    );
    this.trackLinksService
      .trackOutgoingLinks(this.trackLinks)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => (this.datas = data),
        (error) => (this.errorMessage = error)
      );
  }

  get language() {
    return this.cookieService.get('langVal') || this.params.lang;
  }
}
