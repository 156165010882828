import { environment } from 'environments/environment';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UIParams, UIResponse } from 'ngx-facebook';
import { ShareService } from '../util/share.service';

declare let FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookService extends ShareService {
  private fbParams: UIParams;

  constructor() {
    super();
  }

  public initFacebook() {
    FB.init({
      appId: environment.FB_APP_ID,
      cookie: true,
      xfbml: environment.FB_XFBML,
      version: environment.FB_API_VERSION,
    });
  }

  public facebookLogin(): Observable<any> {
    // login with facebook and return observable with fb access token on success
    return from(new Promise<any>((resolve) => FB.login(resolve, { scope: 'email' }))).pipe(
      map(({ authResponse }) => {
        return authResponse ? authResponse.userID : '';
      })
    );
  }

  public getEmail(): Observable<any> {
    return from(new Promise<any>((resolve) => FB.api('/me ', { fields: 'email' }, resolve))).pipe(
      map((res: any) => {
        return res.email || '';
      })
    );
  }

  public share(link: string, tags: string): void {
    // https://developers.facebook.com/docs/sharing/reference/share-dialog
    this.fbParams = {
      href: link,
      method: 'share',
    };

    if (tags) {
      this.fbParams.hashtag = '#' + tags;
    }

    // Facebook ui init -
    FB.ui(this.fbParams, (res: UIResponse) => {
      // console.log('res', res);
    });

  }
}
