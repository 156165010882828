import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Login } from '../../core/models/login';
import { Meta } from '@angular/platform-browser';
import { LoginService } from '../../core/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { FormValidationService } from '../../core/services/common-service/fromvalidation.service';
import { SocialLoginService } from 'app/core/services/social-login/social-login.service';
import { FlashMessagesUtilService } from 'app/core/services/util/flash-messages-util.service';
import capitalizeFirstLetter from 'app/core/helpers/util/capitalize-first-letter';
import { UrlUtilService } from 'app/core/services/util/url-util.service';

@Component({
  providers: [LoginService, SocialLoginService, FormValidationService],
  selector: 'app-login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  public emailFormControl = this.formValidationService.emailValidation();
  public passwordFormControl = this.formValidationService.passwordValidation();

  public processing: boolean = false;
  public data: any;
  public error: any;
  public sub: any;
  public login = new Login(
    environment.CLIENT_ID,
    environment.CLIENT_SECRET,
    environment.GRANT_TYPE_PASSWORD,
    '',
    '',
    '',
    ''
  );

  private params: any = this.activatedRoute.snapshot.params;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private meta: Meta,
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private urlUtilService: UrlUtilService,
    private translateService: TranslateService,
    private formValidationService: FormValidationService,
    private flashMessagesUtilService: FlashMessagesUtilService
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });

    const langVal = this.params.lang ? this.params.lang : environment.DEFAULT_LANGUAGE;
    if (localStorage.getItem('currentUser')) {
      this.router.navigate([`${langVal}/dashboard`]);
    }
  }

  public ngOnInit() {
    this.urlUtilService.customDomainRedirectToLogin();

    // remove unwanted localstorage items
    localStorage.removeItem('returnUrl');
    localStorage.removeItem('diplomaDetails');
    localStorage.removeItem('tempUser');

    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params['social'] && params['social_type']) {
        this.login.uhash_social_link = params['social'];
        this.flashMessagesUtilService.flashMessages(
          'Hey there! Looks like your /socialType/ isn\'t connected yet. Simply sign in, and link it by heading to \'Alternative Logins\' in your settings.',
          'danger',
          10000,
          { socialType: capitalizeFirstLetter(params['social_type']) }
        );
      } else if (params['message'] === 'success_reset_password') {
        this.flashMessagesUtilService.flashMessages('Password is successfully changed.', 'success');
      }
    });

  }

  ngOnDestroy() {
    if (localStorage.getItem('currentUser')) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  public onLogin() {
    this.processing = true;

    this.loginService
      .makeLogin(this.login)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          res['user']
            ? this.loginService.login(res['user'])
            : this.initError('Incorrect Username or Password');
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  public onLoginProcessing($event: boolean) {
    this.processing = $event;
  }

  public initError(message: string): void {
    this.error = message;
    this.processing = false;

    this.flashMessagesUtilService.flashMessages(message, 'danger');
  }

  public formValidation() {
    const username = this.login.username;
    const password = this.login.password;

    return username !== '' && password !== '' ? true : false;
  }

  get diagnostic() {
    return JSON.stringify(this.login);
  }

  get language() {
    return this.params.lang;
  }
}
