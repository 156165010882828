import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { DiplomaService } from '../../core/services/diploma/diploma.service';

@Component({
  selector: 'app-diploma-short',
  styleUrls: ['./diploma.component.css'],
  templateUrl: './diplomashort.component.html',
})
export class DiplomaShortComponent implements OnInit {

  private errorMessage: string;
  private data: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;

  constructor(
    private diplomaService: DiplomaService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  public getFullURL(baseCode, type) {
    this.diplomaService.getFullURL(baseCode, this.params.lang)
                       .subscribe(
                       (data) => this.initData(data),
                       (error) =>  this.errorMessage = error);
  }

  public ngOnInit() {
    this.getFullURL(this.params.baseCode, this.params.type);
  }

  public initData(data) {
    if (data.error) {
      this.router.navigate([this.params.lang + '/not-found'], { state: { isDiploma: true }});
    } else {
      this.diplomaService.pushType(this.params.type);
      this.router.navigate([this.params.lang + '/diploma/' + data.diploma_hash + '/' + data.diploma_nice_name]);
    }
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }
}
