import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";

import { environment } from "../../../../environments/environment";
import { LoginService } from "app/core/services/login/login.service";

@Injectable()
export class UnsubscribeService {
  private apiUrl = environment.API_START_URL + "api/checkDiplomaUnsubscription";
  private apiConfirmUrl =
    environment.API_START_URL + "api/checkConfirmUnsubscription";
  private apiAcceptDiplomaUrl =
    environment.API_START_URL + "api/unsubscribeDiploma";

  constructor(
    private http: HttpClient,
    private router: Router,
    private loginService: LoginService
  ) {}

  public checkDiplomaUnsubscription(lang, uh, id): Observable<any> {
    return this.http
      .get(this.apiUrl + "/" + lang + "/" + uh + "/" + id)
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public checkConfirmUnubscription(lang, uh, id): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " });

    return this.http
      .get(this.apiConfirmUrl + "/" + lang + "/" + uh + "/" + id, {
        headers: header,
      })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public unsubscribeDiploma(lang, uh, id): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " });

    return this.http
      .get(this.apiAcceptDiplomaUrl + "/" + lang + "/" + uh + "/" + id, {
        headers: header,
      })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  private extractData(res) {
    return res || {};
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.loginService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
