<app-header [inheritedLanguage]="this.language"></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <div class="white-box margin-top-inverse-85 text-center">
        <div class="margin-bottom-30 red-color" id="not-found-title">
            {{ 'No certificates registered' | translate }}
        </div>
        <img src="../../assets/img/not-found-error.png" alt="">
        <div class="margin-bottom-30" id="not-found-information">
          {{ 'Records for the requested user don\'t exist. Possible causes:' | translate }}
          <ul>
            <li>
              {{ 'User has not received certificates from the requested issuer' | translate }}
            </li>
            <li>
              {{ 'There are unclaimed certificates. Please check your email' | translate}}
            </li>
          </ul>
        </div>
            <button class="btn btn-success text-uppercase" id="current-session" (click)="this.navigateToDashboard()">{{ 'Go Back to the Homepage' | translate }}</button>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer> 
