<div *ngIf="viewType == 1 else designLearnMore">
    <a *ngIf="learnMoreFunction == 2 else descriptionBox"
        href="{{ templateLink }}"
        class="link"
        target="_blank"
        rel="noopener noreferrer"
        (click)="trackLink(templateLink, templateId) ">
      <div class="box green-box text-center div-shadow margin-top-20" *ngIf="templateLink"
        [style.background]="diplomaDesign.custom_colors && diplomaDesign.box_1_color
          ? diplomaDesign.box_1_color
          : ''"
        [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color
          ? diplomaDesign.box_1_text_color
          : '#fff'">
        <h4 class="white-color-ni margin-bottom-15"
          [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color
            ? diplomaDesign.box_1_text_color
            : ''">
          {{ 'Do you want to know more about /diploma_name/' | translate:{diploma_name: diplomaName} }}?
        </h4>
        <p class="link text-semibold">
          {{ 'Read more' | translate }}
          <i class="fa fa-external-link" aria-hidden="true"></i>
        </p>
      </div>
    </a>
    <ng-container #descriptionBox *ngIf="learnMoreFunction == 1 && description">
      <a
        class="link"
        rel="noopener noreferrer"
        (click)="openModel(descriptionModal, 'descriptionModal')">
        <div class="box green-box text-center div-shadow margin-top-20"
          [style.background]="diplomaDesign.custom_colors && diplomaDesign.box_1_color
            ? diplomaDesign.box_1_color
            : ''"
          [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color
            ? diplomaDesign.box_1_text_color
            : '#fff'">
          <h4 class="white-color-ni margin-bottom-15"
            [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color
              ? diplomaDesign.box_1_text_color
              : ''">
            {{ 'Do you want to know more about /diploma_name/' | translate:{diploma_name: diplomaName} }}?
          </h4>
          <p class="link text-semibold">
            {{ 'Read more' | translate }}
            <i class="fa fa-external-link" aria-hidden="true"></i>
          </p>
        </div>
      </a>
    </ng-container>
</div>
<ng-template #designLearnMore>
  <ng-container *ngIf="learnMoreFunction == 2 else descModalButton">
    <a href="{{ templateLink }}" target="_blank" class="link">
      <div class="side-buttons div-shadow margin-top-20" *ngIf="templateLink"
          [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_color ? diplomaDesign?.box_1_color : ''"
          [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_text_color ? diplomaDesign?.box_1_text_color : ''">
          <span class="box-title">
              {{ 'Do you want to know more about /diploma_name/' | translate:{diploma_name: diplomaName} }}?
          </span>
          <div class="template-link" (click)="trackLink(templateLink, templateId)"
                [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_text_color ? diplomaDesign?.box_1_text_color : ''">
                  {{ 'Read more' | translate }} >>
              
          </div>
      </div>
    </a>
  </ng-container>
    <ng-container #descModalButton *ngIf="learnMoreFunction == 1 && description">
      <a (click)="openModel(descriptionModal, 'descriptionModal')" class="link">
        <div class="side-buttons div-shadow margin-top-20"
          [style.background]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_color ? diplomaDesign?.box_1_color : ''"
          [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_text_color ? diplomaDesign?.box_1_text_color : ''">
          <span class="box-title">
            {{ 'Do you want to know more about /diploma_name/' | translate:{diploma_name: diplomaName} }}?
          </span>
          <div class="template-link"
                [style.color]="diplomaDesign?.custom_colors && diplomaDesign?.box_1_text_color ? diplomaDesign?.box_1_text_color : ''">
                  {{ 'Read more' | translate }} >>
          </div>
        </div>
      </a>
    </ng-container>
</ng-template>
<ng-template #descriptionModal let-d="dismiss">
    <app-learn-more-description-modal 
        [templateLink]="templateLink"
        [dismiss]="d"
        [trackLink]="this.trackLink"
        [description]="description"
        [diplomaName]="diplomaName"
        [organizationImage]="organizationImage">
    </app-learn-more-description-modal>
</ng-template>