import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { ProfileService } from '../../core/services/dashboard/profile.service';
import { Help } from '../../core/models/help';
import { HelpService } from '../../core/services/help/help.service';

import { FlashMessagesService } from 'angular2-flash-messages';

import { FormValidationService } from '../../core/services/common-service/fromvalidation.service';

@Component({
  providers: [FormValidationService, HelpService, ProfileService],
  selector: 'app-help',
  styleUrls: ['./help.component.css'],
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {
  public emailFormControl = this.formValidationService.emailValidation();
  public nameFormControl = this.formValidationService.inputValidation();
  public subjectFormControl = this.formValidationService.inputValidation();
  public messageFormControl = this.formValidationService.inputValidation();

  public mainData: any;
  public data: any;
  public help = new Help('', '', '', '');
  public accessToken: any;
  public status: any;
  private errorMessage: string;
  private error: any;
  private params: any = this.activatedRoute.snapshot.params;
  private langVal: any;

  constructor(
    private profileService: ProfileService,
    private formValidationService: FormValidationService,
    private router: Router,
    private helpService: HelpService,
    private activatedRoute: ActivatedRoute,
    private flashMessagesService: FlashMessagesService,
    private meta: Meta
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  public ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      const accessToken = this.userDetails.accessToken;
      this.getUserDetail(accessToken);
    }
    this.status = false;
  }

  public getUserDetail(accessToken) {
    this.profileService.getUserDetail(accessToken).subscribe(
      (data) => this.initUserData(data),
      (error) => (this.errorMessage = error)
    );
  }

  public initUserData(data) {
    if (!data.error) {
      this.help = new Help(data.name, data.email, '', '');
    }
  }

  public requestHelp() {
    if (this.userDetails) {
      this.accessToken = this.userDetails.accessToken;
    } else {
      this.accessToken = false;
    }
    this.helpService
      .requestHelp(this.help, this.accessToken, this.params.lang)
      .subscribe(
        (data) => this.initData(data),
        (error) => (this.errorMessage = error)
      );
  }

  public initData(data) {
    if (data.error) {
      if (data.message.name) {
        this.flashMessagesService.show(data.message.name, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.email) {
        this.flashMessagesService.show(data.message.email, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.subject) {
        this.flashMessagesService.show(data.message.subject, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else if (data.message.message) {
        this.flashMessagesService.show(data.message.message, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      } else {
        this.flashMessagesService.show(data.message, {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
      this.data = data;
    } else {
      this.status = true;
    }
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get language() {
    return this.params.lang;
  }
}
