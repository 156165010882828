import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appOnHoverBackground]',
})
export class OnHoverBackgroundDirective {

  private initColor;

  private elRef: ElementRef;

  @Input() appOnHoverBackground: string;

  constructor(elRef: ElementRef) {
    this.elRef = elRef;
    this.initColor = this.elRef.nativeElement.style.background;
  }
  @HostListener('mouseover')
  onMouseOver() {

    this.elRef.nativeElement.style.backgroundColor = this.appOnHoverBackground;
    this.updateChildren(this.appOnHoverBackground);
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.elRef.nativeElement.style.backgroundColor =  this.initColor;
    this.updateChildren(this.initColor);
  }

  private updateChildren(color) {
    const children = this.elRef.nativeElement.children;
    for (let child of children) {
      child.style.background = color
    }
  }

}
